<div class="az-body">
  <!-- Start header -->
  <div [ngSwitch]="clientName">
    <div class="az-header az-header-primary" *ngSwitchDefault>
      <div class="container-fluid px-0">
        <div class="az-header-left">
          <a href="javascript:return false" id="azIconbarShow" *ngIf=false
            class="az-header-menu-icon d-lg-none"><span></span>
          </a>
          <a href="javascript:return false" class="ml-0 logo-sm suv-logo suv-logo-sm">
            <img src="../../assets/img/EduKares_logo.jpeg" alt="suvarna_logo" class="img-fluid suv-logo-img">
          </a>
          <div class="client-logo client-logo-sm">
            <img src="../../assets/img/{{clientLogo}}" class="img-fluid client-img-logo">
            <span>{{clientName}}</span>
          </div>
          <a href="javascript:return false" class="az-logo">Edu<span>K</span>ares</a>
        </div>
        <div class="search-header-loockup">
          <p-autoComplete [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [suggestions]="menuList"
            (completeMethod)="getMeuList($event)" (onSelect)="onMenuSelected($event)" (onFocus)="getMeuList('')"
            field="documentName" [minLength]="0" forceSelection="true" emptyMessage="No menu found" autoHighlight="true"
            placeholder="Search Menu" [style]="{'width':'100%', 'background-color':'#ffffff'}" [(ngModel)]="menuLabel">
            <ng-template let-menu pTemplate="item">
              <div style='width: 100% !important; display:inline-block'>
                <div class="row mx-0">
                  <div class="flex-1">
                    {{menu.documentName}}
                  </div>
                  <div class="flex-1">
                    {{menu.moduleName + (menu.subModuleName != null?" - " + menu.subModuleName:"")}}
                  </div>
                </div>
              </div>
            </ng-template>
          </p-autoComplete>
          <button pButton type="button" icon="pi pi-search" class="autocomplete-btn"></button>
        </div>
        <div class="az-header-right">
          <div class="live-server text-white" style="text-align: center">
            <h5 class="mb-0 text-nowrap"><b>{{loginLocation}}</b></h5>
            <h5 class="mb-0 text-nowrap" style="padding-top: 5px;">{{appInstance}}</h5>
          </div>
          <div class="server-ipaddrss text-white  text-nowrap" *ngIf="false">
            <label class="mb-0">Your IP Address</label>
            <div>{{clientIP}}</div>
          </div>
          <div class="module-view">
            <button type="button" class="btn btn-outline" (click)="onModuleViewClick()">
              <i class="fas fa-home"></i>
              <span class="ml-1 module-mobile-none">Module View</span>
            </button>
          </div>

          <div class="noti-bell" *ngIf=true
      pTooltip="Notifications" tooltipPosition="bottom" (click)="onClickNotifications()">
      <i class="typcn typcn-bell text-xl text-primary-300 ml-3" style='font-size: 20pt; color: #fff'></i>
      <!-- <div class="legend legend-danger absolute" style="top:5px;right:5px" *ngIf="true"></div> -->
      <span *ngIf="sysNotifiactions.length>0" class="rounded-circle text-white d-flex justify-content-center align-items-center noti-count"
          style="width: 20px; height: 20px; font-size: 10px">{{sysNotifiactions.length}}</span>
    </div>

          <div class="dropdown az-profile-menu sign-in-profile">
            <a href="#" class="az-img-user sign-in-profile-pic"
              style="border:2px solid rgba(255, 255, 255, 0.75); padding:2px">
              <img alt="" class="img-circle " src="assets/img/male.png"></a>
            <span class="text-white text-ellipsis text-center">{{userName}}</span>
            <div class="dropdown-menu">
              <div class="az-dropdown-header d-sm-none">
                <a href="#" class="az-header-arrow"><i class="icon ion-md-arrow-back"></i></a>
              </div>
              <a class="dropdown-item" *ngIf="false"><i class="typcn typcn-user-outline"></i>My Profile</a>
              <a class="dropdown-item" *ngIf="false"><i class="typcn typcn-edit"></i>Edit Profile</a>
              <a class="dropdown-item" *ngIf="false"><i class="typcn typcn-time"></i>Activity Logs</a>
              <a class="dropdown-item" *ngIf="false"><i class="typcn typcn-cog-outline"></i>Account
                Settings</a>
              <span *ngIf="locList.length > 1">Change Location
                <s-dropdown [filter]="false" [isMandatory]="true" [options]="locList"
                  (onChange)="onLocationChange($event)">
                </s-dropdown></span>
              <a class="dropdown-item" (click)="onChangepwd()"><i class="fas fa-user fa-sm"
                  style="font-size: 17px"></i>Change Password</a>
              <a class="dropdown-item" (click)="onLogoutClick()"><i class="fas fa-power-off fa-sm"
                  style="font-size: 17px"></i>Sign Out</a>
            </div>
          </div>
          <div class="client-logo mobile-client-logo">
            <img src="../../assets/img/{{clientLogo}}" class="img-fluid client-img-logo">
            <span style="position: relative;top:-27px;">{{clientName}}</span>
          </div>
        </div>
        <!-- az-header-right -->
      </div>
      <!-- container-fluid -->
    </div>

    <div class="az-header az-header-primary" *ngSwitchCase="'PIMS (DU)'">
      <div class="container-fluid px-0">
        <div class="az-header-left">
          <a href="javascript:return false" id="azIconbarShow" *ngIf=false
            class="az-header-menu-icon d-lg-none"><span></span>
          </a>
          <a href="javascript:return false" class="ml-0 client-logo">
            <img src="../../assets/img/{{clientLogo}}" class="img-fluid client-img-logo-left">
          </a>
          <div class="client-logo client-logo-sm">
            <img src="../../assets/img/{{clientLogo}}" class="img-fluid client-img-logo">
            <span>{{clientName}}</span>
          </div>
          <a href="javascript:return false" class="az-logo">&nbsp;&nbsp;{{clientName}}</a>
        </div>
        <div class="search-header-loockup">
          <p-autoComplete [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [suggestions]="menuList"
            (completeMethod)="getMeuList($event)" (onSelect)="onMenuSelected($event)" (onFocus)="getMeuList('')"
            field="documentName" [minLength]="0" forceSelection="true" emptyMessage="No menu found" autoHighlight="true"
            placeholder="Search Menu" [style]="{'width':'100%', 'background-color':'#ffffff'}">
            <ng-template let-menu pTemplate="item">
              <div style='width: 100% !important; display:inline-block'>
                <div class="row mx-0">
                  <div class="flex-1">
                    {{menu.documentName}}
                  </div>
                  <div class="flex-1">
                    {{menu.moduleName + (menu.subModuleName != null?" - " + menu.subModuleName:"")}}
                  </div>
                </div>
              </div>
            </ng-template>
          </p-autoComplete>
          <button pButton type="button" icon="pi pi-search" class="autocomplete-btn"></button>
        </div>
        <div class="az-header-right">
          <div class="live-server text-white" style="text-align: center">
            <h5 class="mb-0 text-nowrap"><b>{{loginLocation}}</b></h5>
            <h5 class="mb-0 text-nowrap" style="padding-top: 5px;">{{appInstance}}</h5>
          </div>
          <div class="server-ipaddrss text-white  text-nowrap" *ngIf="false">
            <label class="mb-0">Your IP Address</label>
            <div>{{clientIP}}</div>
          </div>
          <div class="module-view">
            <button type="button" class="btn btn-outline" (click)="onModuleViewClick()">
              <i class="fas fa-home"></i>
              <span class="ml-1 module-mobile-none">Module View</span>
            </button>
          </div>
          <div class="dropdown az-profile-menu sign-in-profile">
            <a href="#" class="az-img-user sign-in-profile-pic"
              style="border:2px solid rgba(255, 255, 255, 0.75); padding:2px">
              <img alt="" class="img-circle " src="assets/img/male.png"></a>
            <span class="text-white text-ellipsis text-center">{{userName}}</span>
            <div class="dropdown-menu">
              <div class="az-dropdown-header d-sm-none">
                <a href="#" class="az-header-arrow"><i class="icon ion-md-arrow-back"></i></a>
              </div>
              <a class="dropdown-item" *ngIf="false"><i class="typcn typcn-user-outline"></i>My Profile</a>
              <a class="dropdown-item" *ngIf="false"><i class="typcn typcn-edit"></i>Edit Profile</a>
              <a class="dropdown-item" *ngIf="false"><i class="typcn typcn-time"></i>Activity Logs</a>
              <a class="dropdown-item" *ngIf="false"><i class="typcn typcn-cog-outline"></i>Account
                Settings</a>
              <span *ngIf="locList.length > 1">Change Location
                <s-dropdown [filter]="false" [isMandatory]="true" [options]="locList"
                  (onChange)="onLocationChange($event)">
                </s-dropdown></span>
              <a class="dropdown-item" (click)="onChangepwd()"><i class="fas fa-user fa-sm"
                  style="font-size: 17px"></i>Change Password</a>
              <a class="dropdown-item" (click)="onLogoutClick()"><i class="fas fa-power-off fa-sm"
                  style="font-size: 17px"></i>Sign Out</a>
            </div>
            <!-- dropdown-menu -->
          </div>
          <div class="client-logo mobile-client-logo">
            <img src="../../assets/img/EduKares_logo.jpeg" alt="suvarna_logo" class="img-fluid suv-logo-img">
          </div>
        </div>
        <!-- az-header-right -->
      </div>
      <!-- container-fluid -->
    </div>
  </div>


  <!-- end header -->

  <!-- start sidebar Menu  -->
  <div class="az-iconbar az-iconbar-primary" *ngIf=false>
    <a href="javascript:return false;" class="suv-side-logo">
      <img src="../../assets/img/talenttimes-logo2.png" alt="suvarna_logo" class="img-fluid suv-logo-img">
      <span style="font-size: 10px; white-space: nowrap;">Talent Times</span>
    </a>
    <nav class="nav sidenav-scroll">
      <p-scrollPanel [style]="{width:'100%'}" styleClass="menu-bar-scroll scrollbar-color">
        <a href="#menu_{{getMenuId(menu.menuName)}}" class="nav-link" *ngFor='let menu of UserMenu'><i
            class="{{menu.imageUrl}}" tooltipPosition="top" tooltipStyleClass="tooltip-info"
            pTooltip={{menu.menuName}}></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules">
          <i class="fas fa-user-shield" tooltipPosition="top" tooltipStyleClass="tooltip-info"
            pTooltip="HR & Payroll"></i>
        </a>
        <a href="#" class="nav-link" *ngIf="showAllModules">
          <i class="fas fa-cogs" tooltipPosition="top" tooltipStyleClass="tooltip-info"
            pTooltip="Materials Management"></i>
        </a>
        <a href="#" class="nav-link" *ngIf="showAllModules">
          <i class="fas fa-copy" tooltipPosition="top" tooltipStyleClass="tooltip-info"
            pTooltip="Document Management"></i>
        </a>

        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-bus-alt" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Transport & Fleet"></i></a>

        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-users" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Traning & Placement"></i></a>

        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-praying-hands" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Activity & Event"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-cog" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Value Added Services"></i></a>

        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-shuttle-van" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Vehicle Management"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-vihara" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Auditorium & Hall Booking"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-people-carry" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Content Share & Communication"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-award" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Accrediation Reports"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-store-alt" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Guest House"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-sticky-note" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Complaints"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-video" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Media & Ads"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-graduation-cap" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Convocation"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-search" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Phd (Research Work)"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-torii-gate" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Asset & State Management"></i></a>

        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-file-signature" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Online Entrance Examination"></i></a>

        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-list-alt" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Recruitment Proccess"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-chart-line" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Career Development"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-user-graduate" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Reimbursement / Claim"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-question-circle" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Question Bank"></i></a>
        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-handshake" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Affiliation"></i></a>

        <a href="#" class="nav-link" *ngIf="showAllModules"><i class="fas fa-th-large" tooltipPosition="top"
            tooltipStyleClass="tooltip-info" pTooltip="Dashboards"></i></a>
      </p-scrollPanel>
    </nav>
  </div>
  <!-- az-iconbar -->
  <div class="az-sidebar">
    <div class="az-iconbar-aside az-iconbar-aside-primary" id="menubar">
      <div class="az-sidebar-body">
        <p-scrollPanel [style]="{width:'100%'}" styleClass="menu-bar-content scrollbar-color">
          <div id="menu_{{getMenuId(menu.menuName)}}" class="az-iconbar-pane" *ngFor='let menu of UserMenu'>
            <h5 style='color:#0062cc'><b>{{menu.menuName}}</b></h5>
            <ul class="nav" style="width:90%">
              <li class="nav-item" *ngFor='let childMenu of menu.childMenus'>
                <ul class="nav">
                  <li class="nav-item" style='color:#0062cc;cursor: pointer;'>
                    <a [ngClass]="childMenu.childMenus.length!=0?'nav-link with-sub': 'nav-link'"
                      (click)='navigateToMenu(childMenu.pageUrl, childMenu.metaData)'>
                      <h6><b><i class="far fa-folder-open"></i>{{childMenu.menuName}}</b></h6>
                    </a>
                    <nav class="nav-sub" *ngIf='childMenu.childMenus.length==0?false:true'>
                      <a class="nav-sub-link text-nowrap" style="color:#007bff"
                        *ngFor='let subchildMenu of childMenu.childMenus'
                        (click)='navigateToMenu(subchildMenu.pageUrl, subchildMenu.metaData)'><i
                          class="far fa-file-alt"></i>&nbsp;&nbsp;{{subchildMenu.menuName}}</a>
                    </nav>
                  </li>
                </ul>
              </li><!-- nav-item -->
            </ul>
          </div>
        </p-scrollPanel>
      </div>
    </div>
  </div>
  <!-- end sidebar Menu -->


  <!-- footer  -->
  <footer class="footer" *ngIf=false>
    <div class="footer-container">
      <div class="organization-footer">
        <div class="">
          <span><strong>Organization: </strong></span>
          <span>Talent Times Pvt.Ltd</span>
        </div>
      </div>
      <div style="border-right: 1px solid #c8c8c8"></div>
      <div class="location-footer">
        <span><strong>Location: </strong></span>
        <span>{{loginLocation}}</span>
      </div>
      <div style="border-right: 1px solid #c8c8c8" *ngIf=false></div>
      <div class="last-logged-footer" *ngIf=false>
        <span><strong>Last Logged In: </strong></span>
        <span>{{userName}}</span>
      </div>
    </div>
  </footer>
  <router-outlet></router-outlet>
</div>
<!-- Change Password -->

<p-dialog header="Change Password" [(visible)]="changePwd" [modal]="true" [style]="{width:'20vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" styleClass="forgot_dialog">
  <form class="m-2" [formGroup]="changePwdForm">
    <div class="form-group mb-1">
      <label for="changepassword" class="login-font">Old Password</label>
      <div class="input-control">
        <span class="fa fa-lock login-icon"></span>
        <s-inputtext [inputType]="oldIsInputText" controlId="changepassword" [cssClass]="'form-Control p-1 pl-30'"
          [isLoginPage]="true" [formControlName]="'parOldPassword'" (onBlur)="getErrormessage('parOldPassword',true)">
        </s-inputtext>
        <span class="{{oldShowEye}}" *ngIf="thisForm.parOldPassword.valid"
          (click)="onClickSeePassword(thisForm.parOldPassword.valid, 'old', ctrlPass)"></span>
        <small class="text-danger font-weight-bold"> {{errorMessages.changepassword}}</small>
      </div>
    </div>
    <div class="form-group mb-1">
      <label for="changeNewPassword" class="login-font">New Password</label>
      <div class="input-control">
        <span class="fa fa-lock login-icon"></span>
        <s-inputtext [inputType]="newIsInputText" controlId="changeNewPassword" [cssClass]="'form-Control p-1 pl-30'"
          aria-autocomplete="off" [isLoginPage]="true" [formControlName]="'parNewPassword'"
          (onBlur)="getErrormessage('parNewPassword',true)" (onChange)="validateSettings($event)">
        </s-inputtext>
        <span class="{{newShowEye}}" (click)="onClickSeePassword(thisForm.parNewPassword.valid,'new',ctrlPass)"
          *ngIf="thisForm.parNewPassword.valid"></span>
        <small class="text-danger font-weight-bold">{{errorMessages.changeNewPassword}}</small>
      </div>
    </div>
    <div class="form-group mb-3">
      <label for="changeConformPassword" class="login-font">Confirm New Password</label>
      <div class="input-control">
        <span class="fa fa-lock login-icon"></span>
        <s-inputtext [inputType]="confirmIsInputText" controlId="changeConformPassword"
          [cssClass]="'form-Control p-1 pl-30'" [isLoginPage]="true" [formControlName]="'parNewConfPassword'"
          (onBlur)="getErrormessage('parNewConfPassword',true)">
        </s-inputtext>
        <span class="{{confirmShowEye}}"
          (click)="onClickSeePassword(thisForm.parNewConfPassword.valid,'cnfrm',ctrlPass)"
          *ngIf="thisForm.parNewConfPassword.valid"></span>
        <small class="text-danger font-weight-bold">{{errorMessages.changeConformPassword}}</small>
      </div>
    </div>

    <button type="submit" class="btn btn-primary w-100 d-block login-btn" (click)='onChangePwdClick()'
      [disabled]="!(isShowSbmit && validNewPwd)">Submit</button>

    <div [ngClass]="saveCode == '0000' ?'pwd-warning':'pwd-success'" *ngIf="showReq">
      <label> {{toastMessage}}</label>
    </div>

    <ul style="margin-top: 15px" *ngIf="isChceckNewPwd">
      <li>
        <a href="javascript:return false"><i class="fa fa-times-circle char-failure" *ngIf="!minLengthFlag"></i><i
            class="fa fa-check-circle char-success" *ngIf="minLengthFlag"></i></a>
        <span>Minimum Length of {{minLength}} Characters Required</span></li>
      <li>
        <a href="javascript:return false"><i class="fa fa-times-circle char-failure" *ngIf="!uppCsFlag"></i><i
            class="fa fa-check-circle  char-success" *ngIf="uppCsFlag"></i></a>
        <span>Minimum {{uppCs}} Upper Case Letter Required</span>
      </li>
      <li>
        <a href="javascript:return false"><i class="fa fa-times-circle char-failure" *ngIf="!lwrCsFlag"></i><i
            class="fa fa-check-circle  char-success" *ngIf="lwrCsFlag"></i></a>
        <span>Minimum {{lwrCs}} Lower Case Letter Required</span>
      </li>
      <li>
        <a href="javascript:return false"><i class="fa fa-times-circle char-failure" *ngIf="!minNmFlag"></i><i
            class="fa fa-check-circle  char-success" *ngIf="minNmFlag"></i></a>
        <span>Minimum {{minNm}} Numbers Required</span>
      </li>
      <li>
        <a href="javascript:return false"><i class="fa fa-times-circle char-failure" *ngIf="!spclChrsFlag"></i><i
            class="fa fa-check-circle  char-success" *ngIf="spclChrsFlag"></i></a>
        <span>Minimum {{spclChrs}} Special Character Required</span>
      </li>
    </ul>
  </form>
</p-dialog>

<p-dialog header="Login Expiring" [(visible)]="showIdleTime" [closeOnEscape]=false [closable]=false [modal]="true"
  [style]="{width:'20vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" styleClass="forgot_dialog">
  <div style='padding-top:10px;padding-bottom: 10px;text-align: center;'>
    Your login session is expiring in <br><b>{{loginExpTime}}</b><br><b><a href="javascript:return false"
        (click)="idleTimeReset()">Click here</a></b> to stay back.
  </div>
</p-dialog>

<!-- <p-dialog header= "Notifications" [(visible)]="isNotifications" styleClass="w-full md:w-30rem xl:w-30rem lg:w-30rem custom-reminders"
  position="right" [style]="{'min-height':'100%'}" [dismissableMask]="true" [draggable]="false" [modal]="false"
  [closeOnEscape]="true" [baseZIndex]="99999">
      <div [ngStyle]="{'overflow-y': 'auto', 'height': '750px'}">
      <div class="card" *ngFor="let thisRow of sysNotifiactions;let systhisRowIn=index;" >
        <button class="close-btn" (click)="onUpdate(systhisRowIn)">x</button>
          <div class="flex justify-content-between relative card-content">
            <p [innerHtml]="thisRow['msgDesc']" class="mt-1">
            </p>
          </div>
        </div>
      </div>
      <div class="card mb-0 text-center" *ngIf="sysNotifiactions.length == 0">
        <span>No Notifications Found</span>
      </div>
</p-dialog> -->

<p-dialog [(visible)]="isNotifications" styleClass="w-full md:w-30rem xl:w-30rem lg:w-30rem custom-reminders"
  position="right"  [dismissableMask]="true" [draggable]="false" [modal]="false"
  [closeOnEscape]="true" [baseZIndex]="99999999999" header="Notifications">

  

    <!-- <p-tabPanel header="Notifications"> -->
      <div class="grid p-input-filled">
        <div class="col-6">
          <!-- <s-datepicker [(inputModel)]="notif_dt" [showTitle]="false" (onClear)="getSysNotifications()"
            (onSelect)="getSysNotifications()" [maxDate]="newDate" /> -->
        </div>
      </div>
      <div class="notification-container ">
      <div class="notification-box" *ngFor="let thisRow of sysNotifiactions;let systhisRowIn=index;" >
        <span class="pi pi-times notification-close" (click)="onUpdate(systhisRowIn)"></span>
        <div class="notification-subject">
          {{thisRow['tempSubject']}}
          </div>
          <div class="notification-message">
            {{thisRow['msgDesc']}}
          </div>
          <div class="notification-time">
            {{thisRow['sentDate']}}
          </div>
        </div>
      </div>
      <div class="notification-box mb-0 text-center" *ngIf="sysNotifiactions.length == 0">
        <div class="notification-message">
         No Notifications Found
        </div>
      </div>
    <!-- </p-tabPanel> -->
    <p-tabPanel header="History" *ngIf=false>
        <!-- <p-panel styleClass="shadow-2 hover:shadow-5 mb-3" *ngFor="let thisRow of notificationList;
        let thisRowIn=index;">
        </p-panel> -->
        <div class="card mb-0 text-center" *ngIf="true">
          <span>No Data Found</span>
        </div>
      </p-tabPanel>

</p-dialog>

<!-- <p-toast key="msgs"  position="bottom-right"
  [baseZIndex]="999999">
  <ng-template let-message pTemplate="headless">
    <div class="flex p-3 gap-3 w-full bg-primary-600 border-2 border-primary-800 border-round-xl shadow-8">
      <i class="pi pi-info-circle text-white text-2xl"></i>
      <div class="flex flex-column gap-3 w-full">
        <p class="m-0 font-semibold text-lg text-white" style="letter-spacing: 0.1rem;">Notifications</p>
        <p class="m-0 text-base text-white" style="letter-spacing: 0.1rem;">You have new Notification(s) / Reminder(s)
        </p>
        <div class="flex m-0 font-semibold text-base text-white" *ngIf="![undefined,null,''].includes(reminderTitle)">
          Reminder :- {{reminderTitle }}
        </div>
        <div class="flex m-0 font-semibold text-base text-white"
          *ngIf="![undefined,null,''].includes(notificationMsgContent)">
          Notification :- {{ notificationMsgContent }}
        </div>
        <div class="flex gap-3 mb-3">
          <p-button label="View" styleClass="text-white p-0" (click)="onClickNotifications()"></p-button>
          <p-button label="Cancel"  styleClass="text-400 p-0" (click)="_messageService.clear()"></p-button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast> -->

<style>

  :host ::ng-deep .custom-reminders{
    margin: 0px !important;
    min-width: 15rem !important;
    background-color: white;
    left: auto !important;
     top: 0px !important; 
    bottom: auto !important;
    position: fixed;  
    right: 0 !important;
    transform: translate(100%);
    animation: slideInCOntainer 0.5s ease-out forwards
  }

  :host ::ng-deep .p-dialog.p-dialog-visible {
    transform: translateX(0);
  }


 

  .noti-count{
     position: absolute;
     top: -5px;
     right: -5px;
     background-color: red;
  }

  .noti-bell{
    position: relative;
    display: inline-block
  }

  /* body .ui-dialog .ui-dialog-content{
    padding: 0px !important
  } */

  

  .notification-container{
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rgba(255,255,255,0.9);
    padding: 0px;
    border-radius: 12px 0 0 12px;
    box-shadow: -2px 4px 10px rgba(0,0,0 0.2);
    max-height:calc(100vh - 120px);
    overflow-y: auto;
  }

  @keyframes slideInCOntainer{
    from{
      transform: translate(100%);
    }
    to{
      transform: translate(0)
    }
  }

  .notification-box{
    width: 280px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.1);
    padding:  16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    border: 1px solid #0073e6;
    border-left: 6px solid #0073e6 !important;
    position: relative;
    transition: transform 0.3s ease-in-out , box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .notification-box:hover{
    transform: translateY(-3px);
    box-shadow: 0px 6px 14px rgba(0,0,0,0.15);
  }
  .notification-subject {
    font-size: 12px;
    font-weight: bold;
    color: #333;
   
  }

  .notification-message {
    font-size: 13px;
    color: #555;
    line-height: 1.5;
    /* font-family: "Open Sans", "Helvetica Neue", sans-serif; */
  
  }
 
  .notification-time {
    font-size: 11px;
    text-align: right;
    color: #777;
  }

  .notification-close {
    position: absolute;
    top: 10px;
    right:10px;
    height: 20px;
    width: 20px;
    background-color: white;
    color: rgb(236, 90, 90);
    border: none;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
    font-size: 17px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
  }
  .notification-close:hover{
    transform: scale(1.1)
  }
  


</style>