import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


Injectable()
export class GlobalData {
    UserInfo: any = { UserID: 0, UserName: "" };
    public capCode: string = "";

    constructor(private _httpClient: HttpClient) { }

    public getConfigData() {
        return this._httpClient.get('assets/config/appconfig.json');
    }

    public getV2GridConfig() {
        return this._httpClient.get('assets/config/v2gridconfig.json');
    }

    public getMenu() {
        return this._httpClient.get('assets/config/menu.json');
    }
}

