import { Component, Input, Self, Output, EventEmitter, Optional, OnInit } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 's-dropdown',
  templateUrl: './dropdown.component.html'
})
export class DropdownComponent implements ControlValueAccessor, OnInit {

  @Input() controlId: string = "";
  @Input() errorMessage: string = "";
  @Input() cssClass: string = "";
  @Input() options: string[] = [];
  @Input() filter: boolean = true;
  @Input() autoFocus: boolean = false;
  @Input() inputModel: string = "";
  @Input() appendTo: string = "body";
  @Input() iconClass: string = "error-icon-dropdown";
  @Input() isMandatory: boolean = false;
  public markMandatory: boolean = false;
  public disabledMe: boolean = false;
  @Input() set disabled(condition: boolean) {
    if (this.ngControl != null) {
      const action = condition ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
    else {
      this.disabledMe = condition;
    }
  };

  @Output() public onBlur = new EventEmitter();
  @Output() public onChange = new EventEmitter();
  @Output() inputModelChange = new EventEmitter<string>();

  constructor(
    @Optional()
    @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.markMandatory = this.isMandatory;
  }

  writeValue(obj: any) { }
  registerOnChange(fn: any) { };
  registerOnTouched(fn: any) { };
  setDisableState?(isDisabled: boolean) { }

  onLeaveControl() {
    if (this.ngControl != null) {
      this.markMandatory = (this.isMandatory && (this.ngControl.control.value == null || this.ngControl.control.value == ""));
    }
    else {
      this.markMandatory = (this.isMandatory && (this.inputModel == null || this.inputModel == ""));
    }
    this.onBlur.emit(true);
  }

  onChangeValue(thisValue) {
    if (this.ngControl != null) {
      this.markMandatory = (this.isMandatory && (this.ngControl.control.value == null || this.ngControl.control.value == ""));
    }
    else {
      this.markMandatory = (this.isMandatory && (this.inputModel == null || this.inputModel == ""));
    }
    this.onChange.emit(thisValue.value);
  }
}
