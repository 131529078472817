import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalData } from '../globaldata';
import * as _ from 'node_modules/lodash';
import { MessageService } from 'primeng/components/common/messageservice';
import * as CryptJs from "crypto-js";
import { DatePipe } from '@angular/common';
import { TreeNode } from 'primeng/api';
import { NgxImageCompressService } from 'ngx-image-compress';
import * as kindOf from 'node_modules/kind-of';
import * as toWords from 'to-words';
declare const PDFBarcodeJs;
declare const PDF_QR_JS;
declare const scanner;
declare const tippy;
declare const JSPM;

@Injectable({
  providedIn: 'root'
})
export class MastersService {

  private apiHostUrl = "";
  private masterApiUrl: any = {};
  public appConfig: any = {};
  public v2GridConfig: any = {};
  private decryptKey = "wonderfullife~!@#$%^&*()_+";
  public jspmConnectStatus = false;

  constructor(
    private _http: HttpClient,
    private _globalData: GlobalData,
    private messageService: MessageService,
    private _datePipe: DatePipe,
    private imageCompress: NgxImageCompressService) {
    this.getConfigData();
    this.getV2GridConfig();

    //console.log("JSPM", JSPM);
    //JSPM.JSPrintManager.auto_reconnect = true;
    //JSPM.JSPrintManager.start();
  }

  // Read "app config" file
  async getConfigData() {
    let promise;
    await this._globalData.getConfigData().toPromise()
      .then((res) => {
        promise = res;
      })
    this.appConfig = promise;
    this.apiHostUrl = this.appConfig["apiHostUrl"];
  }

  async getV2GridConfig() {
    let gridV2Docs;
    await this._globalData.getV2GridConfig().toPromise()
      .then((res) => {
        gridV2Docs = res["gridV2Docs"];
      });
    this.v2GridConfig = gridV2Docs || [];
  }

  // Set master api attribute
  async setMasterApiUrl(masterApi) {
    let masterApiUrl = await this.appConfig[masterApi];
    this.masterApiUrl = masterApiUrl;
  }

  // Get columns to be excluded from data set (to not to show in grid)
  getExcludeCols(excludeColsKey) {
    return this.appConfig[excludeColsKey];
  }

  // Get error messages for validations
  getErrorMessages(errorKey) {
    return this.appConfig[errorKey];
  }

  // Get Grid Columns
  getGridColumns(listName) {
    return this.appConfig[listName];
  }

  // Get Entity ID
  getEntityId(entityName) {
    return this.appConfig["entityIDs"][entityName];
  }

  // Get Authorization ID
  getAuthId(authName) {
    return this.appConfig["authorizations"][authName];
  }

  // Show All Modules
  getShowAllModules() {
    return this.appConfig["showAllModules"];
  }

  // Show All Modules
  getClientInfo(clientName) {
    return this.appConfig["clientInfo"][clientName];
  }

  // Get Workflow API Route
  getWorkflowApiRoute(docName) {
    let workflowApiRoutes = this.appConfig["workflowApiRoutes"];
    let docRoute = _.filter(workflowApiRoutes, { docName: docName });
    if (docRoute.length > 0)
      return docRoute[0]["apiRoute"];
    else
      return "";
  }

  //
  async getImgPlaceholder() {
    let imagePlaceholder = this.getAppConfigKeyInfo("imgPlaceholder");
    return imagePlaceholder;
  }

  //
  async getVisitorAvatar() {
    let visitorAvatar = this.getAppConfigKeyInfo("visitorAvatar");
    return visitorAvatar;
  }

  escapeSpclCharInJSON(key, value) {
    var returnValue = value;
    if (kindOf(this[key]) == "string") {
      returnValue = this[key].replace(/'/g, "''").replace(/"/g, "\"");
      // returnValue = _.trim(this[key] || "") == "" ? null : returnValue;
      // if (kindOf(returnValue) == "string") returnValue = _.trim(returnValue);
    }

    return returnValue;
  };

  // Get all records of master
  async getAllMasterData(urlKey, params) {
    let token: any = this.getUserInfo("token");
    var httpHeaders = new HttpHeaders(
      {
        "authorization": token,
        orgId: this.getUserInfo("org_id"),
        grpId: this.getUserInfo("grp_id"),
        locId: this.getUserInfo("location_id")
      }
    );
    var serviceURL = this.apiHostUrl + this.masterApiUrl[urlKey];
    let paramKeys = Object.keys(params);

    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = params[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });
    let wsAttr = serviceURL.split("?");
    serviceURL = wsAttr[0] + "?";
    if (wsAttr.length > 1) {
      let thisParams = wsAttr[1].split("&");
      _.forEach(thisParams, (param, index) => {
        let paramKeyVal = param.split("=");
        if (paramKeyVal[1] != `{${paramKeyVal[0]}}`) {
          serviceURL += param + "&";
        }
      });
    }
    if (_.endsWith(serviceURL, '&'))
      serviceURL = serviceURL.substring(0, serviceURL.length - 1);
    serviceURL += (serviceURL.indexOf('?') >= 0 ? '&' : '?') + `parSessionId=${this.getUserInfo("last_sessionid")}`;

    let resultData;
    await this._http.get(serviceURL, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }

  // Get record by 'record id'
  async getMasterData(urlKey, params) {
    let token: any = this.getUserInfo("token");
    var httpHeaders = new HttpHeaders({
      "authorization": token,
      orgId: this.getUserInfo("org_id"),
      grpId: this.getUserInfo("grp_id"),
      locId: this.getUserInfo("location_id")
    }
    );
    var serviceURL = this.apiHostUrl + this.masterApiUrl[urlKey];
    let paramKeys = Object.keys(params);

    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = params[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });
    let wsAttr = serviceURL.split("?");
    serviceURL = wsAttr[0] + "?";
    if (wsAttr.length > 1) {
      let thisParams = wsAttr[1].split("&");
      _.forEach(thisParams, (param, index) => {
        let paramKeyVal = param.split("=");
        if (paramKeyVal[1] != `{${paramKeyVal[0]}}`) {
          serviceURL += param + "&";
        }
      });
    }
    if (_.endsWith(serviceURL, '&'))
      serviceURL = serviceURL.substring(0, serviceURL.length - 1);
    serviceURL += (serviceURL.indexOf('?') >= 0 ? '&' : '?') + `parSessionId=${this.getUserInfo("last_sessionid")}`;

    let resultData;
    await this._http.get(serviceURL, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }

  // Insert / Update master record
  async insertUpdateMaster(urlKey, masterData: any) {
    var postData = JSON.stringify(masterData);
    var serviceURL = this.apiHostUrl + this.masterApiUrl[urlKey];
    let token: any = this.getUserInfo("token");
    var httpHeaders = new HttpHeaders(
      {
        'contentType': 'application/json; charset=utf-8',
        "authorization": token,
        orgId: this.getUserInfo("org_id"),
        grpId: this.getUserInfo("grp_id"),
        locId: this.getUserInfo("location_id")
      }
    );

    if (kindOf(masterData) == 'formdata') {
      let jsonData = masterData.get('data');
      jsonData = JSON.stringify(JSON.parse(JSON.stringify(JSON.parse(jsonData), this.escapeSpclCharInJSON)));
      masterData.set('data', jsonData);
    }
    else if (kindOf(masterData) == 'object') {
      masterData = JSON.parse(JSON.stringify(masterData, this.escapeSpclCharInJSON));
    }

    let resultData;
    await this._http.post(serviceURL, masterData, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }
  // Get Lookup Data
  async getLookupData(rootKey, lookupKey, params) {
    let token: any = this.getUserInfo("token");
    var httpHeaders = new HttpHeaders(
      {
        "authorization": token,
        orgId: this.getUserInfo("org_id"),
        grpId: this.getUserInfo("grp_id"),
        locId: this.getUserInfo("location_id")
      }
    );

    let root = this.appConfig[rootKey];
    var serviceURL = this.apiHostUrl + root[lookupKey];
    let paramKeys = Object.keys(params);

    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = params[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });
    let wsAttr = serviceURL.split("?");
    serviceURL = wsAttr[0] + "?";
    if (wsAttr.length > 1) {
      let thisParams = wsAttr[1].split("&");
      _.forEach(thisParams, (param, index) => {
        let paramKeyVal = param.split("=");
        if (paramKeyVal[1] != `{${paramKeyVal[0]}}`) {
          serviceURL += param + "&";
        }
      });
    }
    if (_.endsWith(serviceURL, '&'))
      serviceURL = serviceURL.substring(0, serviceURL.length - 1);

    if (params.sessionReq != "N")
      serviceURL += (serviceURL.indexOf('?') >= 0 ? '&' : '?') + `parSessionId=${this.getUserInfo("last_sessionid")}`;

    let resultData;
    await this._http.get(serviceURL, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }

  // Get Lookup Data From URl
  async getLookupDataFromUrl(url) {
    let token: any = this.getUserInfo("token");
    var httpHeaders = new HttpHeaders(
      {
        "authorization": token,
        orgId: this.getUserInfo("org_id"),
        grpId: this.getUserInfo("grp_id"),
        locId: this.getUserInfo("location_id")
      }
    );
    var serviceURL = this.apiHostUrl + url;
    let wsAttr = serviceURL.split("?");
    serviceURL = wsAttr[0] + "?";
    if (wsAttr.length > 1) {
      let thisParams = wsAttr[1].split("&");
      _.forEach(thisParams, (param, index) => {
        let paramKeyVal = param.split("=");
        if (paramKeyVal[1] != `{${paramKeyVal[0]}}`) {
          serviceURL += param + "&";
        }
      });
    }
    if (_.endsWith(serviceURL, '&'))
      serviceURL = serviceURL.substring(0, serviceURL.length - 1);
    serviceURL += (serviceURL.indexOf('?') >= 0 ? '&' : '?') + `parSessionId=${this.getUserInfo("last_sessionid")}`;
    let resultData;
    await this._http.get(serviceURL, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }

  async getLookupColumns(lookupColKey) {
    let lookupCols = this.appConfig[lookupColKey];
    return lookupCols;
  }

  showToast(toastType: string, toastTitle: string, toastMessage: string) {
    this.messageService.add({ key: "toast", severity: toastType, summary: toastTitle, detail: toastMessage });
  }

  showMessage(toastType: string, toastTitle: string, toastMessage: string) {
    this.messageService.add({ key: "msg", sticky: true, severity: toastType, summary: toastTitle, detail: toastMessage });
  }

  //
  private errorSummary: string = "";
  showErrorSummary(objError: any) {
    let isErrorExist = false;
    this.errorSummary = "";
    this.getErrorSummary(objError);
    if (this.errorSummary != "") {
      isErrorExist = true;
      this.messageService.add({ key: "errSummary", sticky: true, severity: 'warn', summary: '', detail: this.errorSummary });
    }
    return isErrorExist;
  }

  getErrorSummary(objError: any) {
    let objKeys = Object.keys(objError);
    _.forEach(objKeys, (key, index) => {
      if (objError[key].constructor === Array) {
        let childObjectAry = objError[key];
        _.forEach(childObjectAry, (aVal, aIndex) => {
          this.getErrorSummary(aVal);
        });
      }
      else if (objError[key].constructor === Object) {
        let childObject = objError[key];
        this.getErrorSummary(childObject);
      }
      else {
        if (objError[key] != "") {
          this.errorSummary += `<div class="show-toaster-box align-items-center d-flex"><i class='pi pi-exclamation-circle mr-1'></i>${objError[key]}</div>`;
        }
      }
    });
  }

  getDecryptString(encryptData) {
    var userInfoBytes = CryptJs.AES.decrypt(encryptData, this.decryptKey);
    var decryptString = userInfoBytes.toString(CryptJs.enc.Utf8);
    return decryptString;
  }

  getEncryptData(data) {
    return CryptJs.AES.encrypt(data, this.decryptKey).toString();
  }

  getDecryptData(encryptData) {
    var userInfoBytes = CryptJs.AES.decrypt(encryptData, this.decryptKey);
    var userInfo = JSON.parse(userInfoBytes.toString(CryptJs.enc.Utf8));
    return userInfo;
  }

  getUserInfo(key: string) {
    var userInfoBytes = CryptJs.AES.decrypt(window.localStorage.getItem("UserInfo"), this.decryptKey);
    var userInfo = JSON.parse(userInfoBytes.toString(CryptJs.enc.Utf8));
    let keyValue = userInfo[0][key];//|| userInfo[0][_.camelCase(key)];
    return keyValue;
  }

  getUserToken() {
    let userToken = this.getUserInfo("token");
    return userToken;
  }

  getUserOrgId() {
    let orgId = this.getUserInfo("org_id");
    return orgId;
  }

  getUserGrpId() {
    let grpId = this.getUserInfo("grp_id");
    return grpId;
  }

  getUserLocId() {
    let locId = this.getUserInfo("location_id");
    return locId;
  }

  getUserLoginSessionId() {
    let loginSessionId = this.getUserInfo("last_sessionid");
    return loginSessionId;
  }

  getUserId() {
    let userId = this.getUserInfo("user_id");
    return userId;
  }

  getUserPermissions() {
    var userPermissionsBytes = CryptJs.AES.decrypt(window.localStorage.getItem("modDocAccess"), this.decryptKey);
    var userPermissions = JSON.parse(userPermissionsBytes.toString(CryptJs.enc.Utf8));
    return userPermissions;
  }

  async getReportData(rootKey, lookupKey, params) {
    let rptData = await this.getLookupData(rootKey, lookupKey, params);
    return rptData;
  }

  //let pdfData = await this._mastersService.getPDF();
  async generatePdfReport(rootKey, lookupKey, params, fileName?, download?, printForm?) {
    download = (download == null || download == undefined ? true : download);
    printForm = (printForm == null || printForm == undefined ? false : printForm);

    let token: any = this.getUserInfo("token");
    var httpHeaders = new HttpHeaders(
      {
        "authorization": token,
        orgId: this.getUserInfo("org_id"),
        grpId: this.getUserInfo("grp_id"),
        locId: this.getUserInfo("location_id")
      }
    );

    let root = this.appConfig[rootKey];
    var serviceURL = this.apiHostUrl + root[lookupKey];
    let paramKeys = Object.keys(params);

    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = params[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });

    let wsAttr = serviceURL.split("?");
    serviceURL = wsAttr[0] + "?";
    if (wsAttr.length > 1) {
      let thisParams = wsAttr[1].split("&");
      _.forEach(thisParams, (param, index) => {
        let paramKeyVal = param.split("=");
        if (paramKeyVal[1] != `{${paramKeyVal[0]}}`) {
          serviceURL += param + "&";
        }
      });
    }
    if (_.endsWith(serviceURL, '&'))
      serviceURL = serviceURL.substring(0, serviceURL.length - 1);

    serviceURL += (serviceURL.indexOf('?') >= 0 ? '&' : '?') + `parSessionId=${this.getUserInfo("last_sessionid")}`;

    let url: any;
    await this._http.get(serviceURL, { headers: httpHeaders, responseType: 'blob', observe: 'response' })
      .toPromise()
      .then(async (data) => {
        if (params.parExportTo == "xlsx" || params.parExportTo == "xlsb") {
          const blob = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          url = window.URL.createObjectURL(blob);

          var a = document.createElement("a");
          document.body.append(a);

          a.href = url;
          a.download = ((fileName || "") == "" ? `Rpt${this._datePipe.transform(new Date(), "ddMMyyyy_HHmmss_SSS")}.${params.parExportTo}` : `${fileName}`);
          a.click();
        }
        else {
          let printOption = 'NEWWIN';
          let userPrintPref = [];
          if (printForm) {
            userPrintPref = await this.getLookupData("userPrintPreferencesApi", "getAllCourseSettingUrl", { parUserId: this.getUserInfo("user_id") });
            if ((userPrintPref || []).length == 0) {
              printOption = "NEWWIN";
            }
            else if (userPrintPref[0]['Status'] == 204) {
              printOption = "NEWWIN";
            }
            else {
              if (parseInt(userPrintPref[0].userPrintPrefId) == 1) {
                printOption = "NEWWIN";
              }
              else if (parseInt(userPrintPref[0].userPrintPrefId) == 2) {
                printOption = "PRINT";
              }
              else if (parseInt(userPrintPref[0].userPrintPrefId) == 3) {
                printOption = "SAVE";
              }
            }
          }

          if (fileName == "") fileName = `Rpt${this._datePipe.transform(new Date(), "ddMMyyyy_HHmmss_SSS")}.pdf`;
          switch (printOption) {
            case "SAVE":
              let saveUrl = window.URL.createObjectURL(data.body);
              var a = document.createElement("a");
              document.body.append(a);

              a.href = saveUrl;
              a.download = fileName;
              a.click();
              break;
            case "NEWWIN":
              let newUrl = window.URL.createObjectURL(data.body);
              window.open(newUrl);
              break;
            case "PRINT":
              const cpj = new JSPM.ClientPrintJob();
              switch (parseInt(userPrintPref[0].printersTypeId)) {
                case 1: // Default Printer
                  cpj.clientPrinter = new JSPM.DefaultPrinter();
                  break;
                case 2: // User Selected Printer
                  cpj.clientPrinter = new JSPM.UserSelectedPrinter();
                  break;
                case 3: // Installed Printer
                  cpj.clientPrinter = new JSPM.InstalledPrinter(userPrintPref[0].installedPrinters);
                  break;
                case 4: // Shared Printer
                  cpj.clientPrinter = new JSPM.InstalledPrinter(userPrintPref[0].sharedPrinters);
                  break;
                case 5: // Network Printer
                  cpj.clientPrinter = new JSPM.NetworkPrinter(userPrintPref[0].printerPortNo, userPrintPref[0].printersIpAddress);
                  break;
                case 6: // Blue Printer
                  cpj.clientPrinter = new JSPM.BluetoothPrinter(userPrintPref[0].bluetoothPrintersAddressId, userPrintPref[0].channelNumber);
                  break;
                case 7: // Parallel Port Printer
                  cpj.clientPrinter = new JSPM.ParallelPortPrinter(userPrintPref[0].parallelPort);
                  break;
                case 8: // Serial Port Printer
                  cpj.clientPrinter = new JSPM.SerialPortPrinter(userPrintPref[0].serialPortName, userPrintPref[0].serialPortNo, JSPM.SerialPort.Parity.None, JSPM.SerialPort.StopBits.One, JSPM.Serial.DataBits.Eight, JSPM.SerialPort.Handshake.XOnXOff);
                  break;
              }

              var fileToPrint = new JSPM.PrintFilePDF(data.body, JSPM.FileSourceType.BLOB, fileName, 1);
              cpj.files.push(fileToPrint);
              // Send print job to printer!
              cpj.sendToClient();
              break;
          }
        }
      });
  }

  async getDocImage(rootKey, lookupKey, params) {

    let token: any = this.getUserInfo("token");
    var httpHeaders = new HttpHeaders(
      {
        "authorization": token,
        orgId: this.getUserInfo("org_id"),
        grpId: this.getUserInfo("grp_id"),
        locId: this.getUserInfo("location_id")
      }
    );

    let root = this.appConfig[rootKey];
    var serviceURL = this.apiHostUrl + root[lookupKey];
    let paramKeys = Object.keys(params);

    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = params[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });

    let wsAttr = serviceURL.split("?");
    serviceURL = wsAttr[0] + "?";
    if (wsAttr.length > 1) {
      let thisParams = wsAttr[1].split("&");
      _.forEach(thisParams, (param, index) => {
        let paramKeyVal = param.split("=");
        if (paramKeyVal[1] != `{${paramKeyVal[0]}}`) {
          serviceURL += param + "&";
        }
      });
    }
    if (_.endsWith(serviceURL, '&'))
      serviceURL = serviceURL.substring(0, serviceURL.length - 1);

    serviceURL += (serviceURL.indexOf('?') >= 0 ? '&' : '?') + `parSessionId=${this.getUserInfo("last_sessionid")}`;
    let imageContent: any;

    await this._http.get(serviceURL, { headers: httpHeaders, responseType: 'blob', observe: 'response' })
      .toPromise()
      .then((data) => {
        imageContent = data.body;
      });

    return imageContent;
  }


  // Without Authentication Token
  // Insert / Update master record
  async insertUpdateMasterWithoutToken(urlKey, masterData: any) {
    var postData = JSON.stringify(masterData);
    var serviceURL = this.apiHostUrl + this.masterApiUrl[urlKey];
    var httpHeaders = new HttpHeaders(
      {
        'contentType': 'application/json; charset=utf-8'
      }
    );

    let resultData;
    await this._http.post(serviceURL, masterData, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }

  async getMasterDataWithoutToken(urlKey, params) {
    var serviceURL = this.apiHostUrl + this.masterApiUrl[urlKey];
    let paramKeys = Object.keys(params);

    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = params[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });
    let wsAttr = serviceURL.split("?");
    serviceURL = wsAttr[0] + "?";
    if (wsAttr.length > 1) {
      let thisParams = wsAttr[1].split("&");
      _.forEach(thisParams, (param, index) => {
        let paramKeyVal = param.split("=");
        if (paramKeyVal[1] != `{${paramKeyVal[0]}}`) {
          serviceURL += param + "&";
        }
      });
    }
    if (_.endsWith(serviceURL, '&'))
      serviceURL = serviceURL.substring(0, serviceURL.length - 1);
    //serviceURL += (serviceURL.indexOf('?') >= 0 ? '&' : '?') + `parSessionId=${this.getUserInfo("last_sessionid")}`;

    let resultData;
    await this._http.get(serviceURL)
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }

  // Get Lookup Data
  async getLookupDataWithoutToken(rootKey, lookupKey, params) {
    let root = this.appConfig[rootKey];
    var serviceURL = this.apiHostUrl + root[lookupKey];
    let paramKeys = Object.keys(params);

    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = params[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });
    let wsAttr = serviceURL.split("?");
    serviceURL = wsAttr[0] + "?";
    if (wsAttr.length > 1) {
      let thisParams = wsAttr[1].split("&");
      _.forEach(thisParams, (param, index) => {
        let paramKeyVal = param.split("=");
        if (paramKeyVal[1] != `{${paramKeyVal[0]}}`) {
          serviceURL += param + "&";
        }
      });
    }
    if (_.endsWith(serviceURL, '&'))
      serviceURL = serviceURL.substring(0, serviceURL.length - 1);

    if (params.sessionReq != "N")
      serviceURL += (serviceURL.indexOf('?') >= 0 ? '&' : '?') + `parSessionId=${this.getUserInfo("last_sessionid")}`;

    let resultData;
    await this._http.get(serviceURL)
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }

  async validateUser(urlKey: string, userInfo: any) {
    var httpHeaders = new HttpHeaders({ "parusername": userInfo.UserName, "parpassword": userInfo.Password })
    var serviceURL = this.apiHostUrl + this.masterApiUrl[urlKey];
    //var serviceURL = this.masterApiUrl[urlKey];
    let paramKeys = Object.keys(userInfo);
    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = userInfo[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });
    let resultData;
    await this._http.get(serviceURL, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      })
      .catch((err) => {

      });
    return resultData;
  }

  async generatePdfReportWithoutToken(rootKey, lookupKey, params) {
    var httpHeaders = new HttpHeaders(
      {
        orgId: this.getUserInfo("org_id"),
        grpId: this.getUserInfo("grp_id"),
        locId: this.getUserInfo("location_id")
      }
    );

    let root = this.appConfig[rootKey];
    var serviceURL = this.apiHostUrl + root[lookupKey];
    let paramKeys = Object.keys(params);

    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = params[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });

    let wsAttr = serviceURL.split("?");
    serviceURL = wsAttr[0] + "?";
    if (wsAttr.length > 1) {
      let thisParams = wsAttr[1].split("&");
      _.forEach(thisParams, (param, index) => {
        let paramKeyVal = param.split("=");
        if (paramKeyVal[1] != `{${paramKeyVal[0]}}`) {
          serviceURL += param + "&";
        }
      });
    }
    if (_.endsWith(serviceURL, '&'))
      serviceURL = serviceURL.substring(0, serviceURL.length - 1);

    serviceURL += (serviceURL.indexOf('?') >= 0 ? '&' : '?') + `parSessionId=${this.getUserInfo("last_sessionid")}`;

    let url: any;
    await this._http.get(serviceURL, { headers: httpHeaders, responseType: 'blob', observe: 'response' })
      .toPromise()
      .then((data) => {
        if (params.parExportTo == "xlsx" || params.parExportTo == "xlsb") {
          const blob = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          url = window.URL.createObjectURL(blob);

          var a = document.createElement("a");
          document.body.append(a);

          a.href = url;
          a.download = `Rpt${this._datePipe.transform(new Date(), "ddMMyyyyHHmmssSSS")}.${params.parExportTo}`;
          a.click();
        }
        else {
          url = window.URL.createObjectURL(data.body);
          window.open(url);
        }
      });
  }

  async changeLang(lang, text) {
    let transText = text;
    await this._http.get(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=${lang}&dt=t&q=${encodeURI(text)}`)
      .toPromise()
      .then((res) => {
        transText = res[0][0][0];
      });
    return transText;
  }

  async convetBase64ToBlob(base64) {
    const splitDataURI = base64.split(",");
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia]);
  }

  async getIpAddress() {
    let ipAddress = "127.0.0.1";
    await this._http.get(`http://api.ipify.org/?format=json`)
      .toPromise()
      .then((res) => {
        ipAddress = res["ip"];
      });
    return ipAddress;
  }
  //treeTable Data
  getFilesystem() {
    return this._http.get('showcase/resources/data/filesystem.json')
      .toPromise()
      .then((res: any) => <TreeNode[]>res.json().data);
  }

  async compressImage(file) {
    let fileType = 'jpg';
    var mimeType = file['type'];

    if (file.name.toUpperCase().indexOf(".ZIP") >= 0 || file.name.toUpperCase().indexOf(".RAR") >= 0)
      fileType = 'zip';
    else if ((mimeType || "") == "") {
      fileType = (file.name.toUpperCase().indexOf(".PDF") >= 0 ? 'pdf' : 'jpg');
    }
    else {
      if (mimeType.toUpperCase().indexOf("PDF") >= 0 || mimeType.toUpperCase().indexOf("KSWPS") >= 0)
        fileType = 'pdf';
      else if (mimeType.toUpperCase().indexOf("VIDEO") >= 0)
        fileType = 'video';
      else if (mimeType.toUpperCase().indexOf("AUDIO") >= 0)
        fileType = 'audio';
      else if (mimeType.toUpperCase().indexOf("OFFICEDOCUMENT") >= 0
        || mimeType.toUpperCase().indexOf("MS-EXCEL") >= 0
        || mimeType.toUpperCase().indexOf("MSWORD") >= 0
        || mimeType.toUpperCase().indexOf("MS-POWERPOINT") >= 0)
        fileType = 'office';
      else
        fileType = 'jpg';
    }

    if (fileType == 'jpg') {
      return new Promise<File>((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async (event: any) => {
          let originalImageBase64 = event.target.result;
          let originalImageName = file['name'];
          let originalImageMimeType = file['type'];

          var orientation = -1;
          this.imageCompress.compressFile(originalImageBase64, orientation, 50, 50).then(result => {
            fetch(result)
              .then(res => res.blob())
              .then(blob => {
                let compressedImage = new File([blob], originalImageName, { type: originalImageMimeType });
                resolve(compressedImage);
              });
          });
        }
      });
    }
    else
      return file;
  }

  async getDropdownOptions(masterApi: string, getAllApi: string, params: any, valueField: string, labelField: string, lookupName: string, filter: boolean) {
    let lookupData = await this.getLookupData(masterApi, getAllApi, params);
    let dropdownArray = [];

    if (filter) {
      dropdownArray.push({ 'label': `Select ${lookupName}`, 'value': "" })
      _.forEach(lookupData, (value, index) => {
        if (value.recordStatus == 'A') {
          dropdownArray.push({ 'label': value[labelField], 'value': value[valueField] })
        }
      });
    }
    else {
      dropdownArray = lookupData;
    }
    return dropdownArray;
  }

  async getDropdownOptionsV2(masterApi: string, getAllApi: string, params: any, valueField: string = "", labelField: string = "", lookupName: string = "One", isMultiSelect: boolean = false, filterBy: any = null) {
    let lookupData = await this.getLookupData(masterApi, getAllApi, params);
    let dropdownArray = [];

    if (filterBy != null && filterBy != undefined) {
      lookupData = _.filter(lookupData, filterBy);
    }

    valueField = (valueField || "");
    labelField = (labelField || "");
    if (valueField != "" && labelField != "") {
      if (!isMultiSelect) dropdownArray.push({ 'label': `Select ${lookupName}`, 'value': "" })
      _.forEach(lookupData, (value, index) => {
        dropdownArray.push({ 'label': value[labelField], 'value': value[valueField] })
      });
    }
    else {
      dropdownArray = lookupData;
    }
    return dropdownArray;
  }

  // App Config 
  getAppConfigKeyInfo(key: string) {
    let thisConfigKeyValue: any;
    var appConfigBytes = CryptJs.AES.decrypt(window.localStorage.getItem("appConfigData"), this.decryptKey);
    var appConfig = JSON.parse(appConfigBytes.toString(CryptJs.enc.Utf8));
    let keyValue = _.filter(appConfig, { appConfigKeyName: key });
    if (keyValue.length > 0) {
      if (keyValue[0].orgLocLevel == "Org")
        thisConfigKeyValue = keyValue[0].appConfigValue;
      else
        thisConfigKeyValue = keyValue;
    }
    return thisConfigKeyValue;
  }

  getAppConfigKeyInfoLoc(key: string) {
    let thisConfigKeyValue: any;
    var appConfigBytes = CryptJs.AES.decrypt(window.localStorage.getItem("appConfigData"), this.decryptKey);
    var appConfig = JSON.parse(appConfigBytes.toString(CryptJs.enc.Utf8));
    let keyValue = _.filter(appConfig, { appConfigKeyName: key ,configLocId : this.getUserInfo("location_id") });
    if (keyValue.length > 0) {
      if (keyValue[0].orgLocLevel == "Loc")
        thisConfigKeyValue = keyValue[0].appConfigValue;
      else
        thisConfigKeyValue = keyValue;
    }
    return thisConfigKeyValue;
  }

  getFacilitiesCheckInorOut(ctrl) {
    let isInorOut = "";
    switch (ctrl) {
      case "CKIN":
        isInorOut = this.getAppConfigKeyInfo("CheckIn") || "N"
        break;
      case "CKOUT":
        isInorOut = this.getAppConfigKeyInfo("CheckOut") || "N"
        break;
    }
    return isInorOut
  }

  getAppInstance() {
    let appInstance = this.getAppConfigKeyInfo("appInstance") || "";
    return appInstance;
  }


  ELearningSubjects() {
    let ELearningSubjects = this.getAppConfigKeyInfo("ELearningSubjects") || "";
    return ELearningSubjects;
  }


  getClientFullName() {
    let longName = this.getAppConfigKeyInfo("longName") || "";
    return longName;
  }

  getClientShortName() {
    let shortName = this.getAppConfigKeyInfo("shortName") || "";
    return shortName;
  }

  getClientLogo() {
    let logo = this.getAppConfigKeyInfo("logo") || "";
    return logo;
  }

  showLoginFooter() {
    let showLoginFooter = (this.getAppConfigKeyInfo("showLoginFooter") == "Y");
    return showLoginFooter;
  }

  showForgotPassword() {
    let showForgotPwd = (this.getAppConfigKeyInfo("showForgotPwd") == "Y");
    return showForgotPwd;
  }

  getCetDbRefreshTime() {
    let refreshTime = this.getAppConfigKeyInfo("cetDbRefreshTime") || 60;
    return refreshTime;
  }

  showAllModules() {
    let showAllModules = (this.getAppConfigKeyInfo("showAllModules") == "Y");
    return showAllModules;
  }
  isShowStudentExtLabel() {
    return this.appConfig['isShowStudentExtLabel']
  }
  getIdleTime() {
    let idleTime = this.getAppConfigKeyInfo("idleTime") || "";
    if (idleTime != "") {
      idleTime = JSON.parse(idleTime);
      if (idleTime.length > 0) idleTime = idleTime[0];
    }
    else {
      idleTime = { required: "N" };
    }
    return idleTime;
  }

  showPrnLookup() {
    let applicablePrnLookUp = (this.getAppConfigKeyInfo("applicablePrnLookUp") == "Y");
    return applicablePrnLookUp;
  }

  getSampleFileTemplatePath() {
    let templatePath = this.getAppConfigKeyInfo("chatUrl") || "";
    if (templatePath != "") {
      templatePath = JSON.parse(templatePath);
      if (templatePath.length > 0) templatePath = templatePath[0].endPoint;
    }
    else {
      templatePath = "";
    }
    return templatePath;
  }

  allowMultiPayModes() {
    let allowMultiPayModes = (this.getAppConfigKeyInfo("allowMultiPayModes") == "Y");
    return allowMultiPayModes;
  };
  allowCetMultiPayModes() {
    let allowMultiPayModes = (this.getAppConfigKeyInfo("allowMultiPayModesForConselling") == "Y");
    return allowMultiPayModes;
  };

  onlineExamLateTimeAllow() {
    let onlineExamLateTimeAllow = this.getAppConfigKeyInfo("onlineExamLateTimeAllow") || "";
    onlineExamLateTimeAllow = (onlineExamLateTimeAllow == "" || isNaN(onlineExamLateTimeAllow) ? 0 : parseFloat(onlineExamLateTimeAllow))
    return onlineExamLateTimeAllow;
  }

  allowAdvInFeeCol() {
    let allowAdvInFeeCol = (this.getAppConfigKeyInfo("allowAdvInFeeCol") == "Y");
    return allowAdvInFeeCol;
  }

  enableHostelLateFeeChk() {
    let enableHostelLateFeeChk = (this.getAppConfigKeyInfo("enableHostelLateFeeChk") == "Y");
    return enableHostelLateFeeChk;
  }

  anonymusLoginCounselling() {
    let anonymusLoginCounselling = this.getAppConfigKeyInfo("anonymusLoginCounselling");
    return anonymusLoginCounselling;
  }

  allowMultiLanguage() {
    let allowMultiLanguage = (this.getAppConfigKeyInfo("allowMultiLanguage") == "Y");
    return allowMultiLanguage;
  }

  allowOnlinePayment() {
    let allowOnlinePayment = (this.getAppConfigKeyInfo("allowOnlinePayment") == "Y");
    return allowOnlinePayment;
  }

  Allowadmissionwithoutfeescheme() {
    let Allowadmissionwithoutfeescheme = (this.getAppConfigKeyInfo("Allowadmissionwithoutfeescheme") == "Y");
    return Allowadmissionwithoutfeescheme;
  }

  EnableScholarship() {
    let EnableScholarship = (this.getAppConfigKeyInfo("EnableScholarship") == "Y");
    return EnableScholarship;
  }

  IsHostelAmountEditable() {
    let IsHostelAmountEditable = (this.getAppConfigKeyInfo("IsHostelAmountEditable") == "Y");
    return IsHostelAmountEditable;
  }

  IsSchool() {
    let IsSchool = (this.getAppConfigKeyInfoLoc("IsSchool") == "Y");
    return IsSchool;
  }

  getNotificationUrlPrefix() {
    let notificationUrlPrefix = this.getAppConfigKeyInfo("notificationUrlPrefix");
    return notificationUrlPrefix;
  }
  getExcelUploadTime() {
    let ExcelUploadTime = this.getAppConfigKeyInfo("ExcelUploadTime");
    return ExcelUploadTime;
  }

  SystemNotificationRefreshTime() {
    let SystemNotificationRefreshTime = this.getAppConfigKeyInfo("SystemNotificationRefreshTime");
    return SystemNotificationRefreshTime;
  }

  getChatUrl() {
    let chatUrl = this.getAppConfigKeyInfo("chatUrl") || "";
    if (chatUrl != "") {
      chatUrl = JSON.parse(chatUrl);
      if (chatUrl.length > 0) chatUrl = chatUrl[0];
    }
    else {
      chatUrl = {};
    }
    return chatUrl;
  }
  getMaxFacultyforTT() {
    let maxfaculty = this.getAppConfigKeyInfo("MaxFacultyAllowed(Timetable)");
    return maxfaculty;
  }
  enableMobileApp() {
    let enableMobileApp = (this.getAppConfigKeyInfo("EnableMobileApp") == "Y");
    return enableMobileApp;
  }

  getAndroidURL() {
    let androidURL = this.getAppConfigKeyInfo("MobileApp(Android URL)") || "";
    return androidURL;
  }

  getIosURL() {
    let iosURL = this.getAppConfigKeyInfo("MobileApp(IOS URL)") || "";
    return iosURL;
  }

  sortBy(collection, predicate) { // collection : Array of Objects, predicate: Object or a function
    let result = _.sortBy(collection, predicate);
    return result;
  }

  filterBy(collection, predicate) { // collection : Array of Objects, predicate: Object or a function
    let result = _.filter(collection, predicate);
    return result;
  }

  orderBy(collection, orderBy, orderDirection = ['asc']) { // collection : Array of Objects, orderBy: Array of Object Keys, orderDirection: Array ('asc', 'desc')
    let result = _.orderBy(collection, orderBy, orderDirection);
    return result;
  }

  uniqBy(collection, key) { // collection : Array of Objects, key: Object Key
    let result = _.uniqBy(collection, key);
    return result;
  }

  uniqMapSortJoin(collection, key, sort: boolean = false, joinBy: string = "") { // collection : Array of Objects, key: Object Key, sort: boolean
    let result = _.uniq(_.map(collection, key));
    if (sort) result = result.sort();
    joinBy = (joinBy || "");
    if (joinBy != "") result = result.join(joinBy);
    return result;
  }

  mapJoin(collection, key, joinBy: string = ",") { // collection : Array of Objects, key: Object Key, joinBy: string
    let result = _.map(collection, key).join(joinBy);
    return result;
  }

  orderUniqWithPick(collection, pickKeys, orderBy, orderDirection = ['asc']) { // collection : Array of Objects, pickKeys: Array of Object Keys, orderBy: Array of Object Keys, orderDirection: Array ('asc', 'desc')
    let result = _.orderBy(_.uniqWith(_.map(collection, _.partialRight(_.pick, pickKeys)), _.isEqual), orderBy, orderDirection);
    return result;
  }

  reverse(collection) {
    let result = _.reverse(collection);
    return result;
  }

  /* --------- Load / Unload dynamic Scripts ---------------
    public extBarcodeScripts = [
    "../assets/js/pdf.js",
    "../assets/js/barcode/quagga.min.js",
    "../assets/js/barcode/pdf.min.js",
    "../assets/js/barcode/pdf-barcode.min.js",
    "../assets/js/qrcode/jsQR.js",
    "../assets/js/qrcode/pdf.min.js",
    "../assets/js/qrcode/pdf-qr.js"
  ];

  loadBarcodeScripts() {
    _.forEach(this.extBarcodeScripts, (scrUrl, index) => {
      const node = document.createElement("script");
      node.src = scrUrl;
      node.type = "text/javascript";
      node.async = false;
      node.charset = "utf-8";
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

  removeBarcodeScripts() {
    let targetElement = 'script';
    let targetAttr = 'src';
    let jsFiles = document.getElementsByTagName(targetElement);

    _.forEach(this.extBarcodeScripts, (srcUrl, index) => {
      let thisJsFile = _.filter(jsFiles, jsFile => {
        if (jsFile && jsFile.getAttribute(targetAttr) != null && jsFile.getAttribute(targetAttr).toString().indexOf(srcUrl) != -1)
          return jsFile;
      });
      if (thisJsFile.length > 0) {
        thisJsFile[0].parentNode.removeChild(thisJsFile[0]);
      }
    });
  }
  */

  async loadDynamicScripts(scriptsFor) {
    // this._http.get('assets/config/appconfig.json');
    if (Object.keys(this.appConfig).length == 0) {
      await this.getConfigData();
    }

    _.forEach(scriptsFor, (jsScript, sIndex) => {
      let dynamicScripts = this.appConfig["dynamicJsFiles"][jsScript];
      _.forEach(dynamicScripts, (scrUrl, index) => {
        const node = document.createElement("script");
        node.src = scrUrl;
        node.type = "text/javascript";
        node.async = false;
        node.charset = "utf-8";
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    });

  }

  removeDynamicScripts(scriptsFor) {
    let targetElement = 'script';
    let targetAttr = 'src';
    let jsFiles = document.getElementsByTagName(targetElement);

    _.forEach(scriptsFor, (jsScript, sIndex) => {
      let dynamicScripts = this.appConfig["dynamicJsFiles"][jsScript];
      _.forEach(dynamicScripts, (srcUrl, index) => {
        let thisJsFile = _.filter(jsFiles, jsFile => {
          if (jsFile && jsFile.getAttribute(targetAttr) != null && jsFile.getAttribute(targetAttr).toString().indexOf(srcUrl) != -1)
            return jsFile;
        });
        if (thisJsFile.length > 0) {
          thisJsFile[0].parentNode.removeChild(thisJsFile[0]);
        }
      });
    });
  }

  // Barcode / QR Code Reader Start
  async readBarcodeQRCode(fileInput) {
    let barcodeConfig = this.appConfig["barcodeReaderConfig"];
    let qrcodeConfig = this.appConfig["qrcodeReaderConfig"];

    return new Promise(async (resolve, reject) => {
      await PDFBarcodeJs.decodeSinglePage(fileInput, 1, barcodeConfig,
        async (result) => {
          if (result.codes.length > 0) {
            resolve({ type: "Barcode", result: result });
          }
          else {
            await PDF_QR_JS.decodeSinglePage(fileInput, 1, qrcodeConfig,
              (result) => {
                if (result.codes.length > 0) {
                  resolve({ type: "QR Code", result: result });
                }
                else {
                  resolve({ type: null, result: [null] });
                }
              }
            );
          }
        }
      );
    });
  }

  async readAnyCode(fileInput) {
    try {
      let thisFileQrcde = null;
      await this.readBarcodeQRCode(fileInput)
        .then(data => {
          thisFileQrcde = data
        });
      return thisFileQrcde;
    }
    catch (e) { }
  }
  // Barcode / QR Code Reader End

  //Online Scanning ( Scanner.js )
  async scanDocument(imgScanSettings, returnType?, fileName?) {
    returnType = returnType || "file";
    fileName = fileName || `File_${new Date().getTime()}`;

    let thisDocuments;
    await this.scanToFile(imgScanSettings, returnType, fileName)
      .then(scannResult => {
        thisDocuments = scannResult;
      });

    return thisDocuments;
  }

  scanToFile = async (imgScanSettings, returnType, fileName) => {
    let scanJsLicenseKey = this.getAppConfigKeyInfo("scanjsSettings")["licenseKey"];
    var scanSettings = {
      "use_asprise_dialog": false,
      "license": scanJsLicenseKey,
      "twain_cap_setting": {
        "ICAP_PIXELTYPE": imgScanSettings.pixelType,
        "ICAP_XSCALING/RESET": "null",
        "ICAP_XRESOLUTION": imgScanSettings.resolution,
        "ICAP_YRESOLUTION": imgScanSettings.resolution,
        "CAP_AUTOFEED": false,
        "ICAP_FRAMES": "(0, 0, 4, 6)",
        "ICAP_SUPPORTEDSIZES": imgScanSettings.paperSize,
        "ICAP_ORIENTATION": imgScanSettings.orientation
      },
      "prompt_scan_more": imgScanSettings.promptScanMore,
      "i18n": {
        "lang": "en"
      },
      "output_settings": [{
        "type": "return-base64",
        "format": imgScanSettings.fileFormat,
      }]
    };

    return new Promise(async (resolve, reject) => {
      scanner.scan(
        async (successful, mesg, response) => {
          var thisScannedFiles = await this.processImage(successful, mesg, response, returnType, fileName, imgScanSettings.fileFormat);
          resolve(thisScannedFiles);
        },
        scanSettings);
    });
  }

  async processImage(successful, mesg, response, returnType, filename, fileFormat) {
    var scannedFiles = [];

    if (!successful) { // On error
      console.error('Failed Scanning: ' + mesg);
    }
    else if (successful && mesg != null && mesg.toLowerCase().indexOf('user cancel') >= 0) { // User canceled.
      console.info('User canceled');
    }
    else {
      var scannedImages = scanner.getScannedImages(response, true, false); // returns an array of ScannedImage
      for (var i = 0; (scannedImages instanceof Array) && i < scannedImages.length; i++) {
        var scannedImage = scannedImages[i];
        if (scannedImage.src.split(";base64,")[1].length > 0) {
          switch (returnType) {
            case "file":
              filename = (scannedImages.length == 1 ? `${filename}.${fileFormat}` : `${filename}-${i + 1}.${fileFormat}`);
              var file = await this.urltoFile(scannedImage.src, filename, scannedImage.mimeType);
              scannedFiles.push(file);
              break;
            case "base64":
              scannedFiles.push(
                {
                  image: scannedImage.src,
                  mimeType: scannedImage.mimeType
                }
              );
              break;
          }
        }
      }
    }
    return scannedFiles;
  }

  async urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, {
          type: mimeType
        });
      })
    );
  }
  // Get Scan ConfigList
  getOptionsList(masterApi, settingName) {
    return this.appConfig[masterApi][settingName];
  }

  showCustomTooltip(element, tooltip) {
    //setTimeout(() => {
    tippy(element, {
      content: tooltip,
      allowHTML: true,
      animation: "fade"
    });
    //}, 1100);
  }

  async getGridV2Permission(pageUrl) {
    let v2GridPermissions = []
    if (this.v2GridConfig.length > 0) {
      v2GridPermissions = _.filter(this.v2GridConfig, { pageUrl: pageUrl });
    }
    return v2GridPermissions;
  }

  async getPageDimentions(pageSize) {
    let pageDimentions = await this.appConfig["paperSizeDim"];
    pageDimentions = pageDimentions[pageSize];
    return pageDimentions || { // Default A4
      "w": 595,
      "h": 842
    };
  }

  // JS Print Manager 
  getJspmWsStatus() {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed || JSPM.JSPrintManager.websocket_status === undefined) {
      this.messageService.add(
        {
          key: "infoMsg",
          sticky: true,
          severity: 'info',
          summary: 'Printer Client App',
          detail: "JSPrintManager (JSPM) is not installed or not running!<br><a href='./assets/jspmclientapp/jspm6-6.0.0.0-win.exe' target='new'><b>Click here</b></a> to Download JSPM Client App."
        });
      return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
      this.messageService.add(
        {
          key: "infoMsg",
          sticky: true,
          severity: 'true',
          summary: 'Printer Client App',
          detail: "JSPM has blocked this website!"
        });
      return false;
    }
  }

  async getConnectedPrinters() {
    let connectedPrinters = [];
    if (this.getJspmWsStatus()) {
      await JSPM.JSPrintManager.getPrintersInfo()
        .then(async (printersInfo) => {
          connectedPrinters = printersInfo
        });
    }
    return connectedPrinters;
  }

  async getBluetoothDevices() {
    let btDevices = [];
    if (this.getJspmWsStatus()) {
      await JSPM.JSPrintManager.getBluetoothDevices()
        .then(async (btInfo) => {
          btDevices = btInfo
        });
    }
    return btDevices;
  }

  enableLabel(prfrnceId) {
    let iconClass = "";
    switch (prfrnceId) {
      case "1": // open in new window
        iconClass = 'fa fa-external-link-alt';
        break;
      case "2": // direct 
        iconClass = 'fas fa-print';
        break;
      case "3": // download to system
        iconClass = 'fas fa-download';
        break;
    }
    return iconClass;
  }

  displayConvertName(inputText) {
    if (inputText) {
      let t = new toWords.ToWords();
      return t.convert(parseInt(inputText), { currency: true })
    }
  }

  async getMAxallowedSize(pageSize) {
    let sizeObj = await this.appConfig["maxUploadSize"];
    sizeObj = sizeObj[pageSize];
    return sizeObj;
  }

  async getCetCourseId(pageSize) {
    let sizeObj = await this.appConfig["cetCourse"];
    sizeObj = sizeObj[pageSize];
    return sizeObj;
  }

  deletePrpAtSave(_object: any, level: string, ctrl: string) {
    let getDeleteProps = this.getGridColumns(ctrl);
    if (getDeleteProps.length > 0) {
      if (getDeleteProps[level == 'L1' ? 0 : 1][level].length > 0) {
        _.forEach(getDeleteProps[level == 'L1' ? 0 : 1][level], (delPrps, delIn) => {
          if (level == 'L1') {
            delete _object[delPrps];
          } else {
            _.forEach(_object, (arrPrp, arrIn) => {
              delete arrPrp[delPrps];
            });
          }
        });
      };
    };
    return _object;
  }

  showAcdYear() {
    let applicableAcdYear = (this.getAppConfigKeyInfo("AcademicNameEnable") == "Y");
    return applicableAcdYear;
  }

  getConfigBatchorAcdmc(ctrl) {
    let isInorOut = "";
    switch (ctrl) {
      case "BATCH":
        isInorOut = this.getAppConfigKeyInfo("BatchDateRequired");
        break;
      case "ACDMC":
        isInorOut = this.getAppConfigKeyInfo("AcademicNameEnable");
    }
    return isInorOut
  }

  getAyDatesValidation() {
    let maxfaculty = this.getAppConfigKeyInfo("AcademicDateRequired");
    return maxfaculty;
  }
  getQrConfig(ctrl) {
    let isInorOut = null;
    switch (ctrl) {
      case "QrCodeColour":
        isInorOut = this.getAppConfigKeyInfo("QrCodeColour");
        break;
      case "QrBackgroundColour":
        isInorOut = this.getAppConfigKeyInfo("QrBackgroundColour");
        break;
      case "QrRefreshingTime":
        isInorOut = this.getAppConfigKeyInfo("QrRefreshingTime");
        break;
      case "QrRefreshingDuration":
        isInorOut = this.getAppConfigKeyInfo("QrRefreshingDuration");
        break;
      case "IsSlotValidation":
        isInorOut = this.getAppConfigKeyInfo("IsSlotValidation");
        break;
      case "qrradius":
        isInorOut = this.getAppConfigKeyInfo("qrradius");
        break;
      case "StudAttOptions":
        isInorOut = this.getAppConfigKeyInfo("StudAttOptions");
        break;
    }
    return isInorOut
  }
}