import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';

import { AppComponent } from './app.component';
import { GlobalData } from './globaldata';
import { LoginComponent } from './home/login.component';
import { HomeComponent } from './home/home.component';
import { StudhomeComponent } from './portals/student/studhome.component';
import { ModulesComponent } from './masters/modules.component';
import { ModulepageComponent } from './home/modulepage.component';
import { SharedComponentsModule } from './sharedcomponents/sharedcomponents.module';
import { NgIdleModule } from "@ng-idle/core";
import { NgxImageCompressService } from "ngx-image-compress";
import { PrivacypageComponent } from './home/privacypage.component';

// import { SavedirectComponent } from './directives/savedirect/savedirect.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    StudhomeComponent,
    ModulesComponent,
    ModulepageComponent,
    PrivacypageComponent
    // SavedirectComponent
  ],
  imports: [
    SharedComponentsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LayoutModule,
    NgIdleModule.forRoot()
  ],
  providers: [
    GlobalData,
    DatePipe,
    NgxImageCompressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }