
$(function () {
  setTimeout(()=> {
    'use strict'

    $('.az-sidebar .with-sub').on('click', function (e) {
      e.preventDefault();
      $(this).parent().toggleClass('show');
      $(this).parent().siblings().removeClass('show');
    })

    $(document).on('click touchstart', function (e) {
      e.stopPropagation();

      // closing of sidebar menu when clicking outside of it
      if (!$(e.target).closest('.az-header-menu-icon').length) {
        var sidebarTarg = $(e.target).closest('.az-sidebar').length;
        if (!sidebarTarg) {
          $('body').removeClass('az-sidebar-show');
        }
      }
    });

    $('#azSidebarToggle').on('click', function (e) {
      e.preventDefault();

      if (window.matchMedia('(min-width: 992px)').matches) {
        $('body').toggleClass('az-sidebar-hide');
      } else {
        $('body').toggleClass('az-sidebar-show');
      }
    });

    $('.az-iconbar .nav-link').on('click', function (e) {
      e.preventDefault();

      $(this).addClass('active');
      $(this).siblings().removeClass('active');

      $('.az-iconbar-aside').addClass('show');

      var targ = $(this).attr('href');
      if (targ != "#") {
        $(targ).addClass('show');
        $(targ).siblings().removeClass('show');
      }
    });

    $('.az-iconbar-toggle-menu').on('click', function (e) {
      e.preventDefault();

      if (window.matchMedia('(min-width: 992px)').matches) {
        $('.az-iconbar .nav-link.active').removeClass('active');
        $('.az-iconbar-aside').removeClass('show');
      } else {
        $('body').removeClass('az-iconbar-show');
      }
    })

    $('#azIconbarShow').on('click', function (e) {
      e.preventDefault();
      $('body').toggleClass('az-iconbar-show');

      var targ = $('.az-iconbar .nav-link.active').attr('href');
      $(targ).addClass('show');
    });

    $(document).bind('click touchstart', function (e) {
      e.stopPropagation();

      var azContent = $(e.target).closest('.az-content').length;
      var azIconBarMenu = $(e.target).closest('.az-header-menu-icon').length;

      if ($(e.target).closest('.nav-sub-link').length) {
        $('.az-iconbar-aside').removeClass('show');
      }

      if (azContent) {
        $('.az-iconbar-aside').removeClass('show');

        // for mobile
        if (!azIconBarMenu) {
          $('body').removeClass('az-iconbar-show');
        }
      }
    });
  }, 1000);
});