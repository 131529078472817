import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalData } from '../globaldata';
import * as _ from 'node_modules/lodash';
import { MastersService } from "./masters.service"

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiHostUrl = "";
  private masterApiUrl: any = {};
  private appConfig: any = {};

  constructor(
    private _http: HttpClient,
    private _globalData: GlobalData,
    private _masterService: MastersService) {
    this.getConfigData();
  }

  // Read "app config" file
  async getConfigData() {
    let promise;
    await this._globalData.getConfigData().toPromise()
      .then((res) => {
        promise = res;
      })
    this.appConfig = promise;
    this.apiHostUrl = this.appConfig["apiHostUrl"];
  }

  // Set master api attribute
  async setMasterApiUrl(masterApi) {
    this.masterApiUrl = await this.appConfig[masterApi];
  }

  async validateUser(urlKey: string, userInfo: any) {
    var httpHeaders = new HttpHeaders({ "parusername": userInfo.UserName, "parpassword": userInfo.Password })

    var serviceURL = this.masterApiUrl[urlKey];
    let paramKeys = Object.keys(userInfo);
    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = userInfo[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });
    let resultData;
    await this._http.get(serviceURL, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      })
      .catch((err) => {

      });
    return resultData;
  }

  // Read "app config" file
  async gerUserMenu() {
    let promise
    await this._globalData.getMenu().toPromise()
      .then((res) => {
        promise = res;
      })
    return promise;
  }

  async preLoginCheck(urlKey, params) {
    var httpHeaders = new HttpHeaders({ "parusername": params.parUserName, "parpassword": params.parPassword })

    var serviceURL = this.masterApiUrl[urlKey];
    let resultData;
    await this._http.get(serviceURL, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      })
      .catch((err) => {

      });
    return resultData;
  }

  async moderatorLogin(urlKey, params) {
    var serviceURL = this.apiHostUrl + this.masterApiUrl[urlKey];
    var httpHeaders = new HttpHeaders(
      {
        'contentType': 'application/json; charset=utf-8'
      }
    );

    let resultData;
    await this._http.post(serviceURL, params, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      })
      .catch((err) => {

      });
    return resultData;
  }

  async resetPassword(urlKey, params) {
    var httpHeaders = new HttpHeaders({ "parusername": params.parUserName })

    var serviceURL = this.masterApiUrl[urlKey];
    let paramKeys = Object.keys(params);
    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = params[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });
    let resultData;
    await this._http.get(serviceURL, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      })
      .catch((err) => {

      });
    return resultData;
  }

  // Get record by 'record id'
  async getUserPermissions(urlKey, params) {
    var httpHeaders = new HttpHeaders({ "authorization": this._masterService.getUserInfo("token") })
    var serviceURL = this.masterApiUrl[urlKey];
    let paramKeys = Object.keys(params);

    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = params[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });
    serviceURL += (serviceURL.indexOf('?') >= 0 ? '&' : '?') + `parSessionId=${this._masterService.getUserInfo("last_sessionid")}`

    let resultData;
    await this._http.get(serviceURL, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }

  async getDocPermissions(docInfo) {
    let modDocAccess = this._masterService.getUserPermissions();
    let docPermissions = _.filter(modDocAccess, { moduleId: docInfo.moduleId, subModuleId: docInfo.subModuleId, docId: docInfo.docId });
    return docPermissions[0];
  }

  // Insert / Update Global Remarks
  async insertData(rootKey, lookupKey, masterData: any) {
    var postData = JSON.stringify(masterData);
    let root = this.appConfig[rootKey];
    var serviceURL = this.apiHostUrl + root[lookupKey];
    let token: any = this._masterService.getUserInfo("token");
    var httpHeaders = new HttpHeaders({ 'contentType': 'application/json; charset=utf-8', "authorization": token });

    let resultData;
    await this._http.post(serviceURL, masterData, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }

  // Get Global Remarks
  async getData(rootKey, lookupKey, params) {
    let resultData = await this._masterService.getLookupData(rootKey, lookupKey, params);
    return resultData;
  }

  //get data with out token
  async getDataWithoutToken(rootKey, lookupKey, params) {
    let resultData = await this._masterService.getLookupDataWithoutToken(rootKey, lookupKey, params);
    return resultData;
  }

  //dat without token
  async insertUpdateMasterWithoutToken(urlKey, masterData: any) {
    var postData = JSON.stringify(masterData);
    var serviceURL = this.apiHostUrl + this.masterApiUrl[urlKey];
    var httpHeaders = new HttpHeaders(
      {
        'contentType': 'application/json; charset=utf-8'
      }
    );

    let resultData;
    await this._http.post(serviceURL, masterData, { headers: httpHeaders })
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }

  // Get record by 'record id'
  async getAppSettings(urlKey, params) {
    var serviceURL = this.masterApiUrl[urlKey];
    let paramKeys = Object.keys(params);

    _.forEach(paramKeys, (value, index) => {
      let templateColKey = '{' + value + '}';
      let templateColVal = params[value];
      serviceURL = serviceURL.replace(templateColKey, templateColVal);
    });

    let resultData;
    await this._http.get(serviceURL)
      .toPromise()
      .then((res) => {
        resultData = res;
      });
    return resultData;
  }
}