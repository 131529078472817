<style>
  .module-lists .popover-list .ui-scrollpanel-content {
    padding: 0 !important
  }
</style>
<div class="az-content az-content-dashboard-ten bg-white">
  <div class="module-content" style="margin:0 auto; width: 100% ;overflow: hidden;">
    <div class="module-lists">
      <p-scrollPanel [style]="{width:'100%', height:'calc(100vh - 64px)'}"
        styleClass="module-page demo-module-scroll container-scrollbar scrollbar-color">
        <ul class="module-ul set-padding pb-4">
          <li class="module-li module-list-completed close-on-click-outside position-relative"
            *ngFor="let userMenu of UserMenu" (click)="showMenu($event, userMenu, addNewSlot)">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="{{userMenu.imageUrl}} module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">{{userMenu.menuName}}</a>
              </div>
            </div>
          </li>

          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-user-shield module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">HR & Payroll</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-praying-hands module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Activity & Event</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-sticky-note module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Complaints</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="false">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-video module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Medical Ads</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-graduation-cap module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Convocation</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-torii-gate module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Asset & State</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-handshake module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Affiliation</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-users module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Training & Placement</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-cogs module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Materials Management</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-cog module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Value Added Services</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-copy module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Document Management</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-shuttle-van module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Vehicle Management</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-people-carry module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Content Share</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-award module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Accrediation Reports</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-search module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Phd(Research Work)</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-file-signature module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Online Entrance Exam</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-list-alt module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Recruitment Proccess</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-chart-line module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Career Development</a>
              </div>
            </div>
          </li>
          <li class="module-li" *ngIf="showAllModules">
            <div class="module-list-container">
              <div class="module-icon-list">
                <i class="fas fa-user-graduate module-icon"></i>
              </div>
              <div class="module-icon-title">
                <a href="javascript:return false">Reimbursement / Claim</a>
              </div>
            </div>
          </li>
        </ul>
      </p-scrollPanel>
    </div>
  </div>
</div>

<p-toast [style]="{marginTop:'50px'}" position="top-right" key="toast">
</p-toast>

<p-overlayPanel #addNewSlot appendTo="body">
  <div class="pop-menu-content">
    <p-scrollPanel>
      <ul class="popover-main-ul module-pop-menu">
        <li class="popover-main-li" *ngFor="let childMenu of selectedMenu.childMenus">
          <a href="javascript:return false" class="popover-main-link"
            (click)="onSubModuleClick(selectedMenu, childMenu);navigateToMenu(childMenu.pageUrl, childMenu.metaData)">
            <div class="popover-main-title">
              <i
                [ngClass]="childMenu.childMenus.length!=0?(childMenu.expanded?'far fa-folder-open mr-1':'far fa-folder-open mr-1'): 'fas fa-file mr-1'"></i>
              <span class="popover-main-text">{{childMenu.menuName}}</span>
              <i [ngClass]="childMenu.expanded?'fas fa-angle-up mr-2':'fas fa-angle-down mr-2'"
                *ngIf="childMenu.childMenus.length!=0"></i>
            </div>
          </a>
          <ul class="popover-ul">
            <li class="popover-li" *ngFor="let grandChildMenu of childMenu.childMenus"
              (click)='navigateToMenu(grandChildMenu.pageUrl, grandChildMenu.metaData)'>
              <a href="javascript:return false" class="popover-link" *ngIf="grandChildMenu.showMenu">
                <div class="popover-title">
                  <i class="fa fa-file popover-icon"></i>
                  <span class="popover-text">{{grandChildMenu.menuName}}</span>
                </div>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </p-scrollPanel>
  </div>
</p-overlayPanel>