<p-overlayPanel #opAdvSearch [appendTo]="'body'" [style]="{background : '#ececec',width:'88.5%'}" [autoZIndex]="true">
    <div class="my-3 position-relative">
        <div class="row mx-0">
            <div class="col-md-12">
                <div class="mb-2">
                    <div class="row">
                        <div class="col-md-3 px-0 mb-2" *ngFor="let selFCol of filterColInfo ">
                            <div class="col-auto mb-1"><span>{{selFCol.header}}</span></div>
                            <div class="d-flex align-items-center">
                                <div class="col-md-4 pr-1">
                                    <s-dropdown [options]="selFCol.operators" [filter]="false"
                                        [(inputModel)]="selFCol.operator" [appendTo]="'body'"
                                        (onChange)="retainAdvSearch($event, opAdvSearch)">
                                    </s-dropdown>
                                </div>
                                <div class="col-md-8 pl-1">
                                    <s-inputtext [cssClass]="'form-control p-1'" [(inputModel)]="selFCol.value"
                                        [keyFilter]="selFCol.keyFilter" *ngIf="selFCol.dataType != 'date'">
                                    </s-inputtext>
                                    <s-datepicker [cssClass]="'form-control p-1'" [(inputModel)]="selFCol.value"
                                        *ngIf="selFCol.dataType == 'date'" [appendTo]="'body'" [showIcon]="false">
                                    </s-datepicker>
                                </div>
                            </div>
                            <small class="text-danger font-weight-bold pl-3"> {{selFCol.filterqueired}}</small>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="text-center">
                        <small class="text-danger font-weight-bold"> {{filtermessage}}</small>
                    </div>
                    <div class="btn-bottm-position">
                        <a href="javascript:return false" class="text-capitalize btn btn-az-primary mr-2"
                            (click)="onQryBuilderSubmit(opAdvSearch,null,null,'adSearch');resetPage()"><i
                                class="fa fa-search"></i>&nbsp;Search</a>
                        <a href="javascript:return false" class="text-capitalize btn btn-az-clear"
                            (click)="onClearAdSearch(opAdvSearch)"><i class="fa fa-eraser"></i>&nbsp;Clear</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</p-overlayPanel>
<div class="row-no" *ngIf="showAdvanceSearch">
    <div class="text-center col-md-10">
        <h5 *ngIf="showLoadDataLabel" style="color: #ff0000;margin-right: 5px;">Please Use Advance Search to get
            Data</h5>
    </div>
    <div class="col-md-2 text-right">
        <label for="" class="mb-2">
            <a href="javascript:return false" class="text-capitalize btn btn-az-primary"
                (click)="showAdsearchPanel();opAdvSearch.toggle($event)"><i class="fa fa-search"></i>&nbsp;Advance
                Search</a>
        </label>
    </div>
</div>
<!-- <p-button type="text" (click)="opAdvSearch.show($event)" icon="fa fa-search"></p-button> -->
<div class="bx-1 table-responsive sm-dv-border sm-dv-table lockuptable lockupt-gride">

    <p-table #dt [columns]="lookupCols" [value]="lookupData" [paginator]="false" [rows]="25"
        [rowsPerPageOptions]="pageOptions" [responsive]="true" [resizableColumns]="true" [loading]="isLoading">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <!--Grid Titles-->
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn [ngStyle]="col.style">
                    {{col.header}}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order">
                    </p-sortIcon>
                </th>
            </tr>
            <tr>
                <!-- Grid Filters -->
                <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="col.style">
                    <input pInputText type="text" class="form-control p-1"
                        (input)="dt.filter($event.target.value, col.field, 'contains')" style='width:98%'>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <!--Grid Body-->
            <tr (click)="onRowSelect(rowData)" style="cursor: pointer;">
                <td *ngFor="let col of columns" class='ui-resizable-column'
                    [ngClass]="rowData.recordStatus=='D' ? 'inactive-row' : null">
                    <span class="ui-column-title">{{col.header}}</span>
                    <div [ngSwitch]="col.dataType">
                        <div *ngSwitchCase="'datetime'">{{formatDate(rowData[col.field], 'datetime')}}
                        </div>
                        <div *ngSwitchCase="'date'">{{formatDate(rowData[col.field], 'date')}}
                        </div>
                        <div *ngSwitchCase="'monthyear'">{{formatDate(rowData[col.field], 'monthyear')}}
                        </div>
                        <div *ngSwitchDefault [ngClass]="col.dataType=='number'?'grid-col-right':''">
                            {{rowData[col.field]}}</div>
                    </div>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="paginatorleft" let-data>
            <div style="margin-left:5px;margin-top:5px;">Showing {{data.first + 1}} to
                {{math.min((data.page+1) * data.rows, data.totalRecords)}} of
                {{data.totalRecords}} Records</div>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
            <!--Grid Footer-->
            <tr>
                <td [attr.colspan]="columns.length" style="text-align: center;">
                    <p class="mb-2">No records found.
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="paginator-content">
        <div class="paginator-text">{{dataInfo}}</div>
        <p-paginator #paginator [rows]="25" [totalRecords]="totalRecords" pageLinkSize="3" alwaysShow="false"
            [rowsPerPageOptions]="pageOptions" (onPageChange)="onGridPageChange($event)">
        </p-paginator>
    </div>
</div>
<style>
    :host ::ng-deep body .ui-dialog .ui-dialog-content {
        overflow: hidden !important;
    }

    .pop-dia-scroll {
        height: calc(25vh - 100px);
        overflow-y: auto !important;
        overflow-x: hidden;
    }

    .btn-search {
        position: absolute;
        top: 29px;
        right: 10px;
    }

    .btn-position {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .btn-bottm-position {
        position: absolute;
        bottom: 0px;
        right: 15px;
    }

    .table-responsive {
        overflow-x: visible;
    }

    :host ::ng-deep .ui-inputtext {
        min-height: 35px !important;
    }

    /* :host ::ng-deep .lockupgrid .ui-table-wrapper {
        height: calc(75vh - 160px);
        overflow-y: auto;
    } */
</style>