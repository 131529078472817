<div class="datepicker-directive">
  <p-calendar [autoFocus]="autoFocus" [formControl]="ngControl.control" inputStyleClass="{{cssClass}}"
    id="{{controlId}}" [style]="{'width':'100%'}"
    [inputStyle]="{'width':'85%', 'background-color':'#ffffff', 'border-color':(isMandatory && markMandatory?'#ffa6a6':'')}"
    [monthNavigator]="true" [yearNavigator]="true" [readonlyInput]="false" dateFormat="{{dateFormat}}"
    hourFormat="{{hourFormat}}" selectionMode="{{selectionMode}}" yearRange="{{yearRange}}"
    [showButtonBar]="showButtonBar" [minDate]="minDate" [maxDate]="maxDate" [showTime]="showTime" [timeOnly]="timeOnly"
    [numberOfMonths]="numberOfMonths" view="{{dtpView}}" (onBlur)="onLeaveControl($event)" (onFocus)="onFocusControl()"
    (onSelect)="onSelectControl($event)" dataType="{{dataType}}" [showIcon]="showIcon" *ngIf="this.ngControl!=null"
    [disabled]="disabled" [showWeek]="showWeek" placeholder="{{placeHolder}}" [appendTo]="appendTo"
    (onClearClick)="onClears($event)" [baseZIndex]="99999" [selectOtherMonths]="selectOtherMonths">
  </p-calendar>
  <p-calendar [autoFocus]="autoFocus" inputStyleClass="{{cssClass}}" id="{{controlId}}" [style]="{'width':'100%'}"
    [inputStyle]="{'width':'85%', 'background-color':'#ffffff', 'border-color':(isMandatory && markMandatory?'#ffa6a6':'')}"
    [monthNavigator]="true" [yearNavigator]="true" [readonlyInput]="false" dateFormat="{{dateFormat}}"
    hourFormat="{{hourFormat}}" selectionMode="{{selectionMode}}" yearRange="{{yearRange}}"
    [showButtonBar]="showButtonBar" [minDate]="minDate" [maxDate]="maxDate" [showTime]="showTime" [timeOnly]="timeOnly"
    [numberOfMonths]="numberOfMonths" view="{{dtpView}}" (onBlur)="onLeaveControl($event)" (onFocus)="onFocusControl()"
    (onSelect)="onSelectControl($event)" dataType="{{dataType}}" [showIcon]="showIcon" *ngIf="this.ngControl==null"
    [(ngModel)]="inputModel" (ngModelChange)="inputModelChange.emit(inputModel)" [disabled]="disabledMe"
    (onClearClick)="onClears($event)" [showWeek]="showWeek" placeholder="{{placeHolder}}" [appendTo]="appendTo"
    [baseZIndex]="99999" [selectOtherMonths]="selectOtherMonths">
  </p-calendar>

  <i class="pi pi-exclamation-circle error-icon-dtp" tooltipStyleClass="tooltip-error" pTooltip={{errorMessage}}
    *ngIf="errorMessage!=''"></i>
</div>