import { Component, Input, Self, Output, EventEmitter, Optional, OnInit } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 's-datepicker',
  templateUrl: './datepicker.component.html'
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {

  private today = new Date();
  private defYearRange = "1900:" + (this.today.getFullYear() + 30);

  @Input() showButtonBar: boolean = true;
  @Input() minDate: object = null;
  @Input() maxDate: object = null;
  @Input() readonlyInput: boolean = true;
  @Input() showTime: boolean = false;
  @Input() timeOnly: boolean = false;
  @Input() selectionMode: string = "single"; // single / multiple / range
  @Input() numberOfMonths: number = 1;
  @Input() dtpView: string = "date"; // date / week / month
  @Input() dateFormat: string = "dd-M-yy";
  @Input() hourFormat: string = "24"; // 24 / 12
  @Input() yearRange: string = this.defYearRange;
  @Input() dataType: string = "date";
  @Input() inputModel: any = null;
  @Input() controlId: string = "";
  @Input() errorMessage: string = "";
  @Input() cssClass: string = "";
  @Input() autoFocus: boolean = false;
  @Input() showWeek: boolean = false;
  @Input() placeHolder: string = "dd-mmm-yyyy";
  @Input() isCustomDates = false;
  @Input() isMandatory: boolean = false;
  @Input() appendTo = "body";
  @Input() showIcon = true;
  public selectOtherMonths = false;
  public isWeekCalendar = false;

  public markMandatory: boolean = false;
  public disabledMe: boolean = false;
  @Input() set disabled(condition: boolean) {
    if (this.ngControl != null) {
      const action = condition ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
    else {
      this.disabledMe = condition;
    }
  };
  @Output() public onBlur = new EventEmitter();
  @Output() public onFocus = new EventEmitter();
  @Output() public onSelect = new EventEmitter();

  @Output() inputModelChange = new EventEmitter<string>();
  @Output() public onClear = new EventEmitter();
  constructor(
    @Optional()
    @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    //this.cssClass += (this.isMandatory ? " mandatory-input" : "");
    //this.inputModel = [new Date("2022-11-01"), new Date("2022-11-10")];
    if (this.dtpView == "week") {
      this.selectOtherMonths = true;
      this.selectionMode = "range";
      this.dtpView = "date";
      this.isWeekCalendar = true;
    }
  }

  writeValue(obj: any) { }
  registerOnChange(fn: any) { };
  registerOnTouched(fn: any) { };
  setDisableState?(isDisabled: boolean) { }

  onLeaveControl(event) {
    if (this.ngControl != null) {
      this.markMandatory = (this.isMandatory && (this.ngControl.control.value == null || this.ngControl.control.value == ""));
    }
    else {
      this.markMandatory = (this.isMandatory && (this.inputModel == null || this.inputModel == ""));
    }
    this.onBlur.emit(event);
  }

  onFocusControl() {
    this.onFocus.emit(true);
  }

  sleep(milliSeconds) {
    return new Promise(resolve => setTimeout(resolve, milliSeconds));
  }

  async onSelectControl(event) {
    if (this.ngControl != null) {
      //   this.markMandatory = (this.isMandatory && (this.ngControl.control.value == null || this.ngControl.control.value == ""));
      // }
      // else {
      //   this.markMandatory = (this.isMandatory && (this.inputModel == null || this.inputModel == ""));
    }

    await this.sleep(10);

    if (this.isWeekCalendar) {
      let start = new Date(event);
      start.setDate(start.getDate() - start.getDay());
      this.inputModel = [];
      this.inputModel.push(start);

      let end = new Date(start);
      end.setDate(start.getDate() + 6);
      this.inputModel.push(end);
      this.onSelect.emit(this.inputModel);
    }
    else {
      this.onSelect.emit(event);
    }
  }

  getThisDate() {
    return this.inputModel;
  }

  onClears(event) {
    this.onClear.emit(event);
  }
}