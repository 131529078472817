import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoginService } from '../services/login.service';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MessageService } from 'primeng/components/common/messageservice';
import { MastersService } from '../services/masters.service';
import * as _ from 'lodash';
import { GlobalData } from '../globaldata';
import { CaptchaComponent } from '../directives/captcha.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  @ViewChild(CaptchaComponent, null) captcha: CaptchaComponent;

  public formSubmitted = false;
  public UserID: string = "";
  public Password: string = "";
  public loginErrMsg = {};
  public thisPageTitle = "Login";
  public pageMode = "NEW";
  public displayModal: boolean;
  public locList = [{ label: "Select One", value: {} }];
  public selectedLocation: any = {};
  public clientIP = localStorage.getItem("clientIP");
  public appInstance = "";
  public clientName = "";
  public clientShortName = "";
  public clientLogo = "";
  public showFooter = true;
  public showForgotPwd = true;

  public newShowEye = 'fa fa-eye eyes-icon';
  public ctrlPass = 'on';
  public newIsInputText = 'password';
  public resetPwdInfo = { msg: '', code: '' };

  public errorMessages: any = {
    "userNameError": "",
    "userPasswordError": "",
    "resetuserNameError": "",
    "captchaError": "",
    "changepassword": "",
    "changeNewPassword": "",
    "changeConformPassword": ""
  };

  public userForm = this._formBuilder.group({
    parUserName: ['', Validators.required],
    parPassword: ['', Validators.required],
    parRecordStatus: [true],
  });

  get thisForm() { return this.userForm.controls; }
  public resetForm = {
    parUserName: '',
    parCaptcha: ""
  };
  public isChceckNewPwd = false;
  public changePwd: boolean;
  public oldIsInputText = 'password';
  public confirmIsInputText = 'password';
  public loginchangeErrMsg = {};
  public pwdStngPlcs: any;
  public minLength;
  public spclChrs;
  public uppCs;
  public lwrCs;
  public minNm;
  public minLengthFlag = false;
  public spclChrsFlag = false;
  public uppCsFlag = false;
  public lwrCsFlag = false;
  public minNmFlag = false;
  public validNewPwd = false;
  public isShowSbmit = false;
  public pwdExpDays = 0;
  public pwdClosable = true;

  public changePwdForm = this._formBuilder.group({
    parUserId: [],
    parOldPassword: ['', Validators.required],
    parNewPassword: ['', Validators.required],
    parNewConfPassword: ['', Validators.required]
  });
  get thisChangePwdForm() { return this.changePwdForm.controls; }
  public toastMessage = "";
  public showReq = false;
  public saveCode;
  public blockUI = false;
  public displayResetPwd = false;
  public userLogInfo;
  public enableMobileApp = false;
  public androidURL = "";
  public iosURL = "";
  public displayMobileApp = false;

  getErrormessage(ctrl, showToast) {
    switch (ctrl) {
      case "parUserName":
        this.errorMessages.userNameError = this.validateThisctrl(ctrl, "UserNamereq", showToast);
        break;
      case "parPassword":
        this.errorMessages.userPasswordError = this.validateThisctrl(ctrl, "Passwordreq", showToast);
        break;
      case "reSetparUserName":
        this.errorMessages.resetuserNameError = this.resetForm.parUserName == "" || this.resetForm.parUserName == null || this.resetForm.parUserName == undefined ? "Please Enter User name" : "";
        break;
      case "parCaptcha":
        this.errorMessages.captchaError = this.resetForm.parCaptcha == "" || this.resetForm.parCaptcha == null || this.resetForm.parCaptcha == undefined ? "Please Enter Captcha" : "";
        if (this.errorMessages.captchaError == "") {
          this.errorMessages.captchaError = (this.resetForm.parCaptcha != this._global.capCode.replace(/ /g, '') ? "Invalid Captcha" : "");
        }
        break;
    }
  }

  validateThisctrl(ctrl: string, errorUserAttr, showToast: boolean) {
    if (this.thisForm[ctrl].value.constructor === String)
      this.thisForm[ctrl].setValue(this.thisForm[ctrl].value.trim());

    let errorMessage = ((this.formSubmitted || this.thisForm[ctrl].touched) && this.thisForm[ctrl].errors ? this.loginErrMsg[errorUserAttr] : "");
    return errorMessage;
  }

  getPwdChangeErrormessage(ctrl, showToast) {
    switch (ctrl) {
      case "parOldPassword":
        this.errorMessages.changepassword = this.validatePwdChangeThisctrl(ctrl, "changepasswordreq", showToast);
        break;
      case "parNewPassword":
        this.errorMessages.changeNewPassword = this.validatePwdChangeThisctrl(ctrl, "changeNewPasswordreq", showToast);
        break;
      case "parNewConfPassword":
        this.errorMessages.changeConformPassword = this.validatePwdChangeThisctrl(ctrl, "changeConformPasswordreq", showToast);
        break;
    }
  }

  validatePwdChangeThisctrl(ctrl: string, errorUserAttr, showToast: boolean) {
    let errorMessage = ((this.formSubmitted || this.thisChangePwdForm[ctrl].touched) && this.thisChangePwdForm[ctrl].errors ? this.loginchangeErrMsg[errorUserAttr] : "");

    if (ctrl == "parNewPassword" && errorMessage == "") {
      errorMessage = (this.validNewPwd ? "" : "Password is not as per the policy settings");
      errorMessage = (this.thisChangePwdForm[ctrl].value != this.thisChangePwdForm["parOldPassword"].value) ? '' : "Old Password & New Password should be Different "
    }
    if (ctrl == "parNewConfPassword" && errorMessage == "") {
      if (this.thisChangePwdForm[ctrl].value != '') {
        if (this.thisChangePwdForm[ctrl].value == this.thisChangePwdForm["parNewPassword"].value) {
          errorMessage = "";
          this.isShowSbmit = true;
        } else {
          errorMessage = "Confirm Password not matching with New Password";
          this.isShowSbmit = false;
        }
      } else {
        errorMessage = this.loginchangeErrMsg["changeConformPasswordreq"];
        this.isShowSbmit = true;
      }
    }
    if (showToast && errorMessage != '') {
      this.messageService.add({ severity: 'warn', summary: 'Login', detail: errorMessage });
    }
    return errorMessage
  }

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _loginService: LoginService,
    private _mastersService: MastersService,
    private messageService: MessageService,
    private _activatedRoute: ActivatedRoute,
    private _global: GlobalData
  ) { }

  async initLoginPage() {
    if (Object.keys(this._mastersService.appConfig).length == 0) {
      await this._mastersService.getConfigData();
    }
    this.loginErrMsg = this._mastersService.getErrorMessages("userloginErrMsg");

    await this._loginService.getConfigData();
    await this._loginService.setMasterApiUrl("loginApi");

    let appConfigData = await this._loginService.getAppSettings("getAppConfigData", {});
    window.localStorage.setItem("appConfigData", appConfigData.info);

    this.appInstance = this._mastersService.getAppInstance();
    this.clientName = this._mastersService.getClientFullName();
    this.clientShortName = this._mastersService.getClientShortName();
    this.clientLogo = this._mastersService.getClientLogo();
    this.showFooter = this._mastersService.showLoginFooter();
    this.showForgotPwd = this._mastersService.showForgotPassword();

    this.enableMobileApp = this._mastersService.enableMobileApp();
    this.androidURL = this._mastersService.getAndroidURL();
    this.iosURL = this._mastersService.getIosURL();

    window.localStorage.removeItem("modDocAccess");
    window.localStorage.removeItem("sessionID");
    window.localStorage.removeItem("UserInfo");
    window.localStorage.removeItem("UserLoc");
    window.localStorage.removeItem("wc");
  }

  async ngOnInit() {
    this._activatedRoute.paramMap.subscribe(async (param: ParamMap) => {
      let params = param.get('param');

      if (params == null || params == undefined) {
        if (window.localStorage.getItem("modDocAccess") == null || window.localStorage.getItem("modDocAccess") == undefined) {
          await this.initLoginPage();
        }
        else {
          if ((window.localStorage.getItem("wc") || "") == "Y") {
            await this.initLoginPage();
          }
          else {
            window.localStorage.setItem("GoHome", "Y");

            let thisUseTypeId = this._mastersService.getUserInfo('user_type_id');
            switch (thisUseTypeId) {
              case 1: window.location.href = '/home'; break;
              case 7: window.location.href = '/home'; break;
              default: window.location.href = '/shome'; break;
            }
          }
        }
      }
      else {
        await this.initLoginPage();
      }
    });

    setTimeout(() => {
      setInterval(() => {
        if (window.localStorage.getItem("modDocAccess") != null && window.localStorage.getItem("modDocAccess") != undefined) {
          window.localStorage.setItem("GoHome", "Y");

          let thisUseTypeId = this._mastersService.getUserInfo('user_type_id');
          switch (thisUseTypeId) {
            case 1: window.location.href = '/home'; break;
            case 7: window.location.href = '/home'; break;
            default: window.location.href = '/shome'; break;
          }
        }
      }, 1000);
    }, 5000);
  }

  async onLoginClick() {
    this.errorMessages = {
      "userNameError": "",
      "userPasswordError": "",
      "resetuserNameError": "",
      "captchaError": "",
      "changepassword": "",
      "changeNewPassword": "",
      "changeConformPassword": ""
    };
    this.formSubmitted = true;
    this.selectedLocation = {};

    let formFields = Object.keys(this.userForm.controls)
    _.forEach(formFields, (value, index) => {
      this.getErrormessage(value, true);
    })

    if (this.userForm.invalid) { return; }

    let userInfo = this.userForm.controls;
    let params = { parUserName: btoa(userInfo.parUserName.value), parPassword: btoa(userInfo.parPassword.value), parTimezoneId: 0 };
    let preLoginInfo = await this._loginService.preLoginCheck("preLoginUrl", params);
    this.userLogInfo = preLoginInfo

    if (preLoginInfo == undefined || preLoginInfo == null) {
      this.messageService.add({ severity: 'error', summary: 'Login', detail: 'Unable process your request, please try again' });
    }
    else if (preLoginInfo.info.length > 0) {
      let userLocations = this._mastersService.getDecryptData(preLoginInfo.info);

      if (userLocations[0]["Status"] == 401) {
        this.messageService.add({ severity: 'error', summary: 'Login', detail: 'Invalid User ID / Password' });
      }
      else {
        window.localStorage.setItem("UserLoc", preLoginInfo.info);
        this.pwdExpDays = parseFloat(userLocations[0]["pwd_exprd_in_days"] || 1);
        if (this.pwdExpDays <= 0) {
          this.changePwd = true;
          this.pwdClosable = false;
          await this.onChangepwd();
        }
        else {
          this.locList = [{ label: "Select One", value: {} }];
          _.forEach(userLocations, (uLoc, uIndex) => {
            this.locList.push({
              label: `${uLoc.grp_name} - ${uLoc.location_name}`,
              value: { orgId: uLoc.org_id, grpId: uLoc.grp_id, locId: uLoc.location_id, roleId: uLoc.role_id }
            });
          });

          if (userLocations.length == 1) {
            this.selectedLocation["roleId"] = userLocations[0].role_id;
            this.selectedLocation["orgId"] = userLocations[0].org_id;
            this.selectedLocation["grpId"] = userLocations[0].grp_id;
            this.selectedLocation["locId"] = userLocations[0].location_id;
            await this.loginToLocation();
          }
          else {
            this.displayModal = true;
          }
        }
      }
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Login', detail: 'Invalid User ID / Password' });
    }
  }

  async loginToLocation() {
    if (Object.keys(this.selectedLocation).length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Login', detail: 'Please select a location to login' });
      return;
    }

    let userInfo = this.userForm.controls;
    let userCred = {
      UserName: btoa(userInfo.parUserName.value),
      Password: btoa(userInfo.parPassword.value),
      parRoleId: this.selectedLocation["roleId"],
      parOrgId: this.selectedLocation["orgId"],
      parGrpId: this.selectedLocation["grpId"],
      parLocId: this.selectedLocation["locId"]
    };
    let loginResponse = await this._loginService.validateUser("validateLoginUrl", userCred);

    if (loginResponse == undefined || loginResponse == null) {
      this.messageService.add({ severity: 'error', summary: 'Login', detail: 'Unable process your request, please try again' });
      window.localStorage.setItem("UserInfo", null);
      window.localStorage.removeItem("UserInfo");
    }
    else if (loginResponse.info.length > 0) {
      window.localStorage.setItem("UserInfo", loginResponse.info);
      if (this._mastersService.getUserInfo("success") == 200) {
        window.localStorage.setItem("GoHome", "Y");

        let thisUseTypeId = this._mastersService.getUserInfo('user_type_id');
        switch (thisUseTypeId) {
          case 1: window.location.href = '/home'; break;
          case 7: window.location.href = '/home'; break;
          default: window.location.href = '/shome'; break;
        }
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Login', detail: 'Invalid User ID / Password' });
        window.localStorage.setItem("UserInfo", null);
        window.localStorage.removeItem("UserInfo");
      }
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Login', detail: 'Invalid User ID / Password' });
      window.localStorage.setItem("UserInfo", null);
      window.localStorage.removeItem("UserInfo");
    }
    this.formSubmitted = false;
  }

  async onForgetpwd() {
    this.displayResetPwd = true;
    this.resetForm = {
      parUserName: '',
      parCaptcha: ""
    };
  };

  onClickSeePassword(ctrl, toggles) {
    switch (ctrl) {
      case "password":
        if (this.ctrlPass == 'on') {
          this.newShowEye = 'fa fa-eye-slash eyes-icon';
          this.newIsInputText = 'text';
          this.ctrlPass = 'off';
        } else if (this.ctrlPass == 'off') {
          this.newShowEye = 'fa fa-eye eyes-icon';
          this.newIsInputText = 'password';
          this.ctrlPass = 'on';
        }
        break;
    };
  };

  ValidateCaptcha(control: AbstractControl) {
    let formCaptcha = control.value;
    let isValid = null;

    return new Promise(async (resolve, reject) => {
      if (formCaptcha == '')
        resolve(null);
      else {
        var isValid = formCaptcha != this._global.capCode.replace(/ /g, '') ? { 'validcaptcha': true } : null
        reject(isValid);
      }
    })
  }

  onCapthaRefresh() {
    this.resetForm.parCaptcha = "";
  }

  async onResetPwdClick() {
    this.formSubmitted = true;
    this.selectedLocation = {};
    this.resetPwdInfo = { msg: '', code: '' };
    let formFields = ['reSetparUserName', 'parCaptcha']
    _.forEach(formFields, (value, index) => {
      this.getErrormessage(value, true);
    });

    let isErrorExists = this._mastersService.showErrorSummary(this.errorMessages);

    if (isErrorExists) return;
    let resetPwdInfo = await this._loginService.resetPassword("getUserForgotPasswordUrl", { parUserName: this.resetForm.parUserName });
    this.resetPwdInfo.code = resetPwdInfo[0].errorCd
    if (resetPwdInfo[0].errorCd == 0) this.resetPwdInfo.msg = `Your Password has been reset and the new Password has been sent to your email Id ${resetPwdInfo[0].emailId}`
    else if (resetPwdInfo[0].errorCd == 204) this.resetPwdInfo.msg = "User Name does not exists Please Enter Valid User Name";
    else if (resetPwdInfo[0].errorCd == 205) this.resetPwdInfo.msg = "Email/Sms Configuration settings not Found for Sending New password ";
    else this.resetPwdInfo.msg = "Unable to reset Password Please try again "
    this.resetForm = {
      parUserName: '',
      parCaptcha: ""
    };
  }

  async resetCaptcha() {
    await this.captcha.createCaptcha();
  }

  async onChangepwd() {
    this.loginchangeErrMsg = this._mastersService.getErrorMessages("changePasswordErrMsg");
    // LOC FOR FETCHING THE OBJECT FROM PASSWORD SETTING POLICY.
    this.pwdStngPlcs = await this._loginService.getDataWithoutToken("passwordPolicySettingsApi", "getAllPasswordUrL", { "parFlag": "G", "sessionReq": "N" });
    this.minLength = this.pwdStngPlcs[0]["passwPolicyMinLen"];
    this.spclChrs = this.pwdStngPlcs[0]["passwPolicySpecialCharLen"];
    this.uppCs = this.pwdStngPlcs[0]["passwPolicyMinCapitalLettersLen"];
    this.lwrCs = this.pwdStngPlcs[0]["passwPolicyMinSmallLettersLen"];
    this.minNm = this.pwdStngPlcs[0]["passwPolicyDigitsMinLen"];

    this.minLengthFlag = false;
    this.spclChrsFlag = false;
    this.uppCsFlag = false;
    this.lwrCsFlag = false;
    this.minNmFlag = false;
    this.validNewPwd = false;

    this.changePwdForm.reset();
    this.errorMessages = {
      "changepassword": "",
      "changeNewPassword": "",
      "changeConformPassword": ""
    };

    this.changePwd = true;
    this.isChceckNewPwd = false;
  }

  async onChangePwdClick() {
    this.formSubmitted = true
    let pssdFields = Object.keys(this.changePwdForm.controls);
    _.forEach(pssdFields, (pwdVal, pwdIn) => {
      this.getPwdChangeErrormessage(pwdVal, false);
    });
    let isErrorExists = this._mastersService.showErrorSummary(this.errorMessages);
    if (isErrorExists) { return; }
    let userDet = this._mastersService.getDecryptData(this.userLogInfo.info);
    let thisFormData = JSON.parse(JSON.stringify(this.changePwdForm.getRawValue()));
    thisFormData.parUserId = userDet[0].user_id;
    delete thisFormData.parNewConfPassword;

    this.blockUI = true;
    await this._mastersService.setMasterApiUrl("loginApi");
    var saveResult = await this._loginService.insertUpdateMasterWithoutToken("userCahngePwdUrl", thisFormData);
    this.showReq = true;
    this.saveCode = saveResult[0].errorCd;

    if (saveResult.length > 0) {
      if (saveResult[0].errorCd == "0") {
        this.toastMessage = "Password changed successfully";
        setTimeout(async () => {
          //await this.onLogoutClick();
        }, 1500);
      }
      if (saveResult[0].errorCd == "0000") {
        this.toastMessage = "Old password not matching, please check again";
      }
    }
    else {
      this.toastMessage = "Unable to update password";
    }
    await this.onChangepwd();

    this.blockUI = false;
    this.changePwdForm.reset();
    await this.resetCaptcha()
  };

  validateSettings(event) {
    let newPwd = this.changePwdForm.getRawValue()["parNewPassword"];

    let numUCase = (newPwd.match(/[A-Z]/g) || []).length;
    let numLCase = (newPwd.match(/[a-z]/g) || []).length;
    let numNumbers = (newPwd.match(/[0-9]/g) || []).length;
    let numSpclChar = (newPwd.match(/[@#$%^&*()_{}\[\]><.?]/g) || []).length;

    this.minLengthFlag = (newPwd.length >= this.minLength);
    this.uppCsFlag = (numUCase >= this.uppCs);
    this.lwrCsFlag = (numLCase >= this.lwrCs);
    this.minNmFlag = (numNumbers >= this.minNm);
    this.spclChrsFlag = (numSpclChar >= this.spclChrs);
    this.validNewPwd = (this.minLengthFlag && this.uppCsFlag && this.lwrCsFlag && this.minNmFlag && this.spclChrsFlag);
    this.isChceckNewPwd = (this.validNewPwd ? false : true);
  };

  showMobileApp() {
    this.displayMobileApp = true;
  }
}