import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GlobalData } from '../globaldata';

@Component({
  selector: 's-captcha',
  templateUrl: './captcha.component.html'
})
export class CaptchaComponent implements OnInit {
  public capCode: string = "";
  @Output() public onRefresh = new EventEmitter();

  constructor(private _global: GlobalData) { }

  ngOnInit() {
    this.createCaptcha();
  }

  createCaptcha() {
    var alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
      'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
      '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
      '!', '@', '#', '$', '%', '&');
    var a: string = "", b: string = "", c: string = "", d: string = "", e: string = "", f: string = "";
    a = alpha[Math.floor(Math.random() * alpha.length)];
    b = alpha[Math.floor(Math.random() * alpha.length)];
    c = alpha[Math.floor(Math.random() * alpha.length)];
    d = alpha[Math.floor(Math.random() * alpha.length)];
    e = alpha[Math.floor(Math.random() * alpha.length)];
    f = alpha[Math.floor(Math.random() * alpha.length)];
    this.capCode = `${a} ${b} ${c} ${d} ${e} ${f}`;

    let canCapCode = document.getElementById('capCode') as HTMLCanvasElement;
    let ctx = canCapCode.getContext('2d');
    let x = canCapCode.width / 2;

    ctx.fillStyle = '#007bff';
    ctx.fillRect(0, 0, canCapCode.width, canCapCode.height);
    ctx.font = "20px Roboto Slab";
    ctx.fillStyle = '#fff';
    ctx.textAlign = 'center';
    ctx.setTransform(1, -0.12, 0, 1, 0, 15);
    ctx.fillText(this.capCode, x, 15);

    this._global.capCode = this.capCode;

    this.onRefresh.emit(true);
  }
}