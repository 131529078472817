<p-dropdown [autoFocus]="autoFocus" [formControl]="ngControl.control"
  [style]="{'width':'100%', 'background-color':'#ffffff'}" id="classroomName" [options]="options" [filter]="filter"
  (onBlur)="onLeaveControl()" (onChange)="onChangeValue($event)" *ngIf="this.ngControl!=null" [appendTo]="appendTo"
  [ngClass]="{'mondatory_border':isMandatory && markMandatory}">
</p-dropdown>
<p-dropdown [autoFocus]="autoFocus" [(ngModel)]="inputModel" (ngModelChange)="inputModelChange.emit(inputModel)"
  [style]="{'width':'100%', 'background-color':'#ffffff'}" id="classroomName" [options]="options" [filter]="filter"
  (onBlur)="onLeaveControl()" [ngClass]="{'mondatory_border':isMandatory && markMandatory}"
  (onChange)="onChangeValue($event)" *ngIf="this.ngControl==null" [disabled]="disabledMe" [appendTo]="appendTo">
</p-dropdown>

<i class="pi pi-exclamation-circle {{iconClass}}" tooltipStyleClass="tooltip-error" pTooltip={{errorMessage}}
  *ngIf="errorMessage!=''"></i>