import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import "src/assets/js/sidebar.js";
import { LoginService } from '../services/login.service';
import { MastersService } from '../services/masters.service';
import * as _ from 'lodash';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { createDecipher } from 'crypto';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  public UserInfo: any = {};
  public IsUserLoggedIn = false;
  public UserMenu: any = [];
  public isMenuShow = [];
  public userName = "";
  public menuList = [];
  public locList = [];
  public loginLocation = "";
  public clientIP = localStorage.getItem("clientIP");
  public appInstance = "";
  public showAllModules = false;
  public changePwd: boolean;
  public clientName = "EduKares";
  public clientLogo = "default_logo.png";
  public showIdleTime = false;
  public loginExpTime = "";
  public isChceckNewPwd = false;
  public oldIsInputText = 'password';
  public newIsInputText = 'password';
  public confirmIsInputText = 'password';
  public oldShowEye = 'fa fa-eye eye-icon';
  public newShowEye = 'fa fa-eye eye-icon';
  public confirmShowEye = 'fa fa-eye eye-icon';
  public menuLabel = "";

  public ctrlPass = 'on';
  public errorMessages: any = {
    "changepassword": "",
    "changeNewPassword": "",
    "changeConformPassword": ""
  };
  public blockUI = false;
  public formSubmitted = false;
  public loginchangeErrMsg = {};
  public pwdStngPlcs: any;
  public title = "Change Password"
  public toastMessage = "";
  public showReq = false;
  public saveCode;
  public minLength;
  public spclChrs;
  public uppCs;
  public lwrCs;
  public minNm;
  public minLengthFlag = false;
  public spclChrsFlag = false;
  public uppCsFlag = false;
  public lwrCsFlag = false;
  public minNmFlag = false;
  public validNewPwd = false;
  public isShowSbmit = false;
  public pwdExpDays = 0;
  public pwdClosable = true;
  public changePwdForm = this._fb.group({
    parUserId: [],
    parOldPassword: ['', Validators.required],
    parNewPassword: ['', Validators.required],
    parNewConfPassword: ['', Validators.required]
  });

  get thisForm() {
    return this.changePwdForm.controls;
  }
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _loginService: LoginService,
    private _masterService: MastersService,
    private messageService: MessageService,
    private _fb: FormBuilder,
    private idleTime: Idle) { }

  @HostListener('document:keyup', ['$event'])
  async onkeyup(event: KeyboardEvent) {
    if (event.keyCode == 44) {
      var aux = await document.createElement("input");
      await aux.setAttribute('value', '.');
      await document.body.appendChild(aux);
      await aux.select();
      await document.execCommand('copy');
      await document.body.removeChild(aux);
    }
  }

  @HostListener('window:focus', ['$event'])
  onfocus(event: any) {
    let element: HTMLElement = document.getElementsByTagName('div')[0] as HTMLElement;
    element.click();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    window.localStorage.setItem("wc", "Y");
  }

  getErrormessage(ctrl, showToast) {
    switch (ctrl) {
      case "parOldPassword":
        this.errorMessages.changepassword = this.validateThisctrl(ctrl, "changepasswordreq", showToast);
        break;
      case "parNewPassword":
        this.errorMessages.changeNewPassword = this.validateThisctrl(ctrl, "changeNewPasswordreq", showToast);
        break;
      case "parNewConfPassword":
        this.errorMessages.changeConformPassword = this.validateThisctrl(ctrl, "changeConformPasswordreq", showToast);
        break;
    }
  }

  validateThisctrl(ctrl: string, errorUserAttr, showToast: boolean) {
    let errorMessage = ((this.formSubmitted || this.thisForm[ctrl].touched) && this.thisForm[ctrl].errors ? this.loginchangeErrMsg[errorUserAttr] : "");

    if (ctrl == "parNewPassword" && errorMessage == "") {
      errorMessage = (this.validNewPwd ? "" : "Password is not as per the policy settings");
      errorMessage = (this.thisForm[ctrl].value != this.thisForm["parOldPassword"].value) ? '' : "Old Password & New Password should be Different "
    }
    if (ctrl == "parNewConfPassword" && errorMessage == "") {
      if (this.thisForm[ctrl].value != '') {
        if (this.thisForm[ctrl].value == this.thisForm["parNewPassword"].value) {
          errorMessage = "";
          this.isShowSbmit = true;
        } else {
          errorMessage = "Confirm Password not matching with New Password";
          this.isShowSbmit = false;
        }
      } else {
        errorMessage = this.loginchangeErrMsg["changeConformPasswordreq"];
        this.isShowSbmit = true;
      }
    }
    if (showToast && errorMessage != '') {
      this.messageService.add({ severity: 'warn', summary: 'Login', detail: errorMessage });
    }
    return errorMessage
  }

  idleTimeReset() {
    this.showIdleTime = false;
    this.idleTime.watch();
  }

  async ngOnInit() {
    await this._loginService.getConfigData();
    await this._loginService.setMasterApiUrl("loginApi");

    this.loginLocation = this._masterService.getUserInfo("location_name");
    this.userName = this._masterService.getUserInfo("display_name").toString();
    this.pwdExpDays = parseFloat(this._masterService.getUserInfo("pwd_exprd_in_days") || 1);
    this.changePwd = (this.pwdExpDays <= 0 ? true : false);
    if (this.pwdExpDays <= 0) await this.onChangepwd();
    this.pwdClosable = (this.pwdExpDays <= 0 ? false : true);

    let appConfigData = await this._loginService.getAppSettings("getAppConfigData", {});
    window.localStorage.setItem("appConfigData", appConfigData.info);

    this.appInstance = this._masterService.getAppInstance();
    this.showAllModules = this._masterService.showAllModules();
    this.clientName = this._masterService.getClientFullName();
    this.clientLogo = this._masterService.getClientLogo();

    if (Object.keys(this._masterService.appConfig).length == 0) {
      await this._masterService.getConfigData();
    }
  let refreshtme = await this._masterService.SystemNotificationRefreshTime() *1000 ;
  this.getSysNotifications();
    setInterval(() => {
      this.getSysNotifications();
    }, (refreshtme) || 300000);

    let curOrg = this._masterService.getUserInfo("org_id");
    let curGrp = this._masterService.getUserInfo("grp_id");
    let curLoc = this._masterService.getUserInfo("location_id");

    this.locList = [{ label: "Select One", value: {} }];
    let userLocations = this._masterService.getDecryptData(window.localStorage.getItem("UserLoc"));
    _.forEach(userLocations, (uLoc, uIndex) => {
      if (uLoc.org_id != curOrg || uLoc.grp_id != curGrp || uLoc.location_id != curLoc) {
        this.locList.push({
          label: `${uLoc.grp_name} - ${uLoc.location_name}`,
          value: { orgId: uLoc.org_id, grpId: uLoc.grp_id, locId: uLoc.location_id, roleId: uLoc.role_id }
        });
      }
    });

    this.loginLocation = this._masterService.getUserInfo("location_name");
    this.userName = this._masterService.getUserInfo("display_name").toString();

    let params = {
      parUserId: this._masterService.getUserInfo("user_id"),
      parRoleId: this._masterService.getUserInfo("role_id")
    };
    let userPermissions = await this._loginService.getUserPermissions("userPermissionsUrl", params)
    window.localStorage.setItem("modDocAccess", userPermissions.info);
    this.prepareUserMenu();

    this.IsUserLoggedIn = (window.localStorage.getItem("IsLoggedIn") == null || window.localStorage.getItem("IsLoggedIn") == undefined ? false : true);

    if (window.localStorage.getItem("GoHome") == "Y") {
      this._router.navigate(['modules'], { relativeTo: this._activatedRoute });
      window.localStorage.removeItem("GoHome");
    }

    this.getMeuList("");

    setInterval(
      () => {
        window.localStorage.removeItem("wc");
      }, 100
    );

    //////  APPLICATION IDLE TIME CHECK START
    let idleTime = this._masterService.getIdleTime();
    if (idleTime.required == "Y") {
      this.idleTime.setIdle(parseFloat(idleTime.idleTime)); // Idle Time should be in seconds
      this.idleTime.setTimeout(parseFloat(idleTime.warningTime)); // Timeout should be in seconds
      this.idleTime.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      this.idleTime.onInterrupt.subscribe(() => {
        this.showIdleTime = false;
      });

      this.idleTime.onTimeout.subscribe(async () => {
        await this.onLogoutClick();
      });

      this.idleTime.onTimeoutWarning.subscribe(seconds => {
        this.showIdleTime = true;
        this.loginExpTime = `${(Math.floor(seconds / 60)).toString().padStart(2, '0')} Min. ${(Math.floor(seconds % 60)).toString().padStart(2, '0')} Sec.`;
      });

      this.idleTimeReset();
    }
    //////  APPLICATION IDLE TIME CHECK END
  }

  async prepareUserMenu() {
    let modDocAccess = this._masterService.getUserPermissions();
    let allModules = _.uniq(_.map(modDocAccess, "moduleName")).sort();

    let menuJson = [];
    _.forEach(allModules, (mRow, mIndex) => {
      let userModules = _.filter(modDocAccess, { moduleName: mRow });
      let allSubModules = _.uniq(_.map(userModules, "subModuleId")).sort();

      let thisModuleMenu = {
        "menuId": userModules[0].moduleId,
        "menuName": userModules[0].moduleName,
        "imageUrl": userModules[0].modImageUrl,
        "pageUrl": "",
        "metaData": null,
        "childMenus": []
      }

      if (allSubModules[0] == "0" || allSubModules[0] == null) { // If no sub modules exists
        let subModules = _.filter(modDocAccess, { moduleName: mRow });
        _.forEach(subModules, (subMod, subModIndex) => {
          let thisDocMenu = {
            "menuId": subMod.docId,
            "menuName": subMod.documentName,
            "imageUrl": subMod.docImageUrl,
            "pageUrl": ((subMod.gridUrl != "" && subMod.gridUrl != null) ? subMod.gridUrl : subMod.pageUrl),
            "metaData": { moduleId: subMod.moduleId, subModuleId: subMod.subModuleId, docId: subMod.docId },
            "childMenus": []
          };
          thisModuleMenu.childMenus.push(thisDocMenu);
        });
      }
      else { // If Sub Modules exists
        _.forEach(allSubModules, (subModId, subModIndex) => {
          let subModules = _.filter(modDocAccess, { moduleName: mRow, subModuleId: subModId });
          let thisSubModMenu = {
            "menuId": subModules[0].subModuleId,
            "menuName": subModules[0].subModuleName,
            "imageUrl": subModules[0].subModImageUrl, childMenu: [],
            "pageUrl": "",
            "metaData": null,
            "childMenus": []
          };
          _.forEach(subModules, (subMod, subModIndex) => {
            let thisDocMenu = {
              "menuId": subMod.docId,
              "menuName": subMod.documentName,
              "imageUrl": subMod.docImageUrl,
              "pageUrl": ((subMod.gridUrl != "" && subMod.gridUrl != null) ? subMod.gridUrl : subMod.pageUrl),
              "metaData": { moduleId: subMod.moduleId, subModuleId: subMod.subModuleId, docId: subMod.docId },
              "childMenus": []
            };
            thisSubModMenu.childMenus.push(thisDocMenu);
          });
          thisModuleMenu.childMenus.push(thisSubModMenu);
        });
      }
      menuJson.push(thisModuleMenu);
    });
    this.UserMenu = menuJson;

    _.forEach(this.UserMenu, (menuItem, menuIndex) => {
      this.isMenuShow.push(false);
    });
  }

  async getUserMenu() {
    this.UserMenu = await this._loginService.gerUserMenu();
    _.forEach(this.UserMenu, (menuItem, menuIndex) => {
      this.isMenuShow.push(false);
    });
  }

  navigateToMenu(pageUrl, metaData) {
    if (pageUrl != "") {
      let param = btoa(JSON.stringify(metaData));
      this._router.navigate([pageUrl, { docParams: param }], { relativeTo: this._activatedRoute });
      document.getElementById('menubar').className = "az-iconbar-aside az-iconbar-aside-primary";
    }
  }

  async onLogoutClick() {
    let params = {
      parRelogin: "N"
    };
    let newLocLoginInfo = await this._masterService.getLookupData("loginApi", "userSignoutUrl", params);

    this._router.navigate(['/login']);
    window.localStorage.setItem("UserInfo", null);
    window.localStorage.removeItem("UserInfo");
    window.localStorage.setItem("modDocAccess", null);
    window.localStorage.removeItem("modDocAccess");
    window.localStorage.setItem("UserLoc", null);
    window.localStorage.removeItem("UserLoc");
  }

  getMenuId(menuId) {
    return menuId.replace(/ /g, "_");
  }

  onMenuClick(menuIndex) {
    _.forEach(this.isMenuShow, (menuItem, menuIndex) => {
      this.isMenuShow[menuIndex] = false;
    });
    this.isMenuShow[menuIndex] = true;
  }

  getMeuList(filterValue) {
    filterValue = (filterValue.query == undefined ? "" : filterValue.query);
    let userMenus = this._masterService.getUserPermissions();
    this.menuList = [];
    _.forEach(userMenus, (menuItem, menuIndex) => {
      if (menuItem.documentName != null && menuItem.documentName.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0) {
        this.menuList.push(menuItem);
      }
    });
    this.menuList = _.sortBy(this.menuList, tMenu => tMenu.documentName);
    _.forEach(this.menuList, (menuItem, menuIndex) => {
      menuItem.index = menuIndex + 1;
    })
  }

  onMenuSelected(selectedMenu) {
    let pageUrl = ((selectedMenu.gridUrl != "" && selectedMenu.gridUrl != null) ? selectedMenu.gridUrl : selectedMenu.pageUrl);
    let metaData = { moduleId: selectedMenu.moduleId, subModuleId: selectedMenu.subModuleId, docId: selectedMenu.docId };
    this.navigateToMenu(pageUrl, metaData);
    setTimeout(() => { this.menuLabel = ""; }, 1000);
  }

  onModuleViewClick() {
    this._router.navigate(['modules'], { relativeTo: this._activatedRoute });
  }

  async onLocationChange(thisLoc) {
    let params = {
      parOrgId: thisLoc.orgId,
      parGrpId: thisLoc.grpId,
      parLocationId: thisLoc.locId,
      parUserId: this._masterService.getUserInfo("user_id"),
      parRelogin: "Y"
    };
    let newLocLoginInfo = await this._masterService.getLookupData("loginApi", "userSignoutUrl", params);
    let newLocDecrypt = this._masterService.getDecryptData(newLocLoginInfo.info);
    newLocDecrypt[0]["token"] = this._masterService.getUserInfo("token");
    let encryptLoginData = this._masterService.getEncryptData(JSON.stringify(newLocDecrypt));

    window.localStorage.setItem("UserInfo", null);
    window.localStorage.removeItem("UserInfo");
    window.localStorage.setItem("modDocAccess", null);
    window.localStorage.removeItem("modDocAccess");

    window.localStorage.setItem("UserInfo", encryptLoginData);
    this.loginLocation = this._masterService.getUserInfo("location_name");

    window.localStorage.setItem("GoHome", "Y");
    window.location.href = '/home';
  }

  async onChangepwd() {
    this.loginchangeErrMsg = this._masterService.getErrorMessages("changePasswordErrMsg");
    // LOC FOR FETCHING THE OBJECT FROM PASSWORD SETTING POLICY.
    this.pwdStngPlcs = await this._masterService.getLookupData("passwordPolicySettingsApi", "getAllPasswordUrL", { "parFlag": "G" });
    this.minLength = this.pwdStngPlcs[0]["passwPolicyMinLen"];
    this.spclChrs = this.pwdStngPlcs[0]["passwPolicySpecialCharLen"];
    this.uppCs = this.pwdStngPlcs[0]["passwPolicyMinCapitalLettersLen"];
    this.lwrCs = this.pwdStngPlcs[0]["passwPolicyMinSmallLettersLen"];
    this.minNm = this.pwdStngPlcs[0]["passwPolicyDigitsMinLen"];

    this.minLengthFlag = false;
    this.spclChrsFlag = false;
    this.uppCsFlag = false;
    this.lwrCsFlag = false;
    this.minNmFlag = false;
    this.validNewPwd = false;

    this.changePwdForm.reset();
    this.errorMessages = {
      "changepassword": "",
      "changeNewPassword": "",
      "changeConformPassword": ""
    };

    this.changePwd = true;
    this.isChceckNewPwd = false;
  }

  async onChangePwdClick() {
    this.formSubmitted = true
    let pssdFields = Object.keys(this.changePwdForm.controls);
    _.forEach(pssdFields, (pwdVal, pwdIn) => {
      this.getErrormessage(pwdVal, false);
    });
    let isErrorExists = this._masterService.showErrorSummary(this.errorMessages);
    if (isErrorExists) { return; }

    let thisFormData = JSON.parse(JSON.stringify(this.changePwdForm.getRawValue()));
    thisFormData.parUserId = this._masterService.getUserInfo("user_id");
    delete thisFormData.parNewConfPassword;
    let parSessionId = this._masterService.getUserInfo("last_sessionid")

    this.blockUI = true;
    await this._masterService.setMasterApiUrl("loginApi");
    var saveResult = await this._masterService.insertUpdateMaster("userCahngePwdUrl", thisFormData);
    this.showReq = true;
    this.saveCode = saveResult[0].errorCd;

    if (saveResult.length > 0) {
      if (saveResult[0].errorCd == "0") {
        this.toastMessage = "Password changed successfully";
        setTimeout(async () => {
          await this.onLogoutClick();
        }, 1500);
      }
      if (saveResult[0].errorCd == "0000") {
        this.toastMessage = "Old password not matching, please check again";
      }
    }
    else {
      this.toastMessage = "Unable to update password";
    }
    await this.onChangepwd();

    this.blockUI = false;
    this.changePwdForm.reset();
  };

  validateSettings(event) {
    let newPwd = this.changePwdForm.getRawValue()["parNewPassword"];

    let numUCase = (newPwd.match(/[A-Z]/g) || []).length;
    let numLCase = (newPwd.match(/[a-z]/g) || []).length;
    let numNumbers = (newPwd.match(/[0-9]/g) || []).length;
    let numSpclChar = (newPwd.match(/[@#$%^&*()_{}\[\]><.?]/g) || []).length;

    this.minLengthFlag = (newPwd.length >= this.minLength);
    this.uppCsFlag = (numUCase >= this.uppCs);
    this.lwrCsFlag = (numLCase >= this.lwrCs);
    this.minNmFlag = (numNumbers >= this.minNm);
    this.spclChrsFlag = (numSpclChar >= this.spclChrs);
    this.validNewPwd = (this.minLengthFlag && this.uppCsFlag && this.lwrCsFlag && this.minNmFlag && this.spclChrsFlag);
    this.isChceckNewPwd = (this.validNewPwd ? false : true);
  };

  onClickSeePassword(ont, ctrl, toggles) {
    switch (ctrl) {
      case "old":
        if (this.ctrlPass == 'on') {
          this.oldShowEye = 'fa fa-eye-slash eye-icon';
          this.oldIsInputText = 'text';
          this.ctrlPass = 'off';
        } else if (this.ctrlPass == 'off') {
          this.oldShowEye = 'fa fa-eye eye-icon';
          this.oldIsInputText = 'password';
          this.ctrlPass = 'on';
        }
        break;
      case "new":
        if (this.ctrlPass == 'on') {
          this.newShowEye = 'fa fa-eye-slash eye-icon';
          this.newIsInputText = 'text';
          this.ctrlPass = 'off';
        } else if (this.ctrlPass == 'off') {
          this.newShowEye = 'fa fa-eye eye-icon';
          this.newIsInputText = 'password';
          this.ctrlPass = 'on';
        }
        break;
      case "cnfrm":
        if (this.ctrlPass == 'on') {
          this.confirmShowEye = 'fa fa-eye-slash eye-icon';
          this.confirmIsInputText = 'text';
          this.ctrlPass = 'off';
        } else if (this.ctrlPass == 'off') {
          this.confirmShowEye = 'fa fa-eye eye-icon';
          this.confirmIsInputText = 'password';
          this.ctrlPass = 'on';
        }
        break;
    };
  };

  public reminders: any = {
    parDate: new Date(),
    "isReminderEventSlctd": "upcoming"
  };
  public isNotifications = false;
  async getNotifications() {}
  public notif_dt: any = new Date();
  async getSysNotifications() {
    this.sysNotifiactions = [];
    
      let labelList: any = await this._masterService.getLookupData('systemNotification', 'getComMsgMessage',
        {
          "parMsgMethodOfCommunicationId": "3",
          "parFlag": 'sys_notification',
          "parMsgReferenceId": this._masterService.getUserId()
        });


        _.forEach(labelList, (eValue, eIndex) => {
          eValue['sentDate'] = this.getTimeFormat(eValue['createDt']);
          this.sysNotifiactions.push(eValue);
        })
        if (labelList[0]['Status'] == '204' || labelList[0]['Status'] == '999') {
          this.sysNotifiactions = [];
        }
      // this.isBlockUI = false;
  };
  public newDate = new Date();
  public sysNotifiactions = [];
  public notificationMsgContent: any = "";

  async onClickNotifications() {
    this.reminders = {
      parDate: new Date(),
      "isReminderEventSlctd": "upcoming"
    };
    this.isNotifications = true;
    await this.getNotifications()
    this.notif_dt = new Date();
    await this.getSysNotifications();
  }

  async onUpdate(ind){
    await this._masterService.setMasterApiUrl("systemNotification");
    let postData = {
      "parComMsgMessage" : [{
        "message_id" : this.sysNotifiactions[ind]['messageId'],
        "msg_status" : "Y"
      }]
    };
    var saveResult = await this._masterService.insertUpdateMaster("updateComMsgMessage", postData);
    await this.getSysNotifications();
  }
  async onUpdateall(ind){
    await this._masterService.setMasterApiUrl("systemNotification");
    
    let postData = {}
    _.filter(this.sysNotifiactions,(msgr,msgri)=>{
      let pData = {
        "message_id" :  msgr['messageId'],
        "msg_status" : "Y"
      }
      postData['parComMsgMessage'].push(pData)
    })


    var saveResult = await this._masterService.insertUpdateMaster("updateComMsgMessage", postData);
    await this.getSysNotifications();
  }

  
  getTimeFormat(gdate){
   let givenDate = new Date(gdate);
   let currentDate = new Date();

   const diffInMilliseconds = currentDate.getTime() - givenDate.getTime();
   const diffInSec = Math.floor(diffInMilliseconds / (1000));
   if(diffInSec<60){
     return `${diffInSec} sec ago`
   }
   const diffInMinutes = Math.floor(diffInSec/60)
   if(diffInMinutes<60){
    return `${diffInMinutes} min ago`
  }
  const diffInHours = Math.floor(diffInMinutes/60)
  if(givenDate.toDateString() === currentDate.toDateString()){
    return `${diffInHours} hr ago`
  }

  const formattedDate = `${givenDate.getDate().toString().padStart(2,'0')}/${(givenDate.getMonth()+1).toString().padStart(2,'0')}/${givenDate.getFullYear()} 
  ${givenDate.getHours()%12||12}:${givenDate.getMinutes().toString().padStart(2,'0')} ${givenDate.getHours()>=12 ? 'PM' : 'AM'}`
  return formattedDate
}


}