import { Component, OnInit } from '@angular/core';
import { MastersService } from "../services/masters.service";
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-modulepage',
  templateUrl: './modulepage.component.html',
  styleUrls: ['./modulepage.component.css']
})
export class ModulepageComponent implements OnInit {
  public moduleSubDropdownList: boolean = false;
  public UserMenu: any = [];
  public showAllModules = false;
  public selectedMenu = {
    "menuId": "",
    "menuName": "",
    "imageUrl": "",
    "pageUrl": "",
    "metaData": null,
    "childMenus": [{
      "menuId": "",
      "menuName": "",
      "imageUrl": null,
      "pageUrl": "",
      "metaData": {
        "moduleId": "",
        "subModuleId": "",
        "docId": ""
      },
      "childMenus": []
    }]
  };

  constructor(
    private _mService: MastersService,
    private _rout: Router,
    private _actRo: ActivatedRoute,
    private messageService: MessageService
  ) { }

  async ngOnInit() {
    await this.prepareUserMenu();
    await this._mService.getConfigData();
    this.showAllModules = this._mService.showAllModules();

    setInterval(async () => {
      if (window.localStorage.getItem("modDocAccess") == null || window.localStorage.getItem("modDocAccess") == undefined) {
        this._rout.navigate(['../login']);
      }
    }, 1000);

    if ((window.localStorage.getItem("docAccess") || "") == "N") {
      this.messageService.add({ key: "toast", severity: "warn", summary: "Security", detail: "You are not authorized to view this page" });
      window.localStorage.setItem("docAccess", "");
      window.localStorage.removeItem("docAccess");
    }
  }

  async prepareUserMenu() {
    let modDocAccess = this._mService.getUserPermissions();
    let allModules = _.uniq(_.map(modDocAccess, "moduleName")).sort();

    let menuJson = [];
    _.forEach(allModules, (moduleRow, moduleIndex) => {
      let userModules = _.filter(modDocAccess, { moduleName: moduleRow });
      let allSubModules = _.uniq(_.map(userModules, "subModuleId")).sort();

      let thisModuleRow = {
        "menuId": userModules[0].moduleId,
        "menuName": userModules[0].moduleName,
        "imageUrl": userModules[0].modImageUrl || 'fas fa-th-list',
        "pageUrl": "",
        "metaData": null,
        "childMenus": []
      }

      if (allSubModules[0] == "0" || allSubModules[0] == null) { // If no sub modules exists
        let subModules = _.filter(modDocAccess, { moduleName: moduleRow });
        _.forEach(subModules, (subMod, subModIndex) => {
          let thisDocMenu = {
            "menuId": subMod.docId,
            "menuName": subMod.documentName,
            "imageUrl": subMod.docImageUrl || 'fas fa-th-list',
            "pageUrl": ((subMod.gridUrl != "" && subMod.gridUrl != null) ? subMod.gridUrl : subMod.pageUrl),
            "metaData": { moduleId: subMod.moduleId, subModuleId: subMod.subModuleId, docId: subMod.docId },
            "childMenus": []
          };
          thisModuleRow.childMenus.push(thisDocMenu);
        });
      }
      else { // If Sub Modules exists
        _.forEach(allSubModules, (subModId, subModIndex) => {
          let subModules = _.filter(modDocAccess, { moduleName: moduleRow, subModuleId: subModId });
          let thisSubModMenu = {
            "menuId": subModules[0].subModuleId,
            "menuName": subModules[0].subModuleName,
            "imageUrl": subModules[0].subModImageUrl || 'fas fa-th-list',
            childMenu: [],
            "pageUrl": "",
            "metaData": null,
            "expanded": false,
            "childMenus": []
          };
          _.forEach(subModules, (subMod, subModIndex) => {
            let thisDocMenu = {
              "menuId": subMod.docId,
              "menuName": subMod.documentName.trim(),
              "imageUrl": subMod.docImageUrl || 'fas fa-th-list',
              "pageUrl": ((subMod.gridUrl != "" && subMod.gridUrl != null) ? subMod.gridUrl : subMod.pageUrl),
              "metaData": { moduleId: subMod.moduleId, subModuleId: subMod.subModuleId, docId: subMod.docId },
              "showMenu": false,
              "childMenus": []
            };
            thisSubModMenu.childMenus.push(thisDocMenu);
          });
          thisSubModMenu.childMenus = _.sortBy(thisSubModMenu.childMenus, menu => menu.menuName);
          thisModuleRow.childMenus.push(thisSubModMenu);
        });
      }
      menuJson.push(thisModuleRow);
    });
    this.UserMenu = menuJson;
  }

  onSubModuleClick(userMenu, childMenu) {
    _.forEach(userMenu.childMenus, (uMenu, uIndex) => {
      if (uMenu == childMenu) {
        uMenu.expanded = !uMenu.expanded;
        _.forEach(uMenu.childMenus, (cMenu, cIndex) => {
          cMenu.showMenu = !cMenu.showMenu;
        });
      }
      else {
        uMenu.expanded = false;
        _.forEach(uMenu.childMenus, (cMenu, cIndex) => {
          cMenu.showMenu = false;
        });
      }
    });
  }

  navigateToMenu(pageUrl, metaData) {
    if (pageUrl != "") {
      let param = btoa(JSON.stringify(metaData));
      this._rout.navigate([`../${pageUrl}`, { docParams: param }], { relativeTo: this._actRo });
      document.getElementById('menubar').className = "az-iconbar-aside az-iconbar-aside-primary";
    }
  }

  showMenu(event, selectedMenu, menuPanel: OverlayPanel) {
    selectedMenu.childMenus = _.filter(selectedMenu.childMenus, menu => {
      if (menu.menuId != null && menu.menuName != null) return menu;
    });
    selectedMenu.childMenus = _.sortBy(selectedMenu.childMenus, menu => menu.menuName);
    _.forEach(selectedMenu.childMenus, (menu, index) => {
      if ((menu.childMenus || []).length > 0) {
        menu.childMenus = _.filter(menu.childMenus, cmenu => {
          if (cmenu.menuId != null && cmenu.menuName != null) return cmenu;
        });
        menu.childMenus = _.sortBy(menu.childMenus, cmenu => cmenu.menuName);
      }
    });
    this.selectedMenu = selectedMenu;
    menuPanel.toggle(event);
  }
}