import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Reusable Components
import { AutofocusDirective } from '../directives/autofocus.directive';
import { HeaderComponent } from '../directives//header.component';
import { GridViewComponent } from '../directives/gridview.component';
import { InputTextComponent } from '../directives/inputtext.component';
import { TextareaComponent } from '../directives/textarea.component';
import { DatepickerComponent } from '../directives/datepicker.component';
import { DropdownComponent } from '../directives/dropdown.component';
import { CaptchaComponent } from '../directives/captcha.component';
import { LookupComponent } from '../directives/lookup.component';
import { LookupAltComponent } from '../directives/lookupalt.component';
import { LookupGridComponent } from '../directives/lookupgrid.component';
import { MultiselectComponent } from '../directives/multiselect.component';
import { DateRangeComponent } from '../directives/daterange.component';
import { ReportGridViewComponent } from '../directives/reportgridview.component';
import { ImageviewerComponent } from '../directives/imageviewer.component';
import { StudentLedgerComponent } from '../directives/studentledger.component';
import { StudentBannerComponent } from '../directives/studentbanner.component';
import { BlockuiComponent } from '../directives/blockui.component';
import { SavedirectComponent } from '../directives/savedirect.component';
import { HtmleditorComponent } from '../directives/htmleditor.component';
import { GridChartComponent } from '../directives/gridchart.component';

// Web API service
import { MastersService } from "../services/masters.service";

// PrimeNG Angular Components
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/components/common/messageservice';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CardModule } from 'primeng/card';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PickListModule } from 'primeng/picklist';
import { DragDropModule } from 'primeng/dragdrop';
import {PanelModule} from 'primeng/panel'
// import { PopoverModule } from 'ngx-smart-popover';
import { ChartModule } from 'primeng/chart';
import { DataViewModule } from 'primeng/dataview';
import { StepsModule } from 'primeng/steps';
import { ColorPickerModule } from 'primeng/colorpicker'
import { ChipsModule } from 'primeng/chips'
import { OrganizationChartModule } from 'primeng/organizationchart'
//import { CarouselModule } from 'primeng/carousel';
import { TreeTableModule } from 'primeng/treetable';
import { SidebarModule } from 'primeng/sidebar';
import { ListboxModule } from 'primeng/listbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SplitButtonModule } from 'primeng/splitbutton';
//Image Viewer
import { ImageViewerModule } from 'ng2-image-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
// CK-Editor
import { AngularEditorModule } from '@kolkov/angular-editor';
// Carousel
import { IvyCarouselModule } from 'node_modules/angular-responsive-carousel';
// Full Calendar
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin!
import listPlugin from '@fullcalendar/list'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);
// Element Drag & Drop
import { AngularDraggableModule } from "angular2-draggable";
//Slider
import { SliderModule } from "primeng/slider";

import { AgGridModule } from 'ag-grid-angular-legacy'

import { QRCodeModule } from 'angularx-qrcode'

@NgModule({
  declarations: [
    AutofocusDirective,
    HeaderComponent,
    GridViewComponent,
    InputTextComponent,
    TextareaComponent,
    DatepickerComponent,
    DropdownComponent,
    CaptchaComponent,
    LookupComponent,
    LookupAltComponent,
    LookupGridComponent,
    MultiselectComponent,
    DateRangeComponent,
    ReportGridViewComponent,
    ImageviewerComponent,
    StudentLedgerComponent,
    StudentBannerComponent,
    BlockuiComponent,
    //  ImageViewerModule,
    SavedirectComponent,
    HtmleditorComponent,
    GridChartComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    AutofocusDirective,
    HeaderComponent,
    GridViewComponent,
    InputTextComponent,
    TextareaComponent,
    DatepickerComponent,
    DropdownComponent,
    CaptchaComponent,
    LookupComponent,
    LookupAltComponent,
    LookupGridComponent,
    MultiselectComponent,
    DateRangeComponent,
    ReportGridViewComponent,
    ImageViewerModule,
    NgxExtendedPdfViewerModule,
    ImageviewerComponent,
    StudentLedgerComponent,
    StudentBannerComponent,
    BlockuiComponent,
    SavedirectComponent,
    HtmleditorComponent,
    GridChartComponent,

    TableModule,
    PaginatorModule,
    InputTextModule,
    TooltipModule,
    ToastModule,
    CalendarModule,
    TabViewModule,
    ToolbarModule,
    KeyFilterModule,
    CardModule,
    AutoCompleteModule,
    DialogModule,
    DynamicDialogModule,
    FileUploadModule,
    OverlayPanelModule,
    MultiSelectModule,
    BlockUIModule,
    ProgressSpinnerModule,
    ScrollPanelModule,
    ConfirmDialogModule,
    ToggleButtonModule,
    SelectButtonModule,
    PickListModule,
    DragDropModule,
    PanelModule,
    //PopoverModule,
    ChartModule,
    DataViewModule,
    AngularEditorModule,
    ColorPickerModule,
    StepsModule,
    ChipsModule,
    OverlayPanelModule,
    OrganizationChartModule,
    //CarouselModule,
    TreeTableModule,
    IvyCarouselModule,
    SidebarModule,
    FullCalendarModule,
    ListboxModule,
    InputSwitchModule,
    AngularDraggableModule,
    SplitButtonModule,
    SliderModule,

    AgGridModule,
    QRCodeModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    TableModule,
    PaginatorModule,
    InputTextModule,
    TooltipModule,
    ToastModule,
    CalendarModule,
    TabViewModule,
    ToolbarModule,
    KeyFilterModule,
    CardModule,
    AutoCompleteModule,
    DialogModule,
    DynamicDialogModule,
    FileUploadModule,
    OverlayPanelModule,
    MultiSelectModule,
    BlockUIModule,
    ProgressSpinnerModule,
    ScrollPanelModule,
    ConfirmDialogModule,
    ToggleButtonModule,
    SelectButtonModule,
    PickListModule,
    DragDropModule,
    PanelModule,
    //PopoverModule,
    ChartModule,
    DataViewModule,
    ImageViewerModule,
    NgxExtendedPdfViewerModule,
    NgxDocViewerModule,
    AngularEditorModule,
    StepsModule,
    ColorPickerModule,
    ChipsModule,
    //CarouselModule,
    IvyCarouselModule,
    SidebarModule,
    FullCalendarModule,
    AngularDraggableModule,
    SplitButtonModule,
    SliderModule,

    AgGridModule
  ],
  providers: [
    MessageService,
    DialogService,
    ConfirmationService,
    MastersService

  ],
  entryComponents: [LookupGridComponent]
})
export class SharedComponentsModule { }