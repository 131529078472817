<table cellpadding="1" cellspacing="1">
    <tr valign="middle">
        <td>
            <canvas id="capCode" width="150" height="30"></canvas>
        </td>
        <td>
            <a (click)="createCaptcha()" id="aCaptchaRefresh"><i class="pi pi-refresh"
                    style='font-size:1.8em; font-weight: bold; color:#007bff;cursor: pointer;'></i></a>
        </td>
    </tr>
</table>