import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { MastersService } from '../services/masters.service';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 's-lookupgrid',
  templateUrl: './lookupgrid.component.html'
})
export class LookupGridComponent implements OnInit {
  @ViewChild('paginator', { static: false }) Paginator: any;
  public lookupData = [];
  public lookupCols = [];
  public display = false;
  public lookupApiUrlKey: string = "";
  public lookupDataUrlKey: string = "";
  public lookupColsKey: string = "";
  public lookupParams: any = {};
  public isMultiDataset: boolean = false;
  public math: any;
  public totalRecords = 0;
  public dataInfo = "";
  public isLoading = false;
  public filterCols = {};
  public filterColInfo = [];
  public showAdvanceSearch = false;
  public adSearchParms = ''
  public gridSearchParams = ''
  public filtermessage = '';
  // public filtermsgErrs = [];
  public showLoadDataLabel = false;
  public isAdvSearchClick = false;
  public showAdvance = true;
  public operatorList = [
    { operator: "=", numAllowed: true, strAllowed: true, dateAllowed: true },
    { operator: "!=", numAllowed: true, strAllowed: true, dateAllowed: true },
    { operator: ">", numAllowed: true, strAllowed: false, dateAllowed: true },
    { operator: ">=", numAllowed: true, strAllowed: false, dateAllowed: true },
    { operator: "<", numAllowed: true, strAllowed: false, dateAllowed: true },
    { operator: "<=", numAllowed: true, strAllowed: false, dateAllowed: true },
    { operator: "contains", numAllowed: false, strAllowed: true, dateAllowed: false }
  ]
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _mastersService: MastersService,
    private _datePipe: DatePipe
  ) {
    this.math = Math;
  }

  onRowSelect(selectedRow) {
    this.ref.close(selectedRow);
  }
  public pageOptions: any = [25, 50, 100]
  async ngOnInit() {

    this.lookupApiUrlKey = this.config.data[0].lookupApiUrlKey;
    this.lookupDataUrlKey = this.config.data[0].lookupDataUrlKey;
    this.lookupColsKey = this.config.data[0].lookupColsKey;
    this.lookupParams = JSON.parse(JSON.stringify(this.config.data[0].lookupParams));
    this.isMultiDataset = this.config.data[0].isMultiDataset;
    this.showAdvance = this.config.data[0].showAdvanceSearch
    this.gridSearchParams = this.lookupParams.parAdvanceSearch || ''
    this.adSearchParms = ''
    this.filterCols = await this._mastersService.getLookupColumns(this.config.data[0].filterCols) || { "autoLoadGrid": "Y", "showAllpageOption": "Y", "showAdvanceSearch": false, "filterCols": [] };
    this.showLoadDataLabel = this.filterCols['autoLoadGrid'] == 'N'
    if (this.filterCols['showAllpageOption'] == 'Y') this.pageOptions = [25, 50, 100, { showAll: 'All' }]

    //this.filtermsgErrs = []
    this.filterColInfo = [];
    _.forEach(this.filterCols['filterCols'], (selFilterCol, ind) => {
      selFilterCol.operator = "";
      selFilterCol.value = "";
      selFilterCol.keyFilter = (selFilterCol.dataType == 'num' ? 'num' : '');
      selFilterCol.operators = [{ label: "Select", value: "" }];
      let thisColOprators = [];
      switch (selFilterCol.dataType) {
        case "num": thisColOprators = _.filter(this.operatorList, { numAllowed: true }); break;
        case "date": thisColOprators = _.filter(this.operatorList, { dateAllowed: true }); break;
        case "string": thisColOprators = _.filter(this.operatorList, { strAllowed: true }); break;
      }
      _.forEach(thisColOprators, (opr, index) => {
        selFilterCol.operators.push({ label: opr.operator, value: opr.operator });
        selFilterCol.filterqueired = ''
      });
      this.filterColInfo.push(selFilterCol);
      //this.filtermsgErrs.push({ filterqueired: '' })
    })

    this.showAdvanceSearch = this.filterCols['showAdvanceSearch']
    if (this.filterCols['autoLoadGrid'] == 'Y' || this.filterCols['autoLoadGrid'] == undefined || !this.filterCols['showAdvanceSearch']) await this.loadLookupData(null, null);
  }

  async loadLookupData(curPageNo, curPageSize) {
    this.isLoading = true;
    if (Object.keys(this._mastersService.appConfig).length == 0) {
      await this._mastersService.getConfigData();
    }

    curPageNo = (curPageNo == null ? 1 : curPageNo);
    curPageSize = (curPageSize == null ? 25 : curPageSize);

    this.lookupParams.parPagenum = curPageNo;
    this.lookupParams.parPagesize = curPageSize;
    if (this.gridSearchParams == '' && this.adSearchParms != '') {
      this.lookupParams.parAdvanceSearch = this.adSearchParms
    }
    else if (this.adSearchParms == '' && this.gridSearchParams != '') {
      this.lookupParams.parAdvanceSearch = this.gridSearchParams
    }
    else if (this.adSearchParms == '' && this.gridSearchParams == '') {
      this.lookupParams.parAdvanceSearch = '1=1'
    }
    else {
      this.lookupParams.parAdvanceSearch = this.gridSearchParams + "and " + (this.adSearchParms || '1=1');
    }
    this.lookupParams.parAdvanceSearch = encodeURI(this.lookupParams.parAdvanceSearch)
    this.lookupData = await this._mastersService.getLookupData(this.lookupApiUrlKey, this.lookupDataUrlKey, this.lookupParams);

    if (this.isMultiDataset) {
      this.lookupData = this.lookupData["row_1"];
    }
    if (this.lookupData == undefined || this.lookupData == null) this.lookupData = [];

    if (this.lookupData.length != 0) {
      if (this.lookupData[0]["Status"] == 204 || this.lookupData[0]["Status"] == 999) {
        this.lookupData = [];
      }
    }
    else {
      this.lookupData = [];
    }

    let recFrom = (this.lookupData.length == 0 || this.lookupData[0].row == undefined ? 1 : _.minBy(this.lookupData, (record) => { return parseFloat(record.row) }).row);
    let recTo = (this.lookupData.length == 0 || this.lookupData[0].row == undefined ? this.lookupData.length : _.maxBy(this.lookupData, (record) => { return parseFloat(record.row) }).row);
    this.totalRecords = 0;
    if (this.lookupData.length > 0) this.totalRecords = (this.lookupData[0].cnt == undefined ? this.lookupData.length : this.lookupData[0].cnt);
    this.dataInfo = `Showing ${recFrom} to ${recTo} of ${this.totalRecords} records`;

    this.lookupCols = await this._mastersService.getLookupColumns(this.lookupColsKey);
    this.isLoading = false;
  }

  async onGridPageChange(event) {
    if (this.lookupData.length > 0) {
      if (!this.isAdvSearchClick) await this.onQryBuilderSubmit(null, event.page + 1, event.rows, 'page');
    }
  }

  formatDate(dateTime, dateFormat) {
    let formattedDateTime = "";
    if ((dateTime || "") != "") {
      dateTime = dateTime.replace("Z", "");
      switch (dateFormat) {
        case 'datetime': formattedDateTime = this._datePipe.transform(dateTime, "dd-MMM-yyyy hh:mm a"); break;
        case 'date': formattedDateTime = this._datePipe.transform(dateTime, "dd-MMM-yyyy"); break;
        case 'month': formattedDateTime = this._datePipe.transform(dateTime, "MMM-yyyy"); break;
        case 'monthyear': formattedDateTime = this._datePipe.transform(dateTime, "MMM-yyyy"); break;
      }
    }
    return formattedDateTime;
  }
  // async qryBuilder(Panel: OverlayPanel, page, rows, option) {
  //  // await this.onQryBuilderSubmit(Panel, page, rows, option);
  //   //Panel.hide();
  // }
  async onQryBuilderSubmit(Panel: OverlayPanel, page, rows, option) {
    this.filtermessage = '';
    let iserr = false;
    if (option == 'adSearch') {
      _.forEach(this.filterColInfo, (advCol, advIdex) => {
        advCol.filterqueired = ''
      })
      let filterDataoperator = _.filter(this.filterColInfo, col => col.operator != "")
      let filterDatavalue = _.filter(this.filterColInfo, col => col.value != "")
      if (filterDataoperator.length == 0 && filterDatavalue.length == 0) {
        this.filtermessage = "Please Select any One filter to get Data";
        iserr = true;
      }
      _.forEach(this.filterColInfo, (advCol, advIdex) => {
        if (advCol.operator != "" && advCol.value == "") {
          advCol.filterqueired = 'Please Enter Search Value';
          iserr = true;
        }
        if (advCol.operator == "" && advCol.value != "") {
          advCol.filterqueired = 'Please Select Operator';
          iserr = true;
        }
      })
    }
    if (!iserr) {
      let advanceSearch = "";
      _.forEach(this.filterColInfo, (advCol, advIdex) => {
        if (advCol.operator != "" && advCol.value != "") {
          switch (advCol.dataType) {
            case "num": advanceSearch += `${_.snakeCase(advCol.field)} ${advCol.operator} ${advCol.value} AND `; break;
            case "date":
            case "datetime":
              advanceSearch += `${_.snakeCase(advCol.field)} ${advCol.operator} '${this._datePipe.transform(advCol.value, "dd-MMM-yyyy")}' AND `; break;
            case "string": advanceSearch += `lower(${_.snakeCase(advCol.field)}) ${advCol.operator == 'contains' ? 'ilike' : advCol.operator} lower(${advCol.operator == 'contains' ? "'%" + advCol.value + "%'" : "'" + advCol.value + "'"}) AND `; break;
          }
        }
      });
      if (advanceSearch.length > 0) advanceSearch = advanceSearch.substring(0, advanceSearch.length - 4);
      this.adSearchParms = advanceSearch;
      await this.loadLookupData(page, rows);
      if (option == 'adSearch') Panel.hide()
    }
    this.isAdvSearchClick = false;
    //await this.onReportExport(this.masterApiUrlKey, this.getExportApiUrlKey, this.advanceSearch, this.parSelectedColumns, "xlsx");
    //this.showQryBldDlg = false;
  }
  async onClearAdSearch(Panel: OverlayPanel) {
    this.filtermessage = ''
    Panel.hide()
    this.adSearchParms = '';
    _.forEach(this.filterColInfo, (advCol, advIdex) => {
      advCol.operator = "";
      advCol.value = "";
      advCol.filterqueired = ""
    })
    this.lookupData = [];
    if (this.filterCols['autoLoadGrid'] == 'Y') await this.loadLookupData(null, null);
  }
  showAdsearchPanel() {
    this.filtermessage = ''
    // _.forEach(this.filterColInfo, (advCol, advIdex) => {
    //   advCol.operator = "";
    //   advCol.value = "";
    //   advCol.filterqueired = ""
    // })
  }

  resetPage() {
    this.isAdvSearchClick = true;
    this.Paginator.changePage(0);
    this.isAdvSearchClick = false;
  }

  retainAdvSearch(event, opAdvSearch: OverlayPanel) {
    opAdvSearch.show(event);
  }
}