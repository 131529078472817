import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './home/login.component';
import { HomeComponent } from './home/home.component';
import { ModulepageComponent } from './home/modulepage.component';
import { StudhomeComponent } from './portals/student/studhome.component';
import { PrivacypageComponent } from './home/privacypage.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'privacy.html', component: PrivacypageComponent },
  { path: 'onlinereg', loadChildren: () => import('./online/onlineregistration.module').then(m => m.OnlineRegistrationModule) },
  { path: 'onlineuserlogin', loadChildren: () => import('./online/onlineuserlogin.module').then(m => m.OnlineuserloginModule) },
  { path: 'onlineuserloginv2', loadChildren: () => import('./online/onlineuserloginv2.module').then(m => m.Onlineuserloginv2Module) },

  { path: 'onlinecounsellinglogin', loadChildren: () => import('./online/onlinecnslnglogin.module').then(m => m.OnlinecnslngloginModule) },
  { path: 'onlinecnslingentry', loadChildren: () => import('./online/onlinecnslingentry.module').then(m => m.OnlinecnslingentryModule) },
  { path: 'moderatorlogin', loadChildren: () => import('./online/exam/moderatorlogin.module').then(m => m.ModeratorloginModule) },
  { path: 'moderatordashboard', loadChildren: () => import('./online/exam/moderatordashboardv3.module').then(m => m.Moderatordashboardv3Module) },
  { path: 'enquirydashboard', loadChildren: () => import('./online/enquirydashboard.module').then(m => m.EnquirydashboardModule) },

  { // Portals Routes
    path: 'shome', component: StudhomeComponent,
    children: [
      { path: 'studentdashboard', loadChildren: () => import('./portals/student/studentdashboard.module').then(m => m.StudentdashboardModule) },
      { path: 'parentdashboard', loadChildren: () => import('./portals/student/parentdashboard.module').then(m => m.ParentdashboardModule) },
      { path: 'studentprofile', loadChildren: () => import('./portals/student/studprofile.module').then(m => m.StudprofileModule) },
      { path: 'studentcourse', loadChildren: () => import('./portals/student/studcourse.module').then(m => m.StudcourseModule) },
      { path: 'studentsubreg', loadChildren: () => import('./portals/student/studsubreg.module').then(m => m.StudsubregModule) },
      { path: 'studenttimetable', loadChildren: () => import('./portals/student/studtimetable.module').then(m => m.StudtimetableModule) },
      { path: 'studentfeestructure', loadChildren: () => import('./portals/student/studfeestructure.module').then(m => m.StudfeestructureModule) },
      { path: 'studentfeepayments', loadChildren: () => import('./portals/student/studfeepayments.module').then(m => m.StudfeepaymentsModule) },
      { path: 'studentfeereceipts', loadChildren: () => import('./portals/student/studfeereceipts.module').then(m => m.StudfeereceiptsModule) },
      { path: 'studentledger', loadChildren: () => import('./portals/student/studledger.module').then(m => m.StudledgerModule) },
      { path: 'studentdocuments', loadChildren: () => import('./portals/student/studdocuments.module').then(m => m.StuddocumentsModule) },
      { path: 'studentattendance', loadChildren: () => import('./portals/student/studattendance.module').then(m => m.StudattendanceModule) },
      { path: 'studenthostel', loadChildren: () => import('./portals/student/studhostel.module').then(m => m.StudhostelModule) },
      { path: 'studentholidays', loadChildren: () => import('./portals/student/studholidays.module').then(m => m.StudholidaysModule) },
      { path: 'studentfeedback', loadChildren: () => import('./portals/student/stdfeedback.module').then(m => m.StdfeedbackModule) },
      { path: 'studentfeedbackhistory', loadChildren: () => import('./portals/student/feedbackhistory.module').then(m => m.FeedbackhistoryModule) },
      { path: 'studentexamschedule', loadChildren: () => import('./portals/student/studentexamschedule.module').then(m => m.StudentexamscheduleModule) },
      { path: 'studentexamresults', loadChildren: () => import('./portals/student/studentexamresults.module').then(m => m.StudentexamresultsModule) },
      { path: 'studentonlinefeepayment', loadChildren: () => import('./portals/student/studentonlinefeepayment.module').then(m => m.StudentonlinefeepaymentModule) },
      { path: 'studentexamreg', loadChildren: () => import('./portals/student/studentexamregistration.module').then(m => m.StudentexamregistrationModule) },
      { path: 'estudymatatial', loadChildren: () => import('./portals/student/estudymaterial.module').then(m => m.EstudymaterialModule) },
      { path: 'studentonlinetest', loadChildren: () => import('./portals/student/studentonlinetest.module').then(m => m.StudentonlinetestModule) },
      { path: 'nodueapply', loadChildren: () => import('./portals/student/nodueapply.module').then(m => m.NodueapplyModule) },
      { path: 'aluminidashboard', loadChildren: () => import('./portals/student/aluminidashboard.module').then(m => m.AluminidashboardModule) },
      { path: 'aluministdlist', loadChildren: () => import('./portals/student/aluministudentlist.module').then(m => m.AluministudentlistModule) },
      { path: 'studnotification', loadChildren: () => import('./portals/student/studnotification.module').then(m => m.StudnotificationModule) },
      { path: 'studonlinetesthistory', loadChildren: () => import('./portals/student/stdonlinetesthistory.module').then(m => m.StdonlinetesthistoryModule) },
      { path: 'chatmessenger', loadChildren: () => import('./portals/chat/chat.module').then(m => m.ChatModule) },
      { path: 'studdisciplinaryactions', loadChildren: () => import('./portals/student/studdisciplinaryactions.module').then(m => m.StuddisciplinaryactionsModule) },
      { path: 'studentleaveapply', loadChildren: () => import('./portals/student/leaveapply.module').then(m => m.LeaveapplyModule) },
      { path: 'studentexamregv2', loadChildren: () => import('./portals/student/studexamreg.module').then(m => m.StudexamregModule) },
      { path: 'studlibryhistory', loadChildren: () => import('./portals/libryhistory.module').then(m => m.LibryhistoryModule) },
      { path: 'studentportfolio', loadChildren: () => import('./portals/student/studentportfolio.module').then(m => m.StudentportfolioModule) },
      { path: 'timetablev2', loadChildren: () => import('./portals/timetablev2.module').then(m => m.Timetablev2Module) },
      { path: 'stdquebank', loadChildren: () => import('./portals/student/studentquestionbank.module').then(m => m.StudentquestionbankModule) },
      { path: 'stdfactimetable', loadChildren: () => import('./portals/stdfacultytimetable.module').then(m => m.StdfacultytimetableModule) },
      { path: 'examregistrationbystudentv3', loadChildren: () => import('./portals/student/examregistratuionbystudentv3.module').then(m => m.Examregistratuionbystudentv3Module) },
      { path: 'studentexamresultv3', loadChildren: () => import('./portals/student/studentexamresultv3.module').then(m => m.Studentexamresultv3Module) },
      { path: 'studexamtimetablev3', loadChildren: () => import('./portals/student/studexamtimetablev3.module').then(m => m.Studexamtimetablev3Module) },
      { path: 'studentlogbook', loadChildren: () => import('../app/studentelogbook/studentlogbook.module').then(m => m.StudentlogbookModule) },
      { path: 'elogbookrpt', loadChildren: () => import('./reports/elogbookrpt/elogbookrpt.module').then(m => m.ElogbookrptModule) },
      { path: 'elogbookgeneralrpt', loadChildren: () => import('./reports/elogbookrpt/elogbookgeneralrpt.module').then(m => m.ElogbookgeneralrptModule) },
      { path: 'studentelogbook', loadChildren: () => import('../app/studentelogbook/studentelogbook.module').then(m => m.StudentelogbookModule) },
      { path: 'elogreport', loadChildren: () => import('./reports/elogbookrpt/elogreport.module').then(m => m.ElogreportModule) },

      //Faculty Portal
      { path: 'facultydashboard', loadChildren: () => import('./portals/faculty/facultydashboard.module').then(m => m.FacultydashboardModule) },
      { path: 'facultyprofile', loadChildren: () => import('./portals/faculty/facultyprofile.module').then(m => m.FacultyprofileModule) },
      { path: 'facultytimetable', loadChildren: () => import('./portals/faculty/facultytimetable.module').then(m => m.FacultytimetableModule) },
      { path: 'facultymarkattendance', loadChildren: () => import('./portals/faculty/facultymarkattendance.module').then(m => m.FacultymarkattendanceModule) },

      { path: 'facultymarkattendancev2', loadChildren: () => import('./portals/faculty/facultymarkattendancev3.module').then(m => m.Facultymarkattendancev3Module) },
      { path: 'facultyholidaylist', loadChildren: () => import('./portals/faculty/facultyholidaylist.module').then(m => m.FacultyholidaylistModule) },
      { path: 'facultynotificationpost', loadChildren: () => import('./portals/faculty/facultynotificationposting.module').then(m => m.FacultynotificationpostingModule) },
      { path: 'onlinetest', loadChildren: () => import('./exam/onlineexam.module').then(m => m.OnlineexamModule) },
      { path: 'onlineexamlist', loadChildren: () => import('./exam/onlineexamlist.module').then(m => m.OnlineexamlistModule) },
      { path: 'onlinetestv2', loadChildren: () => import('./exam/onlineexamv2.module').then(m => m.Onlineexamv2Module) },
      { path: 'onlineexamlistv2', loadChildren: () => import('./exam/onlineexamv2list.module').then(m => m.Onlineexamv2listModule) },
      { path: 'facultymarksentry', loadChildren: () => import('./portals/faculty/facultymarksentry.module').then(m => m.FacultymarksentryModule) },
      { path: 'facultymarksentryv2', loadChildren: () => import('./portals/faculty/facultymarksentryv2.module').then(m => m.Facultymarksentryv2Module) },
      { path: 'facultymarksverify', loadChildren: () => import('./portals/faculty/facultymarksverify.module').then(m => m.FacultymarksverifyModule) },
      { path: 'facultymarksverifyv2', loadChildren: () => import('./portals/faculty/facultymarksverifyv2.module').then(m => m.Facultymarksverifyv2Module) },
      { path: 'facultymarkspublish', loadChildren: () => import('./portals/faculty/facultymarkspublish.module').then(m => m.FacultymarkspublishModule) },
      { path: 'facultymarkspublishv2', loadChildren: () => import('./portals/faculty/facultymarkspublishv2.module').then(m => m.Facultymarkspublishv2Module) },
      { path: 'nodueapproval', loadChildren: () => import('./nodue/nodueapproval.module').then(m => m.NodueapprovalModule) },
      { path: 'elearningdocuments', loadChildren: () => import('./elearning/elearning.module').then(m => m.ElearningModule) },
      { path: 'elearningdocumentslist', loadChildren: () => import('./elearning/elearninglist.module').then(m => m.ElearninglistModule) },
      { path: 'elearningdocumentsv2', loadChildren: () => import('./elearning/elearningv2.module').then(m => m.Elearningv2Module) },
      { path: 'elearningdocumentslistv2', loadChildren: () => import('./elearning/elearningv2list.module').then(m => m.Elearningv2listModule) },
      { path: 'paperevaluation', loadChildren: () => import('./exam/paperevaluation.module').then(m => m.PaperevaluationModule) },
      { path: 'onlineevaluationv2', loadChildren: () => import('./exam/onlineevaluation.module').then(m => m.OnlineevaluationModule) },
      { path: 'facultylibryhistory', loadChildren: () => import('./portals/libryhistory.module').then(m => m.LibryhistoryModule) },
      // { path: 'onlineevaluationv2', loadChildren: () => import('./exam/onlineevaluation.module').then(m => m.OnlineevaluationModule) },
      { path: 'facultyarchivements', loadChildren: () => import('./portals/faculty/facultyarchinements.module').then(m => m.FacultyarchinementsModule) },
      { path: 'facultyquebank', loadChildren: () => import('./qbank/questionbank.module').then(m => m.QuestionbankModule) },
      { path: 'facultyquebanklist', loadChildren: () => import('./qbank/questionbanklist.module').then(m => m.QuestionbanklistModule) },
      { path: 'manualonlineevaluation', loadChildren: () => import('./portals/faculty/manualonlineevaluation.module').then(m => m.ManualonlineevaluationModule) },
      { path: 'manualexamevaluation', loadChildren: () => import('./portals/faculty/manualexamavaluation.module').then(m => m.ManualexamavaluationModule) },
      { path: 'hostelleaveapproval', loadChildren: () => import('./manageleave/hostelleaveapproval.module').then(m => m.LeaveapprovalModule) },
      { path: 'hostelleaveapprovallist', loadChildren: () => import('./manageleave/hostelleaveapprovallist.module').then(m => m.LeaveapprovallistModule) },
      { path: 'paperevaluationv3', loadChildren: () => import('./exam_V3/post-conduct/paperevaluationv3.module').then(m => m.Paperevaluationv3Module) },
      { path: 'onlineevaluationv3', loadChildren: () => import('./exam_V3/post-conduct/onlineevaluationv3.module').then(m => m.Onlineevaluationv3Module) },
      { path: 'facmtkentryv3', loadChildren: () => import('./portals/faculty/facmtkentryv3.module').then(m => m.Facmtkentryv3Module) },
      { path: 'facmtkentryv4', loadChildren: () => import('./portals/faculty/facmkentryv4.module').then(m => m.Facmkentryv4Module) },

      // BELOW PATH SHOULD BE THE LAST ONE IN ROUTES. ANY NEW ROUTES TO BE CREATED ABOVE THIS ONLY.
      { path: '**', redirectTo: `login;param=eyJpc0ZvcnNlTG9nb3V0Ijp0cnVlfQ==` },
    ]
  },

  { // Regular Login Routes
    path: 'home', component: HomeComponent,
    children: [
      { path: '', component: ModulepageComponent },
      { path: 'modules', component: ModulepageComponent },
      //Academics Masters
      { path: 'academicyearmaster', loadChildren: () => import('./masters/academicyearmaster.module').then(m => m.AcademicYearMasterModule) },
      { path: 'academicyearlist', loadChildren: () => import('./masters/academicyearlist.module').then(m => m.AcademicYearListModule) },
      { path: 'batchlist', loadChildren: () => import('./masters/batchlist.module').then(m => m.BatchListModule) },
      { path: 'batchmaster', loadChildren: () => import('./masters/batchmaster.module').then(m => m.BatchMasterModule) },
      { path: 'institutemaster', loadChildren: () => import('./masters/institutemaster.module').then(m => m.InstituteMasterModule) },
      { path: 'institutelist', loadChildren: () => import('./masters/institutelist.module').then(m => m.InstituteListModule) },
      { path: 'universitylist', loadChildren: () => import('./masters/universitylist.module').then(m => m.UniversitylistModule) },
      { path: 'universitymaster', loadChildren: () => import('./masters/universitymaster.module').then(m => m.UniversityMasterModule) },
      { path: 'disciplinelist', loadChildren: () => import('./masters/disciplinelist.module').then(m => m.DisciplineListModule) },
      { path: 'disciplinemaster', loadChildren: () => import('./masters/disciplinemaster.module').then(m => m.DisciplineMasterModule) },
      { path: 'programmelist', loadChildren: () => import('./masters/programmelist.module').then(m => m.ProgrammeListModule) },
      { path: 'programmemaster', loadChildren: () => import('./masters/programmemaster.module').then(m => m.ProgrammeMasterModule) },
      { path: 'courselist', loadChildren: () => import('./masters/courselist.module').then(m => m.CourseListModule) },
      { path: 'coursemaster', loadChildren: () => import('./masters/coursemaster.module').then(m => m.CourseMasterModule) },
      { path: 'semesterlist', loadChildren: () => import('./masters/semesterlist.module').then(m => m.SemesterListModule) },
      { path: 'semestermaster', loadChildren: () => import('./masters/semestermaster.module').then(m => m.SemesterMasterModule) },
      { path: 'classroomlist', loadChildren: () => import('./masters/classroomlist.module').then(m => m.ClassRoomListModule) },
      { path: 'classroommaster', loadChildren: () => import('./masters/classroommaster.module').then(m => m.ClassRoomMasterModule) },
      { path: 'studentgrouplist', loadChildren: () => import('./academics/studentgrouplist.module').then(m => m.StudentGroupListModule) },
      { path: 'studentgroupmaster', loadChildren: () => import('./academics/studentgroupmaster.module').then(m => m.StudentGroupMasterModule) },
      { path: 'studentgrouplistv2', loadChildren: () => import('./academics/studentgrouplistv2.module').then(m => m.Studentgrouplistv2Module) },
      { path: 'studentgroupmasterv2', loadChildren: () => import('./academics/studentgroupv2.module').then(m => m.Studentgroupv2Module) },
      { path: 'studentpromotion', loadChildren: () => import('./academics/studentpromotion.module').then(m => m.StudentPromotionModule) },
      { path: 'studentpromotionv2', loadChildren: () => import('./academics/studentpromotionv2.module').then(m => m.Studentpromotionv2Module) },
      { path: 'subregistration', loadChildren: () => import('./academics/sub-registration.module').then(m => m.SubRegistrationModule) },
      { path: 'syllabusentrymaster', loadChildren: () => import('./academics/syllabusentrymaster.module').then(m => m.SyllabusEntryMasterModule) },
      { path: 'syllabusentrylist', loadChildren: () => import('./academics/syllabusentrylist.module').then(m => m.SyllabusEntryListModule) },
      { path: 'coursedocmapping', loadChildren: () => import('./academics/coursedocumentmapping.module').then(m => m.CourseDocumentMappingModule) },
      { path: 'coursedocmappinglist', loadChildren: () => import('./academics/coursedocumentmappinglist.module').then(m => m.CoursedocumentmappinglistModule) },
      { path: 'groupmaster', loadChildren: () => import('./masters/groupmaster.module').then(m => m.GroupMasterModule) },
      { path: 'grouplist', loadChildren: () => import('./masters/grouplist.module').then(m => m.GroupListModule) },
      { path: 'recruitment', loadChildren: () => import('./masters/recruitmentsource.module').then(m => m.RecruitmentsourceModule) },
      { path: 'recruitmentsourcelist', loadChildren: () => import('./masters/recruitmentsourcelist.module').then(m => m.RecruitmentsourcelistModule) },
      { path: 'studentinfo', loadChildren: () => import('./academics/studentinfo.module').then(m => m.StudentInfoModule) },
      { path: 'evalutionscheme', loadChildren: () => import('./academics/evalutionscheme.module').then(m => m.EvalutionschemeModule) },
      { path: 'evalutionschemelist', loadChildren: () => import('./academics/evalutionschemelist.module').then(m => m.EvalutionschemelistModule) },
      { path: 'attendancescheme', loadChildren: () => import('./academics/attendancescheme.module').then(m => m.AttendanceschemeModule) },
      { path: 'attendanceschemelist', loadChildren: () => import('./academics/attendenceschemelist.module').then(m => m.AttendenceschemelistModule) },
      { path: 'gradingscheme', loadChildren: () => import('./masters/gradingscheme.module').then(m => m.GradingschemeModule) },
      { path: 'gradingschemelist', loadChildren: () => import('./masters/gradingschemelist.module').then(m => m.GradingschemelistModule) },
      { path: 'coursesettings', loadChildren: () => import('./academics/coursesettings.module').then(m => m.CourseSettingsModule) },
      { path: 'coursesettingslist', loadChildren: () => import('./academics/coursesettingslist.module').then(m => m.CourseSettingsListModule) },
      { path: 'docviewpermissions', loadChildren: () => import('./academics/docviewpermissions.module').then(m => m.DocViewPermissionsModule) },
      { path: 'preadmissionsetting', loadChildren: () => import('./academics/preadmissionsettings.module').then(m => m.PreadmissionsettingsModule) },
      { path: 'cetrecieptsettings', loadChildren: () => import('./academics/cetrecieptsettings.module').then(m => m.CetrecieptsettingsModule) },
      { path: 'provadmnnosettings', loadChildren: () => import('./academics/provisoinaladmissionsettings.module').then(m => m.ProvisoinaladmissionsettingsModule) },
      { path: 'provadmnnocancelsettings', loadChildren: () => import('./academics/provisionaladmissionncancelsetting.module').then(m => m.ProvisionaladmissionncancelsettingModule) },
      { path: 'studentsectionforms', loadChildren: () => import('./preadmission/cetstdntsectnform.module').then(m => m.CetstdntsectnformModule) },
      //Admission Masters
      { path: 'eligibility', loadChildren: () => import('./masters/eligibilitysetupmaster.module').then(m => m.EligibilitySetupMasterModule) },
      { path: 'eligibilitylist', loadChildren: () => import('./masters/eligibilitysetuplist.module').then(m => m.EligibilitySetupListModule) },
      { path: 'documentverification', loadChildren: () => import('./admission/document-verification.module').then(m => m.DocumentVerificationModule) },
      { path: 'documentupload', loadChildren: () => import('./admission/documentupload.module').then(m => m.DocumentUploadModule) },
      { path: 'reservationquotalist', loadChildren: () => import('./masters/reservationquotalist.module').then(m => m.ReservationQuotaListModule) },
      { path: 'reservationquotamaster', loadChildren: () => import('./masters/reservationquotamaster.module').then(m => m.ReservationQuotaMasterModule) },
      { path: 'eligibilityv2', loadChildren: () => import('./masters/eligibilitysetupv2.module').then(m => m.Eligibilitysetupv2Module) },


      // General Masters
      { path: 'entities', loadChildren: () => import('./masters/entities.module').then(m => m.EntitiesModule) },
      { path: 'countrylist', loadChildren: () => import('./masters/countrylist.module').then(m => m.CountrylistModule) },
      { path: 'countrymaster', loadChildren: () => import('./masters/countrymaster.module').then(m => m.CountryMasterModule) },
      { path: 'statelist', loadChildren: () => import('./masters/statelist.module').then(m => m.StateListModule) },
      { path: 'statemaster', loadChildren: () => import('./masters/statemaster.module').then(m => m.StateMasterModule) },
      { path: 'districtlist', loadChildren: () => import('./masters/districtlist.module').then(m => m.DistrictlistModule) },
      { path: 'districtmaster', loadChildren: () => import('./masters/districtmaster.module').then(m => m.DistrictMasterModule) },
      { path: 'citylist', loadChildren: () => import('./masters/citylist.module').then(m => m.CitylistModule) },
      { path: 'citymaster', loadChildren: () => import('./masters/citymaster.module').then(m => m.CityMasterModule) },
      { path: 'arealist', loadChildren: () => import('./masters/arealist.module').then(m => m.AreaListModule) },
      { path: 'areamaster', loadChildren: () => import('./masters/areamaster.module').then(m => m.AreaMasterModule) },
      { path: 'holidaymaster', loadChildren: () => import('./masters/holidaymaster.module').then(m => m.HolidaymasterModule) },
      { path: 'holidaylist', loadChildren: () => import('./masters/holidaylist.module').then(m => m.HolidaylistModule) },


      // Student Enrollment
      { path: 'enquiry', loadChildren: () => import('./admission/enquiry.module').then(m => m.EnquiryModule) },
      { path: 'enquirylist', loadChildren: () => import('./admission/enquirylist.module').then(m => m.EnquirylistModule) },
      { path: 'registration', loadChildren: () => import('./admission/registration.module').then(m => m.RegistrationModule) },
      { path: 'registrationlist', loadChildren: () => import('./admission/registrationlist.module').then(m => m.RegistrationlistModule) },
      { path: 'regconfirm', loadChildren: () => import('./admission/regconfirmation.module').then(m => m.RegConfirmationModule) },
      { path: 'regconfirmlist', loadChildren: () => import('./admission/regconfimationlist.module').then(m => m.RegconfimationlistModule) },
      { path: 'regconfirmv2', loadChildren: () => import('./admission/regconfirmationv2.module').then(m => m.Regconfirmationv2Module) },
      { path: 'regconfirmlistv2', loadChildren: () => import('./admission/regconfirmationlistv2.module').then(m => m.Regconfirmationlistv2Module) },
      { path: 'meritshortlist', loadChildren: () => import('./admission/meritchecklist.module').then(m => m.MeritCheckListModule) },
      { path: 'admission', loadChildren: () => import('./admission/admission.module').then(m => m.AdmissionModule) },
      { path: 'admissionlist', loadChildren: () => import('./admission/admissionlist.module').then(m => m.AdmissionlistModule) },
      { path: 'admnconfirmation', loadChildren: () => import('./admission/admnconfirmation.module').then(m => m.AdmnConfirmationModule) },
      { path: 'admissionconformationlist', loadChildren: () => import('./admission/admissionconformationlist.module').then(m => m.AdmissionconformationlistModule) },
      { path: 'admnconfirmationv2', loadChildren: () => import('./admission/admnconfirmationv2.module').then(m => m.Admnconfirmationv2Module) },
      { path: 'admnconfirmationlistv2', loadChildren: () => import('./admission/admnconfirmationlistv2.module').then(m => m.Admnconfirmationlistv2Module) },
      { path: 'prnupdate', loadChildren: () => import('./admission/prnupdate.module').then(m => m.PrnupdateModule) },
      { path: 'prnupdatelist', loadChildren: () => import('./admission/prnupdatelist.module').then(m => m.PrnupdatelistModule) },
      { path: 'prnuautogenerate', loadChildren: () => import('./admission/prnautognrte.module').then(m => m.PrnautognrteModule) },
      { path: 'prnuautogeneratelist', loadChildren: () => import('./admission/prnautognrtelist.module').then(m => m.PrnautognrtelistModule) },
      { path: 'prnupdatev2', loadChildren: () => import('./admission/prnupdatev2.module').then(m => m.Prnupdatev2Module) },
      { path: 'prnupdatelistv2', loadChildren: () => import('./admission/prnupdatelistv2.module').then(m => m.Prnupdatelistv2Module) },
      { path: 'prnautognrtv2', loadChildren: () => import('./admission/prnautognrtev2.module').then(m => m.Prnautognrtev2Module) },
      { path: 'prnautognrtlistv2', loadChildren: () => import('./admission/prnautognrtelistv2.module').then(m => m.Prnautognrtelistv2Module) },
      { path: 'admncancellation', loadChildren: () => import('./admission/admncancellation.module').then(m => m.AdmnCancellationModule) },
      { path: 'admncancellationlist', loadChildren: () => import('./admission/admncancellationlist.module').then(m => m.AdmncancellationlistModule) },
      { path: 'studentprofileupdate', loadChildren: () => import('./admission/registration.module').then(m => m.RegistrationModule) },
      { path: 'studentprofileupdatev2', loadChildren: () => import('./admission/registrationv2.module').then(m => m.Registrationv2Module) },
      { path: 'generaterollnumber', loadChildren: () => import('./admission/generaterolls.module').then(m => m.GeneraterollsModule) },
      { path: 'generaterollnumberv2', loadChildren: () => import('./admission/generaterrollsv2.module').then(m => m.Generaterrollsv2Module) },
      { path: 'generateligibilityform', loadChildren: () => import('./admission/generateeligibilityform.module').then(m => m.GenerateeligibilityformModule) },
      { path: 'biometricidupload', loadChildren: () => import('./admission/biometricidupload.module').then(m => m.BiometriciduploadModule) },
      { path: 'biometriciduploadlist', loadChildren: () => import('./admission/biometriciduploadlist.module').then(m => m.BiometriciduploadlistModule) },

      { path: 'biometricidupdate', loadChildren: () => import('./admission/biometricidupdate.module').then(m => m.BiometricidupdateModule) },
      { path: 'biometricidupdatev2', loadChildren: () => import('./admission/biometricidupdatev2.module').then(m => m.Biometricidupdatev2Module) },
      { path: 'biometricidupdatelistv2', loadChildren: () => import('./admission/biometricidupdatelistv2.module').then(m => m.Biometricidupdatelistv2Module) },
      { path: 'biometricupdatelist', loadChildren: () => import('./admission/biometricidupdatelist.module').then(m => m.BiometricidupdatelistModule) },
      { path: 'generateadmissionmform', loadChildren: () => import('./admission/generateadmissionform.module').then(m => m.GenerateadmissionformModule) },
      { path: 'registrtionv2', loadChildren: () => import('./admission/registrationv2.module').then(m => m.Registrationv2Module) },
      { path: 'admissionv2', loadChildren: () => import('./admission/admissionv2.module').then(m => m.Admissionv2Module) },
      { path: 'feeschemamappingv2', loadChildren: () => import('./fee/feeschemamappingv2.module').then(m => m.Feeschemamappingv2Module) },
      { path: 'feeschemamappingv3', loadChildren: () => import('./fee/feeschemamappingv3.module').then(m => m.Feeschemamappingv3Module) },
      { path: 'feeschemamappinglistv3', loadChildren: () => import('./fee/feeschemamappinglistv3.module').then(m => m.Feeschemamappinglistv3Module) },
      { path: 'admnnotification', loadChildren: () => import('./admission/admission-notification.module').then(m => m.AdmissionNotificationModule) },
      { path: 'feeschememappingv4', loadChildren: () => import('./fee/feeschememappingv4.module').then(m => m.Feeschememappingv4Module) },
      { path: 'feeschememappinglistv4', loadChildren: () => import('./fee/feeschememappinglistv4.module').then(m => m.Feeschememappinglistv4Module) },
      { path: 'formxlsxdataupload', loadChildren: () => import('./admission/formxlsxdataupload.module').then(m => m.FormxlsxdatauploadModule) },

      //Employee Registration
      { path: 'employeeregistration', loadChildren: () => import('./Employee/empregistration.module').then(m => m.EmpRegistrationModule) },
      { path: 'employeelist', loadChildren: () => import('./Employee/employeelist.module').then(m => m.EmployeeListModule) },
      { path: 'facprofileupdate', loadChildren: () => import('./Employee/facultyprofileupdate.module').then(m => m.FacultyprofileupdateModule) },
      { path: 'depthodmapping', loadChildren: () => import('./Employee/deparmenthodmapping.module').then(m => m.DeparmenthodmappingModule) },
      //Security
      { path: 'locationlist', loadChildren: () => import('./Security/locationlist.module').then(m => m.LocationlistModule) },
      { path: 'locationmaster', loadChildren: () => import('./Security/locationmaster.module').then(m => m.LocationmasterModule) },
      { path: 'rolelist', loadChildren: () => import('./Security/rolelist.module').then(m => m.RoleListModule) },
      { path: 'rolemaster', loadChildren: () => import('./Security/rolemaster.module').then(m => m.RoleMasterModule) },
      { path: 'roleloclist', loadChildren: () => import('./Security/roleloclist.module').then(m => m.RoleLocListModule) },
      { path: 'rolelocmaster', loadChildren: () => import('./Security/rolelocmaster.module').then(m => m.RoleLocMasterModule) },
      { path: 'departmentlist', loadChildren: () => import('./Security/departmentlist.module').then(m => m.DepartmentListModule) },
      { path: 'departmentmaster', loadChildren: () => import('./Security/departmentmaster.module').then(m => m.DepartmentMasterModule) },
      { path: 'documentlist', loadChildren: () => import('./Security/documentlist.module').then(m => m.DocumentListModule) },
      { path: 'documentmaster', loadChildren: () => import('./Security/documentmaster.module').then(m => m.DocumentMasterModule) },
      { path: 'usertypelist', loadChildren: () => import('./Security/usertypelist.module').then(m => m.UserTypeListModule) },
      { path: 'usertypemaster', loadChildren: () => import('./Security/usertypemaster.module').then(m => m.UserTypeMasterModule) },
      { path: 'modulelist', loadChildren: () => import('./Security/modulelist.module').then(m => m.ModuleListModule) },
      { path: 'modulemaster', loadChildren: () => import('./Security/modulemaster.module').then(m => m.ModuleMasterModule) },
      { path: 'moddocmapping', loadChildren: () => import('./Security/moduledocumentmapping.module').then(m => m.ModuleDocumentMappingModule) },
      { path: 'newusermaster', loadChildren: () => import('./Security/newusermaster.module').then(m => m.NewUserMasterModule) },
      { path: 'newuserlist', loadChildren: () => import('./Security/newuserlist.module').then(m => m.NewUserListModule) },
      { path: 'bulknewuser', loadChildren: () => import('./Security/bulknewuser.module').then(m => m.BulknewuserModule) },
      { path: 'permissionrole', loadChildren: () => import('./Security/permissionbyrole.module').then(m => m.PermissionByRoleModule) },
      { path: 'permissionuser', loadChildren: () => import('./Security/permissionbyuser.module').then(m => m.PermissionByUserModule) },
      { path: 'resourcelocmapping', loadChildren: () => import('./Security/resourcelocmapping.module').then(m => m.ResourceLocMappingModule) },
      { path: 'pswdpolicysetting', loadChildren: () => import('./Security/passwordpolicysetting.module').then(m => m.PasswordpolicysettingModule) },
      { path: 'authorizationmaster', loadChildren: () => import('./Security/authorizationmaster.module').then(m => m.AuthorizationmasterModule) },
      { path: 'approvepermissions', loadChildren: () => import('./Security/approvepermissions.module').then(m => m.ApprovepermissionsModule) },
      { path: 'reporttemplate', loadChildren: () => import('./Security/reporttemplates.module').then(m => m.ReporttemplatesModule) },
      { path: 'reporttemplatelist', loadChildren: () => import('./Security/reporttemplateslist.module').then(m => m.ReporttemplateslistModule) },
      { path: 'appsettings', loadChildren: () => import('./Security/app-settings.module').then(m => m.AppSettingsModule) },
      { path: 'userprintpreferences', loadChildren: () => import('./Security/userprintrprfrncs.module').then(m => m.UserprintrprfrncsModule) },

      { path: 'subjectlist', loadChildren: () => import('./academics/subjectlist.module').then(m => m.SubjectListModule) },
      { path: 'subjectmaster', loadChildren: () => import('./academics/subjectmaster.module').then(m => m.SubjectMasterModule) },
      { path: 'subjectcoursemapping', loadChildren: () => import('./academics/subjectcoursemapping.module').then(m => m.SubjectCourseMappingModule) },
      { path: 'subjectcoursemappinglist', loadChildren: () => import('./academics/subjectcoursemappinglist.module').then(m => m.SubjectCourseMappingListModule) },
      { path: 'subjectalcfaculty', loadChildren: () => import('./Employee/subjectallocationtofaculty.module').then(m => m.SubjectAllocationToFacultyModule) },
      { path: 'subjectalcfacultylist', loadChildren: () => import('./Employee/subjectallocationfacultylist.module').then(m => m.SubjectAllocationFacultyListModule) },
      { path: 'shiftmaster', loadChildren: () => import('./masters/shiftmaster.module').then(m => m.ShiftMasterModule) },
      { path: 'shiftlist', loadChildren: () => import('./masters/shiftlist.module').then(m => m.ShiftListModule) },
      { path: 'semestergroupmappingmaster', loadChildren: () => import('./timetable/semestergropmappingmaster.module').then(m => m.SemesterGroupMappingMasterModule) },
      { path: 'semestergroupmappinglist', loadChildren: () => import('./timetable/semestergropmappinglist.module').then(m => m.SemesterGroupMappingListModule) },
      { path: 'facultyshiftmappingmaster', loadChildren: () => import('./Employee/facultyshiftmappingmaster.module').then(m => m.FacultyShiftMappingMasterModule) },
      { path: 'roomshiftmappingmaster', loadChildren: () => import('./masters/roomshiftmappingmaster.module').then(m => m.RoomShiftMappingMasterModule) },
      { path: 'slotlist', loadChildren: () => import('./masters/slotlist.module').then(m => m.SlotListModule) },
      { path: 'slotmaster', loadChildren: () => import('./masters/slotmaster.module').then(m => m.SlotMasterModule) },
      { path: 'approvalworkflow', loadChildren: () => import('./Security/approvalworkflow.module').then(m => m.ApprovalworkflowModule) },
      { path: 'approvalworkflowlist', loadChildren: () => import('./Security/approvalworkflowlist.module').then(m => m.ApprovalworkflowlistModule) },
      { path: 'workflowtempmap', loadChildren: () => import('./Security/workflowtemplatemapping.module').then(m => m.WorkflowtemplatemappingModule) },
      { path: 'workflowtempmaplist', loadChildren: () => import('./Security/workflowtemplatemappinglist.module').then(m => m.WorkflowtemplatemappinglistModule) },
      { path: 'fieldconfiguration', loadChildren: () => import('./Security/fieldconfiguration.module').then(m => m.FieldconfigurationModule) },
      { path: 'fieldconfigurationv2', loadChildren: () => import('./Security/fieldconfigurationv2.module').then(m => m.Fieldconfigurationv2Module) },
      { path: 'fieldconfigv3', loadChildren: () => import('./Security/fieldconfigv3.module').then(m => m.Fieldconfigv3Module) },

      //Time Table
      { path: 'timetable', loadChildren: () => import('./timetable/blocktimetablev2.module').then(m => m.Blocktimetablev2Module) },
      { path: 'timetablev2', loadChildren: () => import('./timetable/timetable.module').then(m => m.TimeTableModule) },
      { path: 'timetablelist', loadChildren: () => import('./timetable/timetablelist.module').then(m => m.TimetableListModule) },
      { path: 'timetablelistv2', loadChildren: () => import('./timetable/timetablelistv2.module').then(m => m.Timetablelistv2Module) },
      { path: 'timetabledaywise', loadChildren: () => import('./timetable/timetabledaywise.module').then(m => m.TimeTableDaywiseModule) },
      { path: 'timetabledaywisev2', loadChildren: () => import('./timetable/timetabledaywisev2.module').then(m => m.Timetabledaywisev2Module) },
      { path: 'courseshedule', loadChildren: () => import('./academics/courseshedule.module').then(m => m.CourseSheduleModule) },
      { path: 'markattendance', loadChildren: () => import('./academics/attendancemarking.module').then(m => m.AttendanceMarkingModule) },
      { path: 'markattendancelist', loadChildren: () => import('./academics/attandancemarkinglist.module').then(m => m.AttandanceMarkinglistModule) },
      { path: 'coordinatorfacultymap', loadChildren: () => import('./timetable/coordinatorfacultymapping.module').then(m => m.CoordinatorfacultymappingModule) },
      { path: 'coordinatortimetable', loadChildren: () => import('./portals/stdfacultytimetable.module').then(m => m.StdfacultytimetableModule) },
      { path: 'coordinatorfacultymarkattendancev2', loadChildren: () => import('./portals/faculty/facultymarkattendancev3.module').then(m => m.Facultymarkattendancev3Module) },
      { path: 'ttroastertmplt', loadChildren: () => import('./timetable/timetableroastertemplate.module').then(m => m.TimetableroastertemplateModule) },
      { path: 'ttroastertmapp', loadChildren: () => import('./timetable/timetableroastermapping.module').then(m => m.TimetableroastermappingModule) },
      { path: 'ttroastertmapv2', loadChildren: () => import('./timetable/timetableroastermappingv2.module').then(m => m.Timetableroastermappingv2Module) },
      { path: 'ttdaywiseupdate', loadChildren: () => import('./timetable/timetabledaywiseupdate.module').then(m => m.TimetabledaywiseupdateModule) },
      { path: 'courseschedulev2', loadChildren: () => import('./academics/courseschedulev2.module').then(m => m.Courseschedulev2Module) },
      { path: 'coursesubjectmappingv2', loadChildren: () => import('./academics/coursesubjectmappingv2.module').then(m => m.Coursesubjectmappingv2Module) },
      { path: 'coursesubjectmappinglistv2', loadChildren: () => import('./academics/coursesubjectmappinglistv2.module').then(m => m.Coursesubjectmappinglistv2Module) },
      { path: 'blocktimetablev3', loadChildren: () => import('./timetable/blocktimetablev3.module').then(m => m.Blocktimetablev3Module) },
      { path: 'timetabledaywisev3', loadChildren: () => import('./timetable/daywisetimetablev3.module').then(m => m.Daywisetimetablev3Module) },


      //  { path: 'coordinatorfacultymaplist', loadChildren:()=> import('./timetable/coordinatorfacultymappinglist.module').then(m=>m.CoordinatorfacultymappinglistModule)},
      // Fee Masters
      { path: 'banklist', loadChildren: () => import('./masters/banklist.module').then(m => m.BankListModule) },
      { path: 'bankmaster', loadChildren: () => import('./masters/bankmaster.module').then(m => m.BankMasterModule) },
      { path: 'feeheadmaster', loadChildren: () => import('./masters/feeheadmaster.module').then(m => m.FeehHeadMasterModule) },
      { path: 'feeheadlist', loadChildren: () => import('./masters/feeheadlist.module').then(m => m.FeeHeadListModule) },
      { path: 'feereceipttypeheadlist', loadChildren: () => import('./masters/feereceipttypeheadlist.module').then(m => m.FeereceipttypeheadlistModule) },
      { path: 'feereceipttypeheadmaster', loadChildren: () => import('./masters/feereceipttypeheadmaster.module').then(m => m.FeereceipttypeheadmasterModule) },
      { path: 'feeschemamaster', loadChildren: () => import('./fee/feeschema.module').then(m => m.FeeschemaModule) },
      { path: 'feeschemalist', loadChildren: () => import('./fee/feeschemalist.module').then(m => m.FeeschemalistModule) },
      { path: 'feeschemamapping', loadChildren: () => import('./fee/feeschemamapping.module').then(m => m.FeeSchemaMappingModule) },
      { path: 'feeschemamappinglist', loadChildren: () => import('./fee/feeschemamappinglist.module').then(m => m.FeeschemamappinglistModule) },
      { path: 'advcollection', loadChildren: () => import('./fee/advancecollection.module').then(m => m.AdvanceCollectionModule) },
      { path: 'advcollectionlist', loadChildren: () => import('./fee/advancecollectionlist.module').then(m => m.AdvancecollectionlistModule) },
      { path: 'feecollection', loadChildren: () => import('./fee/studentfeecollection.module').then(m => m.StudentFeeCollectionModule) },
      { path: 'feecollectionlist', loadChildren: () => import('./fee/studentfeecollectionlist.module').then(m => m.StudentfeecollectionlistModule) },
      { path: 'feereceipttypeheadlist', loadChildren: () => import('./masters/feereceipttypeheadlist.module').then(m => m.FeereceipttypeheadlistModule) },
      { path: 'feereceipttypeheadmaster', loadChildren: () => import('./masters/feereceipttypeheadmaster.module').then(m => m.FeereceipttypeheadmasterModule) },
      { path: 'feereceipttypeheadlist', loadChildren: () => import('./masters/feereceipttypeheadlist.module').then(m => m.FeereceipttypeheadlistModule) },
      { path: 'feereceipttypeheadmaster', loadChildren: () => import('./masters/feereceipttypeheadmaster.module').then(m => m.FeereceipttypeheadmasterModule) },
      { path: 'studentopeningbalanceentry', loadChildren: () => import('./fee/studentopeningbalanceentry.module').then(m => m.StudentOpeningBalanceEntryModule) },
      { path: 'studentopeningbalanceentrylist', loadChildren: () => import('./fee/studentopeningbalanceentrylist.module').then(m => m.StudentopeningbalanceentrylistModule) },
      { path: 'miscellaneousfee', loadChildren: () => import('./fee/miscellaneousfee.module').then(m => m.MiscellaneousfeeModule) },
      { path: 'miscellaneousfeelist', loadChildren: () => import('./fee/miscellaneousfeelist.module').then(m => m.MiscellaneousfeelistModule) },
      { path: 'miscellaneousfeemulti', loadChildren: () => import('./fee/miscellaneousfeemulti.module').then(m => m.MiscellaneousfeemultiModule) },
      { path: 'finefee', loadChildren: () => import('./fee/latefee.module').then(m => m.LatefeeModule) },
      { path: 'finefeelist', loadChildren: () => import('./fee/latefeelist.module').then(m => m.LatefeelistModule) },
      { path: 'feediscount', loadChildren: () => import('./fee/feediscountentry.module').then(m => m.FeediscountentryModule) },
      { path: 'feediscountlist', loadChildren: () => import('./fee/feediscountentrylist.module').then(m => m.FeediscountentrylistModule) },
      { path: 'feechange', loadChildren: () => import('./fee/feechange.module').then(m => m.FeeChangeModule) },
      { path: 'feerefund', loadChildren: () => import('./fee/feerefund.module').then(m => m.FeerefundModule) },
      { path: 'feerefundlist', loadChildren: () => import('./fee/feerefundlist.module').then(m => m.FeerefundlistModule) },
      { path: 'recieptsettings', loadChildren: () => import('./fee/recieptsettings.module').then(m => m.RecieptsettingsModule) },
      { path: 'receiptrecon', loadChildren: () => import('./fee/receiptrecon.module').then(m => m.ReceiptReconModule) },
      { path: 'receiptreconlist', loadChildren: () => import('./fee/receiptreconlist.module').then(m => m.ReceiptreconlistModule) },
      { path: 'scholarshipsponsors', loadChildren: () => import('./masters/scholarshipsponsors.module').then(m => m.ScholarshipsponsorsModule) },
      { path: 'scholarshipsponsorslist', loadChildren: () => import('./masters/scholarshipsponsorslist.module').then(m => m.ScholarshipsponsorslistModule) },
      { path: 'feeapplydue', loadChildren: () => import('./fee/applydue.module').then(m => m.ApplydueModule) },
      { path: 'feeapplyduelist', loadChildren: () => import('./fee/applyduelist.module').then(m => m.ApplyduelistModule) },
      { path: 'paymentgatewayconfiglist', loadChildren: () => import('./fee/paygetconfiglist.module').then(m => m.PaygetconfiglistModule) },
      { path: 'paymentgatewayconfig', loadChildren: () => import('./fee/paygateconfig.module').then(m => m.PaygateconfigModule) },
      { path: 'feeduechangecancel', loadChildren: () => import('./fee/feeduechangecancel.module').then(m => m.FeeduechangecancelModule) },
      { path: 'feeduechangecancellist', loadChildren: () => import('./fee/feeduechangecancellist.module').then(m => m.FeeduechangecancellistModule) },
      { path: 'schedulfeenotification', loadChildren: () => import('./fee/schedulfeenotification.module').then(m => m.SchedulfeenotificationModule) },
      { path: 'schedulfeenotificationlist', loadChildren: () => import('./fee/schedulfeenotificationlist.module').then(m => m.SchedulfeenotificationlistModule) },
      { path: 'bulkfeeduedateextenstion', loadChildren: () => import('./fee/bulkfeeduedateextention.module').then(m => m.BulkfeeduedateextentionModule) },
      { path: 'bulkfeeduedateextenstionlist', loadChildren: () => import('./fee/bulkfeeduedateextentionlist.module').then(m => m.BulkfeeduedateextentionlistModule) },

      { path: 'bulkfeeduedateext2', loadChildren: () => import('./fee/bulkfeeduedateext2.module').then(m => m.Bulkfeeduedateext2Module) },
      { path: 'schfeenotificationv2', loadChildren: () => import('./fee/schedulefeenotificationv2.module').then(m => m.Schedulefeenotificationv2Module) },
      { path: 'feeapplyduev2', loadChildren: () => import('./fee/applyduev2.module').then(m => m.Applyduev2Module) },
      { path: 'applyduepostadmn', loadChildren: () => import('./fee/applyduepostadmission.module').then(m => m.ApplyduepostadmissionModule) },
      { path: 'finefeev2', loadChildren: () => import('./fee/latefeev2.module').then(m => m.Latefeev2Module) },
      { path: 'miscellaneousfeev2', loadChildren: () => import('./fee/miscellaneousv2.module').then(m => m.Miscellaneousv2Module) },


      { path: 'feeschematemplate', loadChildren: () => import('./fee/fstmplte.module').then(m => m.FstmplteModule) },
      { path: 'feeschematemplatelist', loadChildren: () => import('./fee/fstmpltelist.module').then(m => m.FstmpltelistModule) },
      { path: 'feeschematemplatemapping', loadChildren: () => import('./fee/fstmpltemppng.module').then(m => m.FstmpltemppngModule) },
      { path: 'feeschematemplatemappinglist', loadChildren: () => import('./fee/fstmpltemppnglist.module').then(m => m.FstmpltemppnglistModule) },
      { path: 'academicsessionfeeterm', loadChildren: () => import('./fee/academicsessionfeetermmapping.module').then(m => m.AcademicsessionfeetermmappingModule) },
      { path: 'charts', loadChildren: () => import('./management/visuals.module').then(m => m.VisualsModule) },
      { path: 'librarydashboard', loadChildren: () => import('./management/librarydashboard.module').then(m => m.LibrarydashboardModule) },

      //Reports

      //Admission / Academics Reports
      { path: 'studentenquiry', loadChildren: () => import('./reports/academicsReports/studentenquiry.module').then(m => m.StudentenquiryModule) },
      { path: 'studentregistration', loadChildren: () => import('./reports/academicsReports/studentregistration.module').then(m => m.StudentregistrationModule) },
      { path: 'studentregistrationv2', loadChildren: () => import('./reports/academicsReports/studentregistrationv2.module').then(m => m.Studentregistrationv2Module) },
      { path: 'studentadmission', loadChildren: () => import('./reports/academicsReports/studentadmission.module').then(m => m.StudentadmissionModule) },
      { path: 'studentadmissionv2', loadChildren: () => import('./reports/academicsReports/studentadmission-v2.module').then(m => m.StudentadmissionV2Module) },
      { path: 'pendingdocumentreport', loadChildren: () => import('./reports/academicsReports/pendingdocumentrpeort.module').then(m => m.PendingdocumentrpeortModule) },
      { path: 'pendingdocumentreportv2', loadChildren: () => import('./reports/academicsReports/pendingdocumentreportv2.module').then(m => m.Pendingdocumentreportv2Module) },
      { path: 'stdadmnstatisticsrpt', loadChildren: () => import('./reports/academicsReports/stdadmnstatisticsrpt.module').then(m => m.StdadmnstatisticsrptModule) },

      { path: 'stdadmnstatisticsrptv2', loadChildren: () => import('./reports/academicsReports/stdadmnstatisticsrptv2.module').then(m => m.Stdadmnstatisticsrptv2Module) },

      { path: 'stdadmnstatisticsrptv2', loadChildren: () => import('./reports/academicsReports/stdadmnstatisticsrptv2.module').then(m => m.Stdadmnstatisticsrptv2Module) },

      { path: 'coursesemsubrpt', loadChildren: () => import('./reports/academicsReports/coursesemsubrpt.module').then(m => m.CoursesemsubrptModule) },
      { path: 'pendingregconrpt', loadChildren: () => import('./reports/academicsReports/pendingregconrpt.module').then(m => m.PendingregconrptModule) },
      { path: 'pendingregconrptv2', loadChildren: () => import('./reports/academicsReports/pendingregconrptv2.module').then(m => m.Pendingregconrptv2Module) },
      { path: 'pendingadmnconrpt', loadChildren: () => import('./reports/academicsReports/pendingadmnconrpt.module').then(m => m.PendingadmnconrptModule) },
      { path: 'pendingadmnconrptv2', loadChildren: () => import('./reports/academicsReports/pendingadmnconrptv2.module').then(m => m.Pendingadmnconrptv2Module) },

      { path: 'canceladmnrpt', loadChildren: () => import('./reports/academicsReports/canceladmnrpt.module').then(m => m.CanceladmnrptModule) },
      { path: 'studentgrouprpt', loadChildren: () => import('./reports/academicsReports/studentgrouprpt.module').then(m => m.StudentgrouprptModule) },
      { path: 'studentgrouprptv2', loadChildren: () => import('./reports/academicsReports/studentgrouprptv2.module').then(m => m.Studentgrouprptv2Module) },
      { path: 'acdyrwisestd', loadChildren: () => import('./reports/academicsReports/academicyearwisestdreport.module').then(m => m.AcademicyearwisestdreportModule) },
      { path: 'acdyrwisestdrptv2', loadChildren: () => import('./reports/academicsReports/academicyearwisereportv2.module').then(m => m.Academicyearwisereportv2Module) },
      { path: 'canceladmnrptv3', loadChildren: () => import('./reports/academicsReports/canceladmnrptv3.module').then(m => m.Canceladmnrptv3Module) },
      { path: 'prnupdaterpt', loadChildren: () => import('./reports/academicsReports/prnupdaterpt.module').then(m => m.PrnupdaterptModule) },
      // Award Entry Report


      // Award Entry Report
      { path: 'acdyrwisestdv2', loadChildren: () => import('./reports/academicsReports/academicyearwisereportv2.module').then(m => m.Academicyearwisereportv2Module) },


      // Award Entry Report
      { path: 'awardentryrpt', loadChildren: () => import('./reports/awardsReports/awardsreports.module').then(m => m.AwardsReportsModule) },

      //Fee Collection Rerport
      { path: 'dailyreport', loadChildren: () => import('./reports/feeReport/daily-collection-report.module').then(m => m.DailyCollectionReportModule) },
      { path: 'dailyreportv2', loadChildren: () => import('./reports/feeReport/daily-collection-reportv2.module').then(m => m.DailyCollectionReportv2Module) },
      { path: 'Feeheadwisecollection', loadChildren: () => import('./reports/feeReport/feeheadwisecollection.module').then(m => m.FeeheadwisecollectionModule) },
      { path: 'revenuereport', loadChildren: () => import('./reports/feeReport/revenuereport.module').then(m => m.RevenuereportModule) },
      { path: 'duesreports', loadChildren: () => import('./reports/feeReport/duesreports.module').then(m => m.DuesreportsModule) },
      { path: 'defaulters', loadChildren: () => import('./reports/feeReport/defaulters.module').then(m => m.DefaultersModule) },
      { path: 'revenuereport', loadChildren: () => import('./reports/feeReport/revenuereport.module').then(m => m.RevenuereportModule) },
      { path: 'revenuereport', loadChildren: () => import('./reports/feeReport/revenuereport.module').then(m => m.RevenuereportModule) },
      { path: 'latefeereports', loadChildren: () => import('./reports/feeReport/latefee.module').then(m => m.LatefeeModule) },
      { path: 'openingbalancereports', loadChildren: () => import('./reports/feeReport/openingbalancereport.module').then(m => m.OpeningbalancereportModule) },
      { path: 'advancecollectionreport', loadChildren: () => import('./reports/feeReport/advancecollectionreport.module').then(m => m.AdvancecollectionreportModule) },
      { path: 'advancebalancerpt', loadChildren: () => import('./reports/feeReport/advancebalancerpt.module').then(m => m.AdvancebalancerptModule) },
      { path: 'discountreport', loadChildren: () => import('./reports/feeReport/discountreport.module').then(m => m.DiscountreportModule) },
      { path: 'studentledger', loadChildren: () => import('./reports/feeReport/studentledgerinfo.module').then(m => m.StudentLedgerInfoModule) },
      { path: 'duesapplyreport', loadChildren: () => import('./reports/feeReport/duesapplyreport.module').then(m => m.DuesapplyreportModule) },
      { path: 'studentfeeledger', loadChildren: () => import('./reports/feeReport/studentfeeledger.module').then(m => m.StudentfeeledgerModule) },
      { path: 'reconciliationreport', loadChildren: () => import('./reports/feeReport/reconciliationreport.module').then(m => m.ReconciliationreportModule) },
      { path: 'stdopenbalreport', loadChildren: () => import('./reports/feeReport/studentopeningbalancerpt.module').then(m => m.StudentopeningbalancerptModule) },
      { path: 'stdopenbalreportv2', loadChildren: () => import('./reports/feeReport/studentopeningbalancerptv2.module').then(m => m.Studentopeningbalancerptv2Module) },
      { path: 'auditreport', loadChildren: () => import('./reports/feeReport/auditreport.module').then(m => m.AuditreportModule) },
      { path: 'auditreportv2', loadChildren: () => import('./reports/feeReport/auditreportv2.module').then(m => m.Auditreportv2Module) },
      { path: 'balanceduefeeheadwiserpt', loadChildren: () => import('./reports/feeReport/balanceduefeeheadwiserpt.module').then(m => m.BalanceduefeeheadwiserptModule) },
      { path: 'balanceduefeeheadwiserptv2', loadChildren: () => import('./reports/feeReport/balanceduefeeheadwiserptv2.module').then(m => m.Balanceduefeeheadwiserptv2Module) },

      { path: 'projectedrevenureport', loadChildren: () => import('./reports/feeReport/projected-revenue-report.module').then(m => m.ProjectedRevenueReportModule) },
      { path: 'onlinepaymentreport', loadChildren: () => import('./reports/feeReport/onlinepaymentreport.module').then(m => m.OnlinepaymentreportModule) },
      { path: 'duenotificationreport', loadChildren: () => import('./reports/feeReport/duenotificationreport.module').then(m => m.DuenotificationreportModule) },
      { path: 'feerefundreport', loadChildren: () => import('./reports/feeReport/feerefundreport.module').then(m => m.FeerefundreportModule) },
      { path: 'dailyfeecollectionfeeheadwise', loadChildren: () => import('./reports/feeReport/dailyfeecollectionfeeheadwise.module').then(m => m.DailyfeecollectionfeeheadwiseModule) },
      { path: 'dailyfeecollectionfeeheadwisev2', loadChildren: () => import('./reports/feeReport/dailyfeecollectionfeeheadwisev2.module').then(m => m.Dailyfeecollectionfeeheadwisev2Module) },
      { path: 'duenotificationletter', loadChildren: () => import('./reports/feeReport/duenotificationletter.module').then(m => m.DuenotificationletterModule) },
      { path: 'duenotificationletter', loadChildren: () => import('./reports/feeReport/duenotificationletter.module').then(m => m.DuenotificationletterModule) },
      { path: 'finepostingrpt', loadChildren: () => import('./reports/feeReport/finepostingrpt.module').then(m => m.FinepostingrptModule) },
      { path: 'miscellaneousfeepost', loadChildren: () => import('./reports/feeReport/miscellaneousfeepostingrpt.module').then(m => m.MiscellaneousfeepostingrptModule) },
      // { path: 'miscellaneousfeepostv2', loadChildren: () => import('./reports/feeReport/miscellaneousfeepostingrptv2.module').then(m => m.Miscellaneousfeepostingrptv2Module) },
      { path: 'cancelledreceiptsreport', loadChildren: () => import('./reports/feeReport/cancelledreciptsreport.module').then(m => m.CancelledreciptsreportModule) },
      { path: 'cancelledreceiptsreportv2', loadChildren: () => import('./reports/feeReport/cancelledreciptsreportv2.module').then(m => m.Cancelledreciptsreportv2Module) },
      { path: 'stdduereceivablerpt', loadChildren: () => import('./reports/feeReport/stdduereceivablerpt.module').then(m => m.StdduereceivablerptModule) },
      { path: 'tax269strpt', loadChildren: () => import('./reports/feeReport/tax269strpt.module').then(m => m.Tax269strptModule) },
      { path: 'tax269ssrpt', loadChildren: () => import('./reports/feeReport/tax269ssrpt.module').then(m => m.Tax269ssrptModule) },
      { path: 'tax269trpt', loadChildren: () => import('./reports/feeReport/tax269trpt.module').then(m => m.Tax269trptModule) },

      //Pre Admission Reports
      { path: 'preadmattendancerep', loadChildren: () => import('./reports/preadmisstionreports/preadmisstionattendancereport.module').then(m => m.PreAdmisstionAttendanceReportModule) },
      { path: 'docverificationrep', loadChildren: () => import('./reports/preadmisstionreports/documentverificationreport.module').then(m => m.DocumentverificationreportModule) },
      { path: 'preadmissionpaymentrep', loadChildren: () => import('./reports/preadmisstionreports/preadmissionpaymentreport.module').then(m => m.PreadmissionpaymentreportModule) },
      { path: 'provisionaladmnrep', loadChildren: () => import('./reports/preadmisstionreports/provisionaladmissionreport.module').then(m => m.ProvisionaladmissionreportModule) },
      { path: 'preadmncancelrep', loadChildren: () => import('./reports/preadmisstionreports/preadmissioncancellationreport.module').then(m => m.PreadmissioncancellationreportModule) },

      { path: 'counsellingstatusrpt', loadChildren: () => import('./reports/preadmisstionreports/counsellingstatusreport.module').then(m => m.CounsellingstatusreportModule) },
      { path: 'cetpendingdocrpt', loadChildren: () => import('./reports/preadmisstionreports/cetpendingdocumentreport.module').then(m => m.CetpendingdocumentreportModule) },
      { path: 'cetfeeduerpt', loadChildren: () => import('./reports/preadmisstionreports/cetfeeduereport.module').then(m => m.CetfeeduereportModule) },

      { path: 'feecollectionrpt', loadChildren: () => import('./reports/preadmisstionreports/feecollectionrpt.module').then(m => m.FeecollectionrptModule) },
      { path: 'feecancelrpt', loadChildren: () => import('./reports/preadmisstionreports/feecancelatonrpt.module').then(m => m.FeecancelatonrptModule) },
      { path: 'dailycollectfeeheadwise', loadChildren: () => import('./reports/preadmisstionreports/dailycollectonfeeheadwiserpt.module').then(m => m.DailycollectonfeeheadwiserptModule) },
      { path: 'studentregistrationrpt', loadChildren: () => import('./reports/preadmisstionreports/studentregistrationreport.module').then(m => m.StudentregistrationreportModule) },
      { path: 'studprovisionaladmnrpt', loadChildren: () => import('./reports/preadmisstionreports/studentprovisionaladmissionrpt.module').then(m => m.StudentprovisionaladmissionrptModule) },
      { path: 'studprovisionaladmncanclrpt', loadChildren: () => import('./reports/preadmisstionreports/studentprovisionaladmncancellationrpt.module').then(m => m.StudentprovisionaladmncancellationrptModule) },
      //Attendance Reports
      { path: 'dailyattendancereport', loadChildren: () => import('./reports/attendancereports/dailyattendance.module').then(m => m.DailyattendanceModule) },
      { path: 'dailyslotattendancereport', loadChildren: () => import('./reports/attendancereports/dailyslotattendance.module').then(m => m.DailyslotattendanceModule) },
      { path: 'hostelattendancereport', loadChildren: () => import('./reports/attendancereports/hostelattendance.module').then(m => m.HostelattendanceModule) },
      { path: 'studentnotreturnedafterhostelleaverpt', loadChildren: () => import('./reports/hostelReports/studentnotreturnedafterhostelleave.module').then(m => m.StudentnotreturnedafterhostelleaveModule) },
      { path: 'deptwiseattendancerpt', loadChildren: () => import('./reports/attendancereports/departmentwiseattendancereport.module').then(m => m.DepartmentwiseattendancereportModule) },
      { path: 'facultymarkattrpt', loadChildren: () => import('./reports/attendancereports/facultymarkattendancereport.module').then(m => m.FacultymarkattendancereportModule) },
      { path: 'syllabusprogressionreport', loadChildren: () => import('./reports/attendancereports/syllabusprogression.module').then(m => m.SyllabusprogressionModule) },
      { path: 'counsellingdashboard', loadChildren: () => import('./reports/preadmisstionreports/cnsllngdashbrd.module').then(m => m.CnsllngdashbrdModule) },
      //Exam Reports
      { path: 'examschdulereport', loadChildren: () => import('./reports/examreports/examschdulereport.module').then(m => m.ExamschdulereportModule) },
      { path: 'marksreport', loadChildren: () => import('./reports/examreports/marksreport.module').then(m => m.MarksreportModule) },
      { path: 'bundlesummaryrpt', loadChildren: () => import('./reports/examreports/bundlesummary.module').then(m => m.BundlesummaryModule) },
      { path: 'bundlecollectionrpt', loadChildren: () => import('./reports/examreports/bundlecollection.module').then(m => m.BundlecollectionModule) },
      { path: 'bookletforbundlingrpt', loadChildren: () => import('./reports/examreports/bookletforbundling.module').then(m => m.BookletforbundlingModule) },
      { path: 'bundleassigned', loadChildren: () => import('./reports/examreports/bundleassigned.module').then(m => m.BundleassignedModule) },
      { path: 'examinationmastersetprptv2', loadChildren: () => import('./reports/examreports/exammastersetupv2.module').then(m => m.Exammastersetupv2Module) },
      { path: 'examstudrpt', loadChildren: () => import('./reports/examreports/examstudrpt.module').then(m => m.ExamstudrptModule) },
      { path: 'courseSchrptv2', loadChildren: () => import('./reports/examreports/courseschrpt.module').then(m => m.CourseschrptModule) },
      { path: 'examtimetablerptv2', loadChildren: () => import('./reports/examreports/examtimetablereportv2.module').then(m => m.Examtimetablereportv2Module) },
      { path: 'examincsummrptv2', loadChildren: () => import('./reports/examreports/centerinchargesummaryreportv2.module').then(m => m.Centerinchargesummaryreportv2Module) },
      { path: 'batchwisegrprptv2', loadChildren: () => import('./reports/examreports/batchwiseexamgroupingreport.module').then(m => m.BatchwiseexamgroupingreportModule) },
      { path: 'daywiseexamSummaryrptv2', loadChildren: () => import('./reports/examreports/daywiseexamsummaryreport.module').then(m => m.DaywiseexamsummaryreportModule) },
      { path: 'marksrptv2', loadChildren: () => import('./reports/examreports/marksrptv2.module').then(m => m.Marksrptv2Module) },
      { path: 'resdeclarerptv2', loadChildren: () => import('./reports/examreports/resultsdeclarationreportv2.module').then(m => m.Resultsdeclarationreportv2Module) },
      //Hostel / Hostel Reports       
      { path: 'hostelbedstatusreport', loadChildren: () => import('./reports/hostelReports/hostelbedstatusreport.module').then(m => m.HostelbedstatusreportModule) },
      { path: 'hostelleavereport', loadChildren: () => import('./reports/hostelReports/hostelleavereport.module').then(m => m.HostelleavereportModule) },
      { path: 'roomchangehistory', loadChildren: () => import('./reports/hostelReports/roomchangehistoryreport.module').then(m => m.RoomchangehistoryreportModule) },
      { path: 'termoverstdrpt', loadChildren: () => import('./reports/hostelReports/termoverstdrpt.module').then(m => m.TermoverstdrptModule) },
      { path: 'hstlalctdwithoutfeestdrpt', loadChildren: () => import('./reports/hostelReports/hstlallocatedwithoutfeestdrpt.module').then(m => m.HstlallocatedwithoutfeestdrptModule) },
      { path: 'hstldeallocationrpt', loadChildren: () => import('./reports/hostelReports/hstldeallocationrpt.module').then(m => m.HstldeallocationrptModule) },
      { path: 'hstlledger', loadChildren: () => import('./reports/hostelReports/hostelledger.module').then(m => m.HostelledgerModule) },
      //visitor Pass / Reports       
      { path: 'visitorpassreport', loadChildren: () => import('./reports/visitorpassreports/visitorpassreport.module').then(m => m.VisitorpassreportModule) },

      //AppUsage / AppUsage Reports
      { path: 'appusagereport', loadChildren: () => import('./reports/appusagereports/appusagereport.module').then(m => m.AppUsageReportModule) },

      //Library Reports
      { path: 'bookcatalogue', loadChildren: () => import('./reports/libraryReports/bookcatalogue.module').then(m => m.BookcatalogueModule) },
      { path: 'booktransaction', loadChildren: () => import('./reports/libraryReports/booktransaction.module').then(m => m.BooktransactionModule) },
      { path: 'librarymembershiprpt', loadChildren: () => import('./reports/libraryReports/librarymembershiprpt.module').then(m => m.LibrarymembershiprptModule) },
      { path: 'lbrybookresvtnrpt', loadChildren: () => import('./reports/libraryReports/librybokrsvtnrpt.module').then(m => m.LibrybokrsvtnrptModule) },
      { path: 'subjectcirculation', loadChildren: () => import('./reports/libraryReports/subjectwisecirculationreport.module').then(m => m.SubjectwisecirculationreportModule) },
      { path: 'bookcirculation', loadChildren: () => import('./reports/libraryReports/bookwisecirculationreport.module').then(m => m.BookwisecirculationreportModule) },
      { path: 'costwisebookrpt', loadChildren: () => import('./reports/libraryReports/costwisebookreport.module').then(m => m.CostwisebookreportModule) },
      { path: 'libraryusagerpt', loadChildren: () => import('./reports/libraryReports/libraryusagereport.module').then(m => m.LibraryusagereportModule) },
      { path: 'bookreplacereport', loadChildren: () => import('./reports/libraryReports/bookreplacementreport.module').then(m => m.BookreplacementreportModule) },
      { path: 'libfineduereport', loadChildren: () => import('./reports/libraryReports/libraryfineduereport.module').then(m => m.LibraryfineduereportModule) },


      //Feedback Reports
      { path: 'feedbacksummary', loadChildren: () => import('./reports/feedbackReports/feedbacksummary.module').then(m => m.FeedbacksummaryModule) },
      { path: 'feedbacksuggestion', loadChildren: () => import('./reports/feedbackReports/feedbacksuggestion.module').then(m => m.FeedbacksuggestionModule) },
      { path: 'feedbackresponse', loadChildren: () => import('./reports/feedbackReports/studentfeedbackresponse.module').then(m => m.StudentfeedbackresponseModule) },
      { path: 'studentfeedback', loadChildren: () => import('./reports/feedbackReports/studentfeedback.module').then(m => m.StudentfeedbackModule) },
      { path: 'batchwisefeedback', loadChildren: () => import('./reports/feedbackReports/batchwisefeedbackreport.module').then(m => m.BatchwisefeedbackreportModule) },

      // Transport Reports
      { path: 'routeMapRpt', loadChildren: () => import('./reports/transportreports/routemaprpt.module').then(m => m.RoutemaprptModule) },
      { path: 'vehicleRpt', loadChildren: () => import('./reports/transportreports/vehiclerpt.module').then(m => m.VehiclerptModule) },
      { path: 'vehicleschRpt', loadChildren: () => import('./reports/transportreports/vehicleschrpt.module').then(m => m.VehicleschrptModule) },
      { path: 'driverattenderRpt', loadChildren: () => import('./reports/transportreports/driver-attender-rpt.module').then(m => m.DriverAttenderRptModule) },
      { path: 'vehicleOccupancyRpt', loadChildren: () => import('./reports/transportreports/vehicleoccupancyrpt.module').then(m => m.VehicleoccupancyrptModule) },

      //Employee registration Reports
      { path: 'employeeRegRpt', loadChildren: () => import('./reports/employeereports/employeeregrpt.module').then(m => m.EmployeeregrptModule) },
      { path: 'facultysubmaprpt', loadChildren: () => import('./reports/employeereports/faculty-subjectmaprpt.module').then(m => m.FacultySubjectmaprptModule) },
      { path: 'facultyworkloadrpt', loadChildren: () => import('./reports/employeereports/facultyworkloadrpt.module').then(m => m.FacultyworkloadrptModule) },
      { path: 'facultywisetopicscoveredrpt', loadChildren: () => import('./reports/employeereports/facultywisetopicscoveredrpt.module').then(m => m.FacultywisetopicscoveredrptModule) },
      //E-Learning Reports
      { path: 'e-studydocupload', loadChildren: () => import('./reports/e-learningreports/e-studydocuploadrpt.module').then(m => m.EStudydocuploadrptModule) },

      //student notification reports
      { path: 'notifpostingrpt', loadChildren: () => import('./reports/studnotificationreports/notificationpostingrpt.module').then(m => m.NotificationpostingrptModule) },

      //Alumni Reports
      { path: 'alumnirpt', loadChildren: () => import('./reports/alumnireports/alumnipostingrpt.module').then(m => m.AlumnipostingrptModule) },

      //No Due Reports
      { path: 'noduestatusrpt', loadChildren: () => import('./reports/noduereports/noduestatusrpt.module').then(m => m.NoduestatusrptModule) },
      { path: 'nodueworkflowrpt', loadChildren: () => import('./reports/noduereports/nodueworkflowrpt.module').then(m => m.NodueworkflowrptModule) },
      { path: 'nodueapprovalrpt', loadChildren: () => import('./reports/noduereports/nodueapprovalrpt.module').then(m => m.NodueapprovalrptModule) },
      //Certificate Reports
      { path: 'certificateprintrpt', loadChildren: () => import('./reports/certificateprintreports/certificateprintreport.module').then(m => m.CertificateprintreportModule) },

      //Online Examnation Reports
      { path: 'onlineExaminationrpt', loadChildren: () => import('./reports/onlineexamreports/onlineexamnationmarksrpt.module').then(m => m.OnlineexamnationmarksrptModule) },

      //Disciplinary Action Reports
      { path: 'disciplinaryactionrpt', loadChildren: () => import('./reports/disciplinaryactionreports/disciplinaryactionapprpt.module').then(m => m.DisciplinaryactionapprptModule) },

      // Time Table
      { path: 'blocktimetable', loadChildren: () => import('./reports/timetablereports/blocktimetable.module').then(m => m.BlocktimetableModule) },
      { path: 'daywisetimetablereport', loadChildren: () => import('./reports/timetablereports/daywisetimetable.module').then(m => m.DaywisetimetableModule) },
      { path: 'deptweekytimetable', loadChildren: () => import('./reports/timetablereports/deptwiseweeklytimetablereport.module').then(m => m.DeptwiseweeklytimetablereportModule) },

      //Statistical Reports
      { path: 'admissioncategorygender', loadChildren: () => import('./reports/statisticalReports/admissioncategorygender.module').then(m => m.AdmissioncategorygenderModule) },
      { path: 'studstatisreligionrpt', loadChildren: () => import('./reports/statisticalReports/studreligionstatisrpt.module').then(m => m.StudreligionstatisrptModule) },
      { path: 'studstatiszonerpt', loadChildren: () => import('./reports/statisticalReports/studzonestatisrpt.module').then(m => m.StudzonestatisrptModule) },
      { path: 'withinwithoutmhgender', loadChildren: () => import('./reports/statisticalReports/withinwithoutmhgendrerpt.module').then(m => m.WithinwithoutmhgendrerptModule) },
      { path: 'withinmhgender', loadChildren: () => import('./reports/statisticalReports/withinmhgenderrpt.module').then(m => m.WithinmhgenderrptModule) },
      { path: 'outsidemhgender', loadChildren: () => import('./reports/statisticalReports/outsidemhgenderrpt.module').then(m => m.OutsidemhgenderrptModule) },
      { path: 'castesubcastegender', loadChildren: () => import('./reports/statisticalReports/caste-subcaste-stat-rpt.module').then(m => m.CasteSubcasteStatRptModule) },
      { path: 'studentminority', loadChildren: () => import('./reports/statisticalReports/studentminorityrpt.module').then(m => m.StudentminorityrptModule) },
      { path: 'categorywiserpt', loadChildren: () => import('./reports/statisticalReports/categorywisegenderrpt.module').then(m => m.CategorywisegenderrptModule) },
      { path: 'hstldayschlrwiserpt', loadChildren: () => import('./reports/statisticalReports/hostlerdayscholarcatewiserpt.module').then(m => m.HostlerdayscholarcatewiserptModule) },
      { path: 'studentpwdrpt', loadChildren: () => import('./reports/statisticalReports/studentpwdrpt.module').then(m => m.StudentpwdrptModule) },
      { path: 'ruralurbanwiserpt', loadChildren: () => import('./reports/statisticalReports/ruralurbancatewiserpt.module').then(m => m.RuralurbancatewiserptModule) },
      { path: 'stdadmncatewiserpt', loadChildren: () => import('./reports/statisticalReports/stdadmsncatewiserpt.module').then(m => m.StdadmsncatewiserptModule) },

      //ElogBook Reports
      { path: 'elogbookrpt', loadChildren: () => import('./reports/elogbookrpt/elogbookrpt.module').then(m => m.ElogbookrptModule) },
      { path: 'elogbookgeneralrpt', loadChildren: () => import('./reports/elogbookrpt/elogbookgeneralrpt.module').then(m => m.ElogbookgeneralrptModule) },

      //Digital Exam Reports
      { path: 'digitalexamrpt', loadChildren: () => import('./reports/digitalexamreports/digitalexamrpt.module').then(m => m.DigitalexamrptModule) },
      { path: 'digitalexamanalyticrpt', loadChildren: () => import('./reports/digitalexamreports/digitalexamanalyticrpt.module').then(m => m.DigitalexamanalyticrptModule) },

      // Hostel Module
      { path: 'blockmaster', loadChildren: () => import('./masters/blockbuildingfloormaster.module').then(m => m.BlockBuildingFloorMasterModule) },
      { path: 'blocklist', loadChildren: () => import('./masters/blockbuildingfloorlist.module').then(m => m.BlockBuildingFloorlistModule) },
      { path: 'hostelmaster', loadChildren: () => import('./masters/hostelmaster.module').then(m => m.HostelMasterModule) },
      { path: 'hostellist', loadChildren: () => import('./masters/hostellist.module').then(m => m.HostelListModule) },
      { path: 'hostelroommaster', loadChildren: () => import('./masters/hostelroommaster.module').then(m => m.HostelRoomMasterModule) },
      { path: 'bedmaster', loadChildren: () => import('./masters/bedmaster.module').then(m => m.BedMasterModule) },
      { path: 'wardenmapping', loadChildren: () => import('./hostel/wardenmapping.module').then(m => m.WardenMappingModule) },
      { path: 'hostelallotment', loadChildren: () => import('./hostel/hostelallotment.module').then(m => m.HostelAllotmentModule) },
      { path: 'hostelallotmentlist', loadChildren: () => import('./hostel/hostelallotmentlist.module').then(m => m.HostelAllotmentListModule) },
      { path: 'hosteldeallotment', loadChildren: () => import('./hostel/hosteldeallotment.module').then(m => m.HostelDeallotmentModule) },
      { path: 'roomchange', loadChildren: () => import('./hostel/roomchange.module').then(m => m.RoomChangeModule) },
      { path: 'migroomchange', loadChildren: () => import('./hostel/migroomchange.module').then(m => m.MigRoomChangeModule) },
      { path: 'hosteltariff', loadChildren: () => import('./hostel/hosteltariff.module').then(m => m.HostelTariffModule) },
      { path: 'hosteltarifflist', loadChildren: () => import('./hostel/hosteltarifflist.module').then(m => m.HostelTariffListModule) },
      { path: 'mighostelallotment', loadChildren: () => import('./hostel/mighostelallotment.module').then(m => m.MigHostelAllotmentModule) },
      { path: 'mighostelallotmentlist', loadChildren: () => import('./hostel/mighostelallotmentlist.module').then(m => m.MigHostelAllotmentListModule) },
      { path: 'termoverhostelallotment', loadChildren: () => import('./hostel/termoverhostelallotment.module').then(m => m.TermOverHostelAllotmentModule) },
      { path: 'termoverhostelallotmentlist', loadChildren: () => import('./hostel/termoverhostelallotmentlist.module').then(m => m.TermOverHostelAllotmentListModule) },
      { path: 'hostelattendencebywarden', loadChildren: () => import('./manageleave/hostelattendencebywarden.module').then(m => m.HostelattendencebywardenModule) },
      { path: 'stophostelnotifications', loadChildren: () => import('./hostel/stophostelnotifications.module').then(m => m.StophostelnotificationsModule) },
      { path: 'stophostelnotificationslist', loadChildren: () => import('./hostel/stophostelnotificationslist.module').then(m => m.StophostelnotificationslistModule) },
      { path: 'clienthostelmap', loadChildren: () => import('./hostel/clienthostelmapping.module').then(m => m.ClienthostelmappingModule) },

      // Pre Admission
      { path: "docverificationupload", loadChildren: () => import('./preadmission/docverificationupload.module').then(m => m.DocverificationuploadModule) },
      { path: "docverificationuploadlist", loadChildren: () => import('./preadmission/docverificationuploadlist.module').then(m => m.DocVerificationUploadListModule) },
      { path: 'docverificationattendance', loadChildren: () => import('./preadmission/docverattendance.module').then(m => m.DocVerAttendanceModule) },
      { path: 'docverificationattendancelist', loadChildren: () => import('./preadmission/docverattendancelist.module').then(m => m.DocVerAttendanceListModule) },
      { path: 'docverificationentry', loadChildren: () => import('./preadmission/docverificationentry.module').then(m => m.DocVerificationEntryModule) },
      { path: 'docverificationentrylist', loadChildren: () => import('./preadmission/docverentrylist.module').then(m => m.DocVerEntryListModule) },
      { path: 'predocumentverification', loadChildren: () => import('./preadmission/documentverification.module').then(m => m.DocumentverificationModule) },
      { path: 'predocumentverificationlist', loadChildren: () => import('./preadmission/documentverificationlist.module').then(m => m.DocumentVerificationListModule) },
      { path: 'predocumentverificationl2', loadChildren: () => import('./preadmission/documentverificationl2.module').then(m => m.DocumentVerificationL2Module) },
      { path: 'predocumentverificationl2list', loadChildren: () => import('./preadmission/documentverificationl2list.module').then(m => m.DocumentVerificationL2ListModule) },
      { path: 'predocumentverificationl3', loadChildren: () => import('./preadmission/documentverificationl3.module').then(m => m.DocumentVerificationL3Module) },
      { path: 'predocumentverificationl3list', loadChildren: () => import('./preadmission/documentverificationl3list.module').then(m => m.Documentverificationl3listModule) },
      { path: 'admissionpayment', loadChildren: () => import('./preadmission/admissionpayment.module').then(m => m.AdmissionpaymentModule) },
      { path: 'admissionpaymentlist', loadChildren: () => import('./preadmission/admissionpaymentlist.module').then(m => m.AdmissionPaymentListModule) },
      { path: 'provisionaladmission', loadChildren: () => import('./preadmission/provisionaladmission.module').then(m => m.ProvisionaladmissionModule) },
      { path: 'provisionaladmissionlist', loadChildren: () => import('./preadmission/provisionaladmissionlist.module').then(m => m.ProvisionalAdmissionListModule) },
      { path: 'counsellingnotification', loadChildren: () => import('./preadmission/counsellingnotification.module').then(m => m.CounsellingnotificationModule) },
      { path: 'counsellingnotificationlist', loadChildren: () => import('./preadmission/counsellingnotificationlist.module').then(m => m.CounsellingnotificationlistModule) },
      { path: 'preadmncancel', loadChildren: () => import('./preadmission/preadmissioncancellation.module').then(m => m.PreadmissioncancellationModule) },
      { path: 'preadmncancellist', loadChildren: () => import('./preadmission/preadmissioncancellationlist.module').then(m => m.PreadmissioncancellationlistModule) },
      { path: 'cetRecipctCancelation', loadChildren: () => import('./preadmission/cetrecepctcancelation.module').then(m => m.CetrecepctcancelationModule) },
      { path: 'cetrecipctcncllist', loadChildren: () => import('./preadmission/cetreceipctcancelationlist.module').then(m => m.CetreceipctcancelationlistModule) },
      { path: 'counsellingfeeposting', loadChildren: () => import('./preadmission/counsellingfeeposting.module').then(m => m.CounsellingfeepostingModule) },
      { path: 'counsellingcloser', loadChildren: () => import('./preadmission/counsellingcloser.module').then(m => m.CounsellingcloserModule) },
      { path: 'counsellingdataposting', loadChildren: () => import('./preadmission/counsellingdataposting.module').then(m => m.CounsellingdatapostingModule) },

      //Exam. Module
      { path: 'internalexamsch', loadChildren: () => import('./exam/internalexamschdule.module').then(m => m.InternalExamSchduleModule) },
      { path: 'internalexamschlist', loadChildren: () => import('./exam/internalexamschdulelist.module').then(m => m.InternalExamSchduleListModule) },
      { path: 'hallticketcfg', loadChildren: () => import('./exam/hallticket-config.module').then(m => m.HallticketConfigModule) },
      { path: 'hallticketcfglist', loadChildren: () => import('./exam/hallticket-configlist.module').then(m => m.HallticketConfiglistModule) },
      { path: 'examattendance', loadChildren: () => import('./exam/examattendance.module').then(m => m.ExamattendanceModule) },
      { path: 'examattendancelist', loadChildren: () => import('./exam/examattendancelist.module').then(m => m.ExamattendancelistModule) },
      { path: 'externalexamsch', loadChildren: () => import('./exam/externalexamschdule.module').then(m => m.ExternalExamSchduleModule) },
      { path: 'externalexamschcol', loadChildren: () => import('./exam/externalexamschdulecol.module').then(m => m.ExternalExamSchduleColModule) },
      { path: 'externalexamschlist', loadChildren: () => import('./exam/externalexamschdulelist.module').then(m => m.ExternalExamSchduleListModule) },
      { path: 'marksentry', loadChildren: () => import('./exam/marksentry.module').then(m => m.MarksentryModule) },
      { path: 'marksentryupload', loadChildren: () => import('./exam/marksentryupload.module').then(m => m.MarksentryUploadModule) },
      { path: 'marksverify', loadChildren: () => import('./exam/marksverify.module').then(m => m.MarksverifyModule) },
      { path: 'markspublish', loadChildren: () => import('./exam/markspublish.module').then(m => m.MarkspublishModule) },
      { path: 'examnotification', loadChildren: () => import('./exam/examnotification.module').then(m => m.ExamnotificationModule) },
      { path: 'examnotificationlist', loadChildren: () => import('./exam/examnotificationlist.module').then(m => m.ExamnotificationlistModule) },
      { path: 'examregapproval', loadChildren: () => import('./exam/examregistrationapproval.module').then(m => m.ExamregistrationapprovalModule) },
      { path: 'examregapprovallist', loadChildren: () => import('./exam/examregapprovallist.module').then(m => m.ExamapprovallistModule) },
      { path: 'exampannel', loadChildren: () => import('./exam/exampannel.module').then(m => m.ExampannelModule) },

      { path: 'seatingarrangement', loadChildren: () => import('./exam/seatingarrangement.module').then(m => m.SeatingarrangementModule) },
      { path: 'generatehallticket', loadChildren: () => import('./exam/generatehalltkt.module').then(m => m.GeneratehalltktModule) },
      { path: 'generatehallticketlist', loadChildren: () => import('./exam/generatehalltiktlist.module').then(m => m.GeneratehalltiktlistModule) },
      { path: 'centerinchargeassignment', loadChildren: () => import('./exam/cntrinchgeassgmt.module').then(m => m.CntrinchgeassgmtModule) },
      { path: 'centerinchargeassignmentlist', loadChildren: () => import('./exam/cntrinchgeassgmtlist.module').then(m => m.CntrinchgeassgmtlistModule) },

      { path: 'squadassignment', loadChildren: () => import('./exam/squadassignment.module').then(m => m.SquadassignmentModule) },
      { path: 'nominalrolls', loadChildren: () => import('./exam/nominalrolls.module').then(m => m.NominalrollsModule) },

      { path: 'evaluatorassignment', loadChildren: () => import('./exam/evaluatorassignment.module').then(m => m.EvaluatorassignmentModule) },
      { path: 'evaluatorassignmentlist', loadChildren: () => import('./exam/evaluatorassignmentlist.module').then(m => m.EvaluatorassignmentlistModule) },
      { path: 'invigilatorreplacement', loadChildren: () => import('./exam/invigilatorreplacement.module').then(m => m.InvigilatorreplacementModule) },
      { path: 'paperbarcoding', loadChildren: () => import('./exam/paperbarcoding.module').then(m => m.PaperbarcodingModule) },
      { path: 'bundling', loadChildren: () => import('./exam/bundling.module').then(m => m.BundlingModule) },
      { path: 'bundleassignment', loadChildren: () => import('./exam/bundleassignment.module').then(m => m.BundleassignmentModule) },
      { path: 'bundlingcollection', loadChildren: () => import('./exam/bundlecollection.module').then(m => m.BundlecollectionModule) },
      { path: 'certificatedocumentmapp', loadChildren: () => import('./exam/certificatedocumentmapping.module').then(m => m.CertificatedocumentmappingModule) },
      { path: 'provcertficatereq', loadChildren: () => import('./exam/provisionalcertificaterequest.module').then(m => m.ProvisionalcertificaterequestModule) },
      { path: 'provcertficatereqlist', loadChildren: () => import('./exam/provisionalcertificaterequestlist.module').then(m => m.ProvisionalcertificaterequestlistModule) },
      { path: 'provcertficateapproval', loadChildren: () => import('./exam/provisionalcertificateapproval.module').then(m => m.ProvisionalcertificateapprovalModule) },
      { path: 'provcertficateissue', loadChildren: () => import('./exam/provisionalcertificateissueissue.module').then(m => m.ProvisionalcertificateissueissueModule) },
      { path: 'odrequest', loadChildren: () => import('./exam/odrequest.module').then(m => m.OdrequestModule) },
      { path: 'odrequestlist', loadChildren: () => import('./exam/odrequestlist.module').then(m => m.OdrequestlistModule) },
      { path: 'odapproval', loadChildren: () => import('./exam/odapproval.module').then(m => m.OdapprovalModule) },
      { path: 'odissue', loadChildren: () => import('./exam/odissue.module').then(m => m.OdissueModule) },
      { path: 'halltktprint', loadChildren: () => import('./exam/hallticketprint.module').then(m => m.HallticketprintModule) },
      { path: 'onlinetest', loadChildren: () => import('./exam/onlineexam.module').then(m => m.OnlineexamModule) },
      { path: 'onlineexamlist', loadChildren: () => import('./exam/onlineexamlist.module').then(m => m.OnlineexamlistModule) },
      { path: 'onlinetestv2', loadChildren: () => import('./exam/onlineexamv2.module').then(m => m.Onlineexamv2Module) },
      { path: 'onlineexamlistv2', loadChildren: () => import('./exam/onlineexamv2list.module').then(m => m.Onlineexamv2listModule) },
      { path: 'hostelleaveapproval', loadChildren: () => import('./manageleave/hostelleaveapproval.module').then(m => m.LeaveapprovalModule) },
      { path: 'hostelleaveapprovallist', loadChildren: () => import('./manageleave/hostelleaveapprovallist.module').then(m => m.LeaveapprovallistModule) },
      { path: 'manualonlineevaluation', loadChildren: () => import('./portals/faculty/manualonlineevaluation.module').then(m => m.ManualonlineevaluationModule) },
      { path: 'manualexamevaluation', loadChildren: () => import('./portals/faculty/manualexamavaluation.module').then(m => m.ManualexamavaluationModule) },

      //Exam Module Version@2
      { path: 'coursefacultymappv2', loadChildren: () => import('./exam/coursefacultymapping.module').then(m => m.CoursefacultymappingModule) },
      { path: 'examstudentmappv2', loadChildren: () => import('./exam/examstudentmapping.module').then(m => m.ExamstudentmappingModule) },
      { path: 'examstudmapplistv2', loadChildren: () => import('./exam/examstudentmappinglist.module').then(m => m.ExamstudentmappinglistModule) },
      { path: 'examfeetariffmappingv2', loadChildren: () => import('./exam/examfeetariffmappingv2.module').then(m => m.Examfeetariffmappingv2Module) },

      // { path: 'examfeeheadv2', loadChildren: () => import('./exam/examfeeheadmasterv2.module').then(m => m.Examfeeheadmasterv2Module) },
      { path: 'examtariffv2', loadChildren: () => import('./exam/examfeetariffv2.module').then(m => m.Examfeetariffv2Module) },
      { path: 'examtarifflistv2', loadChildren: () => import('./exam/examfeetarifflistv2.module').then(m => m.Examfeetarifflistv2Module) },
      { path: 'coursemastersetupv2', loadChildren: () => import('./exam/examinationmastersetupv2.module').then(m => m.Examinationmastersetupv2Module) },
      { path: 'coursemastersetupv2list', loadChildren: () => import('./exam/examinationmastersetupv2list.module').then(m => m.Examinationmastersetupv2listModule) },

      { path: 'examfeeheadmaster', loadChildren: () => import('./exam/examfeeheadmasterv2.module').then(m => m.Examfeeheadmasterv2Module) },
      { path: 'examfeeheadmasterlist', loadChildren: () => import('./exam/examfeeheadmasterv2list.module').then(m => m.Examfeeheadmasterv2listModule) },
      { path: 'examschedulepostingv2', loadChildren: () => import('./exam/examscheduleposting.module').then(m => m.ExamschedulepostingModule) },
      { path: 'examschedulepostinglistv2', loadChildren: () => import('./exam/examschedulepostinglist.module').then(m => m.ExamschedulepostinglistModule) },
      { path: 'coursepromotionschedulev2', loadChildren: () => import('./exam/coursepromostionschedule.module').then(m => m.CoursepromostionscheduleModule) },
      { path: 'coursepromotionschedulelistv2', loadChildren: () => import('./exam/coursepromotionschedulelist.module').then(m => m.CoursepromotionschedulelistModule) },
      { path: 'seatnogenrationv2', loadChildren: () => import('./exam/seatnumbergenerationv2.module').then(m => m.Seatnumbergenerationv2Module) },

      { path: 'externalemployeeregistrationv2', loadChildren: () => import('./exam/externalempreg.module').then(m => m.ExternalempregModule) },
      { path: 'externalemployeeregistrationlistv2', loadChildren: () => import('./exam/externalempreglist.module').then(m => m.ExternalempreglistModule) },

      { path: 'examregapprovalv2', loadChildren: () => import('./exam/examregistrationapprovalv2.module').then(m => m.Examregistrationapprovalv2Module) },
      { path: 'exampannelsv2', loadChildren: () => import('./exam/exampanelsv2.module').then(m => m.Exampanelsv2Module) },
      { path: 'examinationseatingv2', loadChildren: () => import('./exam/examseatings.module').then(m => m.ExamseatingsModule) },
      { path: 'examregapprovalv2', loadChildren: () => import('./exam/examregistrationapprovalv2.module').then(m => m.Examregistrationapprovalv2Module) },

      { path: 'examregapprovalv2', loadChildren: () => import('./exam/examregistrationapprovalv2.module').then(m => m.Examregistrationapprovalv2Module) },
      { path: 'examtimetablev2', loadChildren: () => import('./exam/examtimetablev2.module').then(m => m.Examtimetablev2Module) },
      { path: 'examtimetablelistv2', loadChildren: () => import('./exam/examtimetablelistv2.module').then(m => m.Examtimetablelistv2Module) },
      { path: 'printhallticketv2', loadChildren: () => import('./exam/printhallticket.module').then(m => m.PrinthallticketModule) },
      { path: 'examinationquespaperv2', loadChildren: () => import('./exam/examinationquespaper.module').then(m => m.ExaminationquespaperModule) },
      { path: 'examinationquespaperv2list', loadChildren: () => import('./exam/examinationquespaperlist.module').then(m => m.ExaminationquespaperlistModule) },
      { path: 'examroleassignementv2', loadChildren: () => import('./exam/examroleassingmentv2.module').then(m => m.Examroleassingmentv2Module) },
      { path: 'nominalrollv2', loadChildren: () => import('./exam/examnominalrollsv2.module').then(m => m.Examnominalrollsv2Module) },
      { path: 'examattendancev2', loadChildren: () => import('./exam/examattendancev2.module').then(m => m.Examattendancev2Module) },
      { path: 'centerincsummaryV2', loadChildren: () => import('./exam/centerinchargesummaryv2.module').then(m => m.Centerinchargesummaryv2Module) },
      { path: 'exambundlingv2', loadChildren: () => import('./exam/exambundlingv2.module').then(m => m.Exambundlingv2Module) },
      { path: 'exambundlecollectionV2', loadChildren: () => import('./exam/exambndlecllctnv2.module').then(m => m.Exambndlecllctnv2Module) },
      { path: 'exambundleassignmentV2', loadChildren: () => import('./exam/exambndleassgnmntv2.module').then(m => m.Exambndleassgnmntv2Module) },

      //  {path:'batchwiseexammapv2',loadChildren:()=>import('./exam/batchwiseexamgrouping.module').then(m=>m.BatchwiseexamgroupingModule)},

      { path: 'batchwiseexammapv2', loadChildren: () => import('./exam/batchwiseexamgrouping.module').then(m => m.BatchwiseexamgroupingModule) },
      { path: 'batchwisegroupv2', loadChildren: () => import('./exam/batchwiseseatnogroupsv2.module').then(m => m.Batchwiseseatnogroupsv2Module) },
      { path: 'batchwisegroupv2list', loadChildren: () => import('./exam/batchwiseseatnogroupsv2list.module').then(m => m.Batchwiseseatnogroupsv2listModule) },

      { path: 'batchwiseexammapv2', loadChildren: () => import('./exam/batchwiseexamgrouping.module').then(m => m.BatchwiseexamgroupingModule) },

      { path: 'batchwiseexammapv2', loadChildren: () => import('./exam/batchwiseexamgrouping.module').then(m => m.BatchwiseexamgroupingModule) },
      { path: 'ansbookletuploadv2', loadChildren: () => import('./exam/answerbookletupload.module').then(m => m.AnswerbookletuploadModule) },
      { path: 'examregbyadmnv2', loadChildren: () => import('./exam/examregistrationbyadmnv2.module').then(m => m.Examregistrationbyadmnv2Module) },
      { path: 'marksentryv2', loadChildren: () => import('./exam/studentmarksentryuploadv2.module').then(m => m.Studentmarksentryuploadv2Module) },
      { path: 'marksverifyv2', loadChildren: () => import('./exam/studentmarksverifyv2.module').then(m => m.Studentmarksverifyv2Module) },
      { path: 'resultpreparev2', loadChildren: () => import('./exam/resultsprepartion.module').then(m => m.ResultsprepartionModule) },
      { path: 'resultverifyv2', loadChildren: () => import('./exam/resultsverificationv2.module').then(m => m.Resultsverificationv2Module) },
      { path: 'resultdeclarev2', loadChildren: () => import('./exam/resultsdeclarationv2.module').then(m => m.Resultsdeclarationv2Module) },

      { path: 'timetableautomation', loadChildren: () => import('./exam/timetableautomation.module').then(m => m.TimetableautomationModule) },


      //ComSetting
      { path: 'comsetting', loadChildren: () => import('./comsetting/comsetting.module').then(m => m.ComsettingModule) },
      { path: 'schsms', loadChildren: () => import('./comsetting/schedulesmssender.module').then(m => m.SchedulesmssenderModule) },
      // { path: 'comsettingv1', loadChildren: () => import('./comsetting/comv2.module').then(m => m.Comv2Module) },

      // { path: 'comsettingv1', loadChildren: () => import('./comsetting/comv2.module').then(m => m.Comv2Module) },

      //Feed Back Modules
      { path: 'questionnairecatgory', loadChildren: () => import('./Feedback/questionarycatgory.module').then(m => m.QuestionaryModule) },
      { path: 'targetaudience', loadChildren: () => import('./Feedback/targetaudience.module').then(m => m.TargetaudienceModule) },
      { path: 'cataudmapping', loadChildren: () => import('./Feedback/cataudmapping.module').then(m => m.CataudmappingModule) },
      { path: 'questionbank', loadChildren: () => import('./Feedback/questionbank.module').then(m => m.QuestionbankModule) },
      { path: 'questionbanklist', loadChildren: () => import('./Feedback/questionbanklist.module').then(m => m.QuestionbanklistModule) },
      { path: 'questionnairecatgorylist', loadChildren: () => import('./Feedback/questionarycatgorylist.module').then(m => m.QuestionarycatgorylistModule) },
      { path: 'targetaudiencelist', loadChildren: () => import('./Feedback/targetaudiencelist.module').then(m => m.TargetaudiencelistModule) },
      { path: 'feedbackpost', loadChildren: () => import('./Feedback/feedbackpost.module').then(m => m.FeedbackpostModule) },
      { path: 'feedbackpostlist', loadChildren: () => import('./Feedback/feedbackpostlist.module').then(m => m.FeedbacklistModule) },

      { path: 'feedbackpostV2', loadChildren: () => import('./Feedback/feedbackpostv2.module').then(m => m.Feedbackpostv2Module) },
      { path: 'feedbackpostV2list', loadChildren: () => import('./Feedback/feedbackpostv2list.module').then(m => m.Feedbackpostv2listModule) },

      // Certificates Module
      { path: 'certificateprint', loadChildren: () => import('./certificates/certificateprint.module').then(m => m.CertificateprintModule) },
      { path: 'certificateprintv2', loadChildren: () => import('./certificates_V2/certificateprint.module').then(m => m.CertificateprintModule) },

      // Visitors Module
      { path: 'visitorsin', loadChildren: () => import('./visitors/visitorpassin.module').then(m => m.VisitorpassinModule) },
      { path: 'visitorsout', loadChildren: () => import('./visitors/visitorpassout.module').then(m => m.VisitorpassoutModule) },
      { path: 'visitorsinlist', loadChildren: () => import('./visitors/visitorpassinlist.module').then(m => m.VisitorpassinlistModule) },
      { path: 'visitorsoutlist', loadChildren: () => import('./visitors/visitorpassoutlist.module').then(m => m.VisitorpassoutlistModule) },
      { path: 'employeevisitorpass', loadChildren: () => import('./visitors/employeevisitorpass.module').then(m => m.EmployeevisitorpassModule) },
      { path: 'employeevisitorpasslist', loadChildren: () => import('./visitors/employeevisitorpasslist.module').then(m => m.EmployeevisitorpasslistModule) },
      { path: 'plant', loadChildren: () => import('./visitors/plant.module').then(m => m.PlantModule) },
      { path: 'plantlist', loadChildren: () => import('./visitors/plantlist.module').then(m => m.PlantlistModule) },

      //Library
      { path: 'membershiprole', loadChildren: () => import('./library/membershiprole.module').then(m => m.MembershiproleModule) },
      { path: 'membershiprolelist', loadChildren: () => import('./library/membershiprolelist.module').then(m => m.MembershiprolelistModule) },
      { path: 'membershipcreation', loadChildren: () => import('./library/membershipcreation.module').then(m => m.MembershipcreationModule) },
      { path: 'membershipcreationlist', loadChildren: () => import('./library/membershipcreationlist.module').then(m => m.MembershipcreationlistModule) },
      { path: 'librarybookmasterlist', loadChildren: () => import('./library/libbookmasterlist.module').then(m => m.LibbookmasterlistModule) },
      { path: 'librarybookmaster', loadChildren: () => import('./library/libbookmaster.module').then(m => m.LibbookmasterModule) },

      { path: 'centrallibrarymaster', loadChildren: () => import('./library/centralibrarymaster.module').then(m => m.CentralibrarymasterModule) },
      { path: 'centrallibrarymasterlist', loadChildren: () => import('./library/centralibrarymasterlist.module').then(m => m.CentralibrarymasterlistModule) },
      { path: 'librarybookdistributions', loadChildren: () => import('./library/bookdistributionmaster.module').then(m => m.BookdistributionmasterModule) },
      { path: 'librarysuppliermaster', loadChildren: () => import('./library/suppliermaster.module').then(m => m.SuppliermasterModule) },
      { path: 'librarysupplierlist', loadChildren: () => import('./library/suppliermasterlist.module').then(m => m.SuppliermasterlistModule) },

      { path: 'bookissue', loadChildren: () => import('./library/bookissue.module').then(m => m.BookissueModule) },
      { path: 'bookissuelist', loadChildren: () => import('./library/bookissuelist.module').then(m => m.BookissuelistModule) },
      { path: 'bookreturn', loadChildren: () => import('./library/bookreturn.module').then(m => m.BookreturnModule) },
      { path: 'bookreturnv2', loadChildren: () => import('./library/libbkrtnv2.module').then(m => m.Libbkrtnv2Module) },
      //elearning
      { path: 'elearningdocuments', loadChildren: () => import('./elearning/elearning.module').then(m => m.ElearningModule) },
      { path: 'elearningdocumentslist', loadChildren: () => import('./elearning/elearninglist.module').then(m => m.ElearninglistModule) },
      { path: 'elearningdocumentsv2', loadChildren: () => import('./elearning/elearningv2.module').then(m => m.Elearningv2Module) },
      { path: 'elearningdocumentslistv2', loadChildren: () => import('./elearning/elearningv2list.module').then(m => m.Elearningv2listModule) },
      { path: 'libraryeditormaster', loadChildren: () => import('./library/editormaster.module').then(m => m.EditormasterModule) },
      { path: 'libraryeditorlist', loadChildren: () => import('./library/editormasterlist.module').then(m => m.EditormasterlistModule) },
      { path: 'libraryauthormaster', loadChildren: () => import('./library/authormaster.module').then(m => m.AuthormasterModule) },
      { path: 'libraryauthorlist', loadChildren: () => import('./library/authormasterlist.module').then(m => m.AuthormasterlistModule) },
      { path: 'librarypublishermaster', loadChildren: () => import('./library/publishermaster.module').then(m => m.PublishermasterModule) },
      { path: 'librarypublisherlist', loadChildren: () => import('./library/publishermasterlist.module').then(m => m.PublishermasterlistModule) },
      { path: 'libfeereciptsettings', loadChildren: () => import('./library/libraryfeerecieptsettings.module').then(m => m.LibraryfeerecieptsettingsModule) },
      { path: 'libfeecollection', loadChildren: () => import('./library/libraryfeecollection.module').then(m => m.LibraryfeecollectionModule) },
      { path: 'libfeecollectionlist', loadChildren: () => import('./library/libraryfeecollectionlist.module').then(m => m.LibraryfeecollectionlistModule) },

      { path: 'stockverification', loadChildren: () => import('./library/stockverification.module').then(m => m.StockverificationModule) },

      { path: 'lostbookreplace', loadChildren: () => import('./library/lostbookreplacement.module').then(m => m.LostbookreplacementModule) },
      { path: 'bookissuev2', loadChildren: () => import('./library/bookissusev2.module').then(m => m.Bookissusev2Module) },
      { path: 'rackmaster', loadChildren: () => import('./library/rackmaster.module').then(m => m.RackmasterModule) },
      { path: 'shelfmaster', loadChildren: () => import('./library/shelfmaster.module').then(m => m.ShelfmasterModule) },
      { path: 'bookarrangement', loadChildren: () => import('./library/bookarrangement.module').then(m => m.BookarrangementModule) },
      //noc
      { path: 'noccertificatete', loadChildren: () => import('./nodue/noctemplate.module').then(m => m.NoctemplateModule) },
      { path: 'noccertificatetelist', loadChildren: () => import('./nodue/noctemplatelist.module').then(m => m.NoctemplatelistModule) },

      { path: 'noctemplatemappinglist', loadChildren: () => import('./nodue/noctemplatemappinglist.module').then(m => m.NoctemplatemappinglistModule) },
      { path: 'noctemplatemapping', loadChildren: () => import('./nodue/noctemplatemapping.module').then(m => m.NoctemplatemappingModule) },
      { path: 'nodueauthorization', loadChildren: () => import('./nodue/nodueauthorization.module').then(m => m.NodueauthorizationModule) },
      { path: 'nodueapproval', loadChildren: () => import('./nodue/nodueapproval.module').then(m => m.NodueapprovalModule) },
      { path: 'nodueapplyforadmin', loadChildren: () => import('./portals/student/nodueapply.module').then(m => m.NodueapplyModule) },
      { path: 'noctemplatemappinglistv2', loadChildren: () => import('./nodue/noctempmappngv2list.module').then(m => m.Noctempmappngv2listModule) },
      { path: 'noctemplatemappingv2', loadChildren: () => import('./nodue/noctempmappngv2.module').then(m => m.Noctempmappngv2Module) },

      //Alumini
      { path: 'aluminicreation', loadChildren: () => import('./alumini/alumini.module').then(m => m.AluminiModule) },
      { path: 'aluminicreationlist', loadChildren: () => import('./alumini/aluminilist.module').then(m => m.AluminilistModule) },
      { path: 'aluminicreationV2', loadChildren: () => import('./alumini/aluminiv2.module').then(m => m.Aluminiv2Module) },
      { path: 'aluminicreationlistV2', loadChildren: () => import('./alumini/aluminiv2list.module').then(m => m.Aluminiv2listModule) },
      //Notification
      { path: 'notificationposting', loadChildren: () => import('./notification/notificationposting.module').then(m => m.NotificationpostingModule) },
      { path: 'notificationpostinglist', loadChildren: () => import('./notification/notificationpostinglist.module').then(m => m.NotificationpostinglistModule) },
      { path: 'notificationpostingv2', loadChildren: () => import('./notification/ntfnpstngv2.module').then(m => m.Ntfnpstngv2Module) },
      { path: 'notificationpostinglistv2', loadChildren: () => import('./notification/ntfnpstngv2list.module').then(m => m.Ntfnpstngv2listModule) },
      //transport
      { path: 'routemap', loadChildren: () => import('./transport/routemap.module').then(m => m.RoutemapModule) },
      { path: 'routemaplist', loadChildren: () => import('./transport/routemaplist.module').then(m => m.RoutemaplistModule) },
      { path: 'vehicalmaster', loadChildren: () => import('./transport/vehicalmaster.module').then(m => m.VehicalmasterModule) },
      { path: 'vehicalmasterlist', loadChildren: () => import('./transport/vehicalmasterlist.module').then(m => m.VehicalmasterlistModule) },
      { path: 'driverattendance', loadChildren: () => import('./transport/driverattendancemaster.module').then(m => m.DriverattendancemasterModule) },
      { path: 'driverattendetlist', loadChildren: () => import('./transport/driverattendermasterlist.module').then(m => m.DriverattendermasterlistModule) },
      { path: 'vehiclesch', loadChildren: () => import('./transport/vehicalscheduling.module').then(m => m.VehicalschedulingModule) },
      { path: 'vehicleschlist', loadChildren: () => import('./transport/vehicalschedulinglist.module').then(m => m.VehicalschedulinglistModule) },
      { path: 'transporttarif', loadChildren: () => import('./transport/transporttarif.module').then(m => m.TransporttarifModule) },
      { path: 'transportallocation', loadChildren: () => import('./transport/transportallocation.module').then(m => m.TransportallocationModule) },
      { path: 'transportallocationlist', loadChildren: () => import('./transport/transportallocationlist.module').then(m => m.TransportallocationlistModule) },

      //Awards 
      { path: 'awarsmaster', loadChildren: () => import('./awards/awardsmaster.module').then(m => m.AwardsmasterModule) },
      { path: 'awardslist', loadChildren: () => import('./awards/awardslist.module').then(m => m.AwardslistModule) },
      { path: 'awardentry', loadChildren: () => import('./awards/awardsentry.module').then(m => m.AwardsentryModule) },
      { path: 'awardentryv2', loadChildren: () => import('./awards/awardentryv2.module').then(m => m.Awardentryv2Module) },
      { path: 'awardentrylistv2', loadChildren: () => import('./awards/awardentrylistv2.module').then(m => m.Awardentrylistv2Module) },
      { path: 'awardentrylist', loadChildren: () => import('./awards/awardsentrylist.module').then(m => m.AwardsentrylistModule) },
      //Disciplinary
      { path: 'disciplinaryaction', loadChildren: () => import('./diciplinary/disciplinaryaction.module').then(m => m.DisciplinaryactionModule) },
      { path: 'diciplinaryactionlist', loadChildren: () => import('./diciplinary/disciplinaryactionlist.module').then(m => m.DisciplinaryactionlistModule) },
      { path: 'disciplinaryactionapproval', loadChildren: () => import('./diciplinary/disciplinaryactionapproval.module').then(m => m.DisciplinaryactionapprovalModule) },
      { path: 'disciplinaryactionapprovallist', loadChildren: () => import('./diciplinary/disciplinaryactionapprovallist.module').then(m => m.DisciplinaryactionapprovallistModule) },

      { path: 'disciplineactv2', loadChildren: () => import('./diciplinary/disciplinaryactionv2.module').then(m => m.Disciplinaryactionv2Module) },
      { path: 'disciplineactapprovalv2', loadChildren: () => import('./diciplinary/disciplinaryactionapprovalv2.module').then(m => m.Disciplinaryactionapprovalv2Module) },

      { path: 'facilitiesmaster', loadChildren: () => import('./facilitybkngs/facilitiesmaster.module').then(m => m.FacilitiesmasterModule) },
      { path: 'facilitiesmasterlist', loadChildren: () => import('./facilitybkngs/facilitiesmasterlist.module').then(m => m.FacilitiesmasterlistModule) },
      { path: 'facilitestariff', loadChildren: () => import('./facilitybkngs/facilitytariff.module').then(m => m.FacilitytariffModule) },
      { path: 'facltsctgry', loadChildren: () => import('./facilitybkngs/facilitycategory.module').then(m => m.FacilitycategoryModule) },
      { path: 'facltsctgrylist', loadChildren: () => import('./facilitybkngs/facilitycategorylist.module').then(m => m.FacilitycategorylistModule) },
      { path: 'facilitybooking', loadChildren: () => import('./facilitybkngs/facilitybooking.module').then(m => m.FacilitybookingModule) },
      { path: 'facilitybookinglist', loadChildren: () => import('./facilitybkngs/facilitybookinglist.module').then(m => m.FacilitybookinglistModule) },

      { path: 'facilitypaymentlist', loadChildren: () => import('./facilitybkngs/facilitypaymentlist.module').then(m => m.FacilitypaymentlistModule) },
      { path: 'facilitypayment', loadChildren: () => import('./facilitybkngs/facltypayment.module').then(m => m.FacltypaymentModule) },

      { path: 'facilityrefundlist', loadChildren: () => import('./facilitybkngs/facilityrefundlist.module').then(m => m.FacilityrefundlistModule) },
      { path: 'facilityrefund', loadChildren: () => import('./facilitybkngs/facltyrefund.module').then(m => m.FacltyrefundModule) },

      { path: 'facilitycheckinlist', loadChildren: () => import('./facilitybkngs/facilitycheckinlist.module').then(m => m.FacilitycheckinlistModule) },
      { path: 'facilitycheckin', loadChildren: () => import('./facilitybkngs/facltycheckin.module').then(m => m.FacltycheckinModule) },

      { path: 'facilitycheckoutlist', loadChildren: () => import('./facilitybkngs/facilitycheckoutlist.module').then(m => m.FacilitycheckoutlistModule) },
      { path: 'facilitycheckout', loadChildren: () => import('./facilitybkngs/facltycheckout.module').then(m => m.FacltycheckoutModule) },
      { path: 'bookingnogenerate', loadChildren: () => import('./facilitybkngs/bkngcodegenrte.module').then(m => m.BkngcodegenrteModule) },

      //Question Bank 
      { path: 'quebank', loadChildren: () => import('./qbank/questionbank.module').then(m => m.QuestionbankModule) },
      { path: 'quebanklist', loadChildren: () => import('./qbank/questionbanklist.module').then(m => m.QuestionbanklistModule) },
      //leave apply on warden 
      { path: 'leaveapplyonwarden', loadChildren: () => import('./portals/student/leaveapply.module').then(m => m.LeaveapplyModule) },

      { path: 'generateleaveseq', loadChildren: () => import('./manageleave/generateleavesequence.module').then(m => m.GenerateleavesequenceModule) },

      // Exxam Module v3

      { path: 'coursereg', loadChildren: () => import('./exam_V3/pre-conduct/coursereg.module').then(m => m.CourseregModule) },
      { path: 'courseregulationmaster', loadChildren: () => import('./exam_V3/pre-conduct/courseregulationmaster.module').then(m => m.CourseregulationmasterModule) },
      { path: 'courseregulationmasterlist', loadChildren: () => import('./exam_V3/pre-conduct/courseregulationmasterlist.module').then(m => m.CourseregulationmasterlistModule) },

      { path: 'courseregulationmap', loadChildren: () => import('./exam_V3/pre-conduct/courseregulationmap.module').then(m => m.CourseregulationmapModule) },
      { path: 'evaluationschunivv3', loadChildren: () => import('./exam_V3/pre-conduct/evaluationschmev3.module').then(m => m.Evaluationschmev3Module) },
      { path: 'evaluationschunivv3list', loadChildren: () => import('./exam_V3/pre-conduct/evaluationschmev3list.module').then(m => m.Evaluationschmev3listModule) },
      { path: 'evaluationschclgv3', loadChildren: () => import('./exam_V3/pre-conduct/collegeevaluationschmev3.module').then(m => m.Collegeevaluationschmev3Module) },
      { path: 'examfeetariffv3', loadChildren: () => import('./exam_V3/pre-conduct/examfeetariffv3.module').then(m => m.Examfeetariffv3Module) },
      { path: 'examfeetarifflistv3', loadChildren: () => import('./exam_V3/pre-conduct/examfeetarifflistv3.module').then(m => m.Examfeetarifflistv3Module) },
      { path: 'studentexamregistrationv3', loadChildren: () => import('./exam_V3/pre-conduct/studentexamregistrationv3.module').then(m => m.Studentexamregistrationv3Module) },
      { path: 'studentexamregistrationlistv3', loadChildren: () => import('./exam_V3/pre-conduct/studentexamregistrationv3list.module').then(m => m.Studentexamregistrationv3listModule) },
      { path: 'examnotificationv3', loadChildren: () => import('./exam_V3/pre-conduct/examnotificationv3.module').then(m => m.Examnotificationv3Module) },
      { path: 'examnotificationv3list', loadChildren: () => import('./exam_V3/pre-conduct/examnotificationv3list.module').then(m => m.Examnotificationv3listModule) },

      { path: 'hallticketpatternv3', loadChildren: () => import('./exam_V3/pre-conduct/hallticketpatternv3.module').then(m => m.Hallticketpatternv3Module) },

      { path: 'hallticketpatternv3list', loadChildren: () => import('./exam_V3/pre-conduct/hallticketpatternv3list.module').then(m => m.Hallticketpatternv3listModule) },
      //  { path: 'studentgroupingpraticalv3', loadChildren: () => import('./exam_V3/pre-conduct/studentgroupingpraticalv3.module').then(m => m.Studentgroupingpraticalv3Module) },

      { path: 'examregapprovalv3', loadChildren: () => import('./exam_V3/pre-conduct/examregistrationapprovalv3.module').then(m => m.Examregistrationapprovalv3Module) },
      { path: 'examregapprovalv3list', loadChildren: () => import('./exam_V3/pre-conduct/examregistrationapprovallistv3.module').then(m => m.Examregistrationapprovallistv3Module) },
      { path: 'externalempregv3', loadChildren: () => import('./exam_V3/pre-conduct/externalemployeeregistrationv3.module').then(m => m.Externalemployeeregistrationv3Module) },
      { path: 'externalempregv3list', loadChildren: () => import('./exam_V3/pre-conduct/externalemployeeregistrationv3list.module').then(m => m.Externalemployeeregistrationv3listModule) },

      { path: 'exampanelv3', loadChildren: () => import('./exam_V3/pre-conduct/exampanelv3.module').then(m => m.Exampanelv3Module) },
      { path: 'examroleassignv3', loadChildren: () => import('./exam_V3/pre-conduct/examroleassignmentv3.module').then(m => m.Examroleassignmentv3Module) },

      { path: 'generatehallticketv3', loadChildren: () => import('./exam_V3/pre-conduct/generatehallticketv3.module').then(m => m.Generatehallticketv3Module) },
      { path: 'generatehallticketListv3', loadChildren: () => import('./exam_V3/pre-conduct/generatehallticketlistv3.module').then(m => m.Generatehallticketlistv3Module) },


      { path: 'printhallticketv3', loadChildren: () => import('./exam_V3/pre-conduct/printhallticketv3.module').then(m => m.Printhallticketv3Module) },
      { path: 'examtimetablev3', loadChildren: () => import('./exam_V3/pre-conduct/examtimetablev3.module').then(m => m.Examtimetablev3Module) },
      { path: 'qstnpapertemplatev3List', loadChildren: () => import('./exam_V3/pre-conduct/qstn-paper-tmplt-list.module').then(m => m.QstnPaperTmpltListModule) },
      { path: 'qstnpapertemplatev3', loadChildren: () => import('./exam_V3/pre-conduct/qstn-paper-tmplt.module').then(m => m.QstnPaperTmpltModule) },
      { path: 'quespaperverifishortlist', loadChildren: () => import('./exam_V3/pre-conduct/quespaperverficationandshortlistv3.module').then(m => m.Quespaperverficationandshortlistv3Module) },

      { path: 'moderatorassignmentv3', loadChildren: () => import('./exam_V3/pre-conduct/modrtrassgnmntv3.module').then(m => m.Modrtrassgnmntv3Module) },
      { path: 'moderatorassignmentv3List', loadChildren: () => import('./exam_V3/pre-conduct/modrtrassnlist.module').then(m => m.ModrtrassnlistModule) },


      { path: 'stdntgrpngpractclsv3', loadChildren: () => import('./exam_V3/pre-conduct/stdgrpngprctclsv3.module').then(m => m.Stdgrpngprctclsv3Module) },

      { path: 'stdntgrpngmppingpractclsv3', loadChildren: () => import('./exam_V3/pre-conduct/stdgrpngmppngprctclsv3.module').then(m => m.Stdgrpngmppngprctclsv3Module) },
      { path: 'finalqstnpaperv3', loadChildren: () => import('./exam_V3/pre-conduct/finalquestionpaperforexamv3.module').then(m => m.Finalquestionpaperforexamv3Module) },

      { path: 'paperbarcodingv3', loadChildren: () => import('./exam_V3/pre-conduct/paperbarcodingv3.module').then(m => m.Paperbarcodingv3Module) },
      { path: 'quepaperv3print', loadChildren: () => import('./exam_V3/pre-conduct/quepaperv3print.module').then(m => m.Quepaperv3printModule) },
      { path: 'attendanceschv3', loadChildren: () => import('./exam_V3/pre-conduct/attendanceschemav3.module').then(m => m.Attendanceschemav3Module) },

      ///Exam Conduct Module
      { path: 'seatingarrgv3', loadChildren: () => import('./exam_V3/conduct/seatingarrangementv3.module').then(m => m.Seatingarrangementv3Module) },
      { path: 'squadassignmentv3', loadChildren: () => import('./exam_V3/conduct/squadassignementv3.module').then(m => m.Squadassignementv3Module) },
      { path: 'nominalrollsv3', loadChildren: () => import('./exam_V3/conduct/nominalrollsv3.module').then(m => m.Nominalrollsv3Module) },
      { path: 'examattendencev3', loadChildren: () => import('./exam_V3/conduct/examattendencev3.module').then(m => m.Examattendencev3Module) },
      { path: 'invgltrassnmntv3', loadChildren: () => import('./exam_V3/conduct/invigilatorassgnmntv3.module').then(m => m.Invigilatorassgnmntv3Module) },
      { path: 'centreinchargeassgnmntv3', loadChildren: () => import('./exam_V3/conduct/centreinchargeasnmntv3.module').then(m => m.Centreinchargeasnmntv3Module) },
      // post conduct
      { path: 'ansbookletuploadv3', loadChildren: () => import('./exam_V3/post-conduct/answerbookletuploadv3.module').then(m => m.Answerbookletuploadv3Module) },
      { path: 'ansbkltbundlingv3', loadChildren: () => import('./exam_V3/post-conduct/bundlingv3.module').then(m => m.Bundlingv3Module) },
      { path: 'paperbundlingassgnmntv3', loadChildren: () => import('./exam_V3/post-conduct/bundlingassnmtv3.module').then(m => m.Bundlingassnmtv3Module) },
      { path: 'restultpreparationv3', loadChildren: () => import('./exam_V3/post-conduct/rsltprprtnv3.module').then(m => m.Rsltprprtnv3Module) },
      { path: 'restultverificationv3', loadChildren: () => import('./exam_V3/post-conduct/rsltvrfctnv3.module').then(m => m.Rsltvrfctnv3Module) },

      //{ path: 'paperevaluationv3', loadChildren: () => import('./exam_V3/post-conduct/paperevaluationv3.module').then(m => m.Paperevaluationv3Module) },
      { path: 'bundelscollectionv3', loadChildren: () => import('./exam_V3/post-conduct/budlescollectionv3.module').then(m => m.Budlescollectionv3Module) },
      // { path: 'onlineevaluationv3', loadChildren: () => import('./exam_V3/post-conduct/onlineevaluationv3.module').then(m => m.Onlineevaluationv3Module) },
      { path: 'marksentryv3', loadChildren: () => import('./exam_V3/post-conduct/marksentryv3.module').then(m => m.Marksentryv3Module) },
      { path: 'marksverifyv3', loadChildren: () => import('./exam_V3/post-conduct/marksverifyv3.module').then(m => m.Marksverifyv3Module) },
      { path: 'notificationcloser', loadChildren: () => import('./exam_V3/post-conduct/notificationcloser.module').then(m => m.NotificationcloserModule) },
      { path: 'resultdeclarationv3', loadChildren: () => import('./exam_V3/pre-conduct/resultdeclarationv3.module').then(m => m.Resultdeclarationv3Module) },
      { path: 'updatehallticketnov3', loadChildren: () => import('./exam_V3/post-conduct/updatehallticketnov3.module').then(m => m.Updatehallticketnov3Module) },
      { path: 'internalexamtimetablev3', loadChildren: () => import('./exam_V3/pre-conduct/internalexamtimetablev3.module').then(m => m.Internalexamtimetablev3Module) },
      { path: 'internalmarksentryv3', loadChildren: () => import('./exam_V3/pre-conduct/internalmarksentryv3.module').then(m => m.Internalmarksentryv3Module) },
      { path: 'internalfinalmarksentryv3', loadChildren: () => import('./exam_V3/pre-conduct/internalfinalmarksentryv3.module').then(m => m.Internalfinalmarksentryv3Module) },
      { path: 'marksentryclgv3', loadChildren: () => import('./exam_V3/post-conduct/mkentryextv3.module').then(m => m.Mkentryextv3Module) },
      { path: 'marksvrfyclgv3', loadChildren: () => import('./exam_V3/post-conduct/mkvrfyextv3.module').then(m => m.Mkvrfyextv3Module) },
      { path: 'markspblshclgv3', loadChildren: () => import('./exam_V3/post-conduct/mkpblshv3.module').then(m => m.Mkpblshv3Module) },
      { path: 'intrnlfnlmkentryv3', loadChildren: () => import('./exam_V3/post-conduct/intrnlfnlmksentryv3.module').then(m => m.Intrnlfnlmksentryv3Module) },

      { path: 'deviceregistration', loadChildren: () => import('./studAttendance/deviceregistration.module').then(m => m.DeviceregistrationModule) },
      { path: 'deviceregistrationlist', loadChildren: () => import('./studAttendance/deviceregistrationlist.module').then(m => m.DeviceregistrationlistModule) },
      { path: 'biometriccapture', loadChildren: () => import('./studAttendance/biometriccapture.module').then(m => m.BiometriccaptureModule) },
      { path: 'biometriccapturelist', loadChildren: () => import('./studAttendance/biometriccapturelist.module').then(m => m.BiometriccapturelistModule) },
      { path: 'devicefacultymapping', loadChildren: () => import('./studAttendance/devicefacultymapping.module').then(m => m.DevicefacultymappingModule) },
      { path: 'devicefacultymappinglist', loadChildren: () => import('./studAttendance/devicefacultymappinglist.module').then(m => m.DevicefacultymappinglistModule) },

      { path: 'studentelogbook', loadChildren: () => import('../app/studentelogbook/studentelogbook.module').then(m => m.StudentelogbookModule) },
      { path: 'studentlogbook', loadChildren: () => import('../app/studentelogbook/studentlogbook.module').then(m => m.StudentlogbookModule) },
      { path: 'coursedepmap', loadChildren: () => import('../app/studentelogbook/coursedepmap.module').then(m => m.CoursedepmapModule) },
      { path: 'departmentskillmap', loadChildren: () => import('../app/studentelogbook/departmentskillmap.module').then(m => m.DepartmentskillmapModule) },
      { path: 'departmentskillcolmap', loadChildren: () => import('../app/studentelogbook/departmentskillcolmap.module').then(m => m.DepartmentskillcolmapModule) },


      // BELOW PATH SHOULD BE THE LAST ONE IN ROUTES. ANY NEW ROUTES TO BE CREATED ABOVE THIS ONLY.
      { path: 'testcomponents', loadChildren: () => import('./templates/testcomponents.module').then(m => m.TestcomponentsModule) },


      { path: '**', redirectTo: `login;param=eyJpc0ZvcnNlTG9nb3V0Ijp0cnVlfQ==` },

      //Application Helpline Desk
      { path: 'helpdesk', loadChildren: () => import('../app/helplinedesk/helplinedesk.module').then(m => m.HelplinedeskModule) },
    ],
  },
  // BELOW PATH SHOULD BE THE LAST ONE IN ROUTES. ANY NEW ROUTES TO BE CREATED ABOVE THIS ONLY.
  { path: '**', redirectTo: `login;param=eyJpc0ZvcnNlTG9nb3V0Ijp0cnVlfQ==` },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }