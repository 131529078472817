import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'EduKares';

  @HostListener('document:keyup', ['$event'])
  async onkeyup(event: KeyboardEvent) {
    if (event.keyCode == 44) {
      var aux = await document.createElement("input");
      await aux.setAttribute('value', '.');
      await document.body.appendChild(aux);
      await aux.select();
      await document.execCommand('copy');
      await document.body.removeChild(aux);
    }
  }

  @HostListener('window:focus', ['$event'])
  onfocus(event: any) {
    let element: HTMLElement = document.getElementsByTagName('div')[0] as HTMLElement;
    element.click();
  }
}
