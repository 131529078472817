import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[autoFocus]'
})
export class AutofocusDirective implements OnInit {

  private _autofocus: boolean = false;
  constructor(private _elRef: ElementRef) { }

  ngOnInit() {
    if (this._autofocus || typeof this._autofocus === "undefined")
      this._elRef.nativeElement.focus()
  }

  @Input() set autoFocus(condition: boolean) {
    this._autofocus = condition != false;
  }
}