import { Component, Input, Self, Output, EventEmitter, Optional, OnInit } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 's-inputtext',
  templateUrl: './inputtext.component.html'
})
export class InputTextComponent implements ControlValueAccessor, OnInit {

  @Input() controlId: string = "";
  @Input() errorMessage: string = "";
  @Input() cssClass: string = "";
  @Input() autoFocus: boolean = false;
  @Input() keyFilter: RegExp = /^[^]+$/;
  @Input() maxLength: string = "1000";
  @Input() inputModel: any = "";
  @Input() iconClass: string = "error-icon";
  @Input() inputType: string = "text";
  @Input() isMandatory: boolean = false;
  @Input() isLoginPage: boolean = false;
  @Input() placeholder = "";
  @Input() isLargeInput = false;
  @Input() tooltipText = "";
  @Input() autocomplete = 'off';
  @Input() tooltipPosition = "right";
  @Input() allowNegativeValues = false;

  public disabledMe: boolean = false;
  public markMandatory: boolean = false;

  @Input() set disabled(condition: boolean) {
    if (this.ngControl != null) {
      const action = condition ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
    else {
      this.disabledMe = condition;
    }
  };

  @Output() public onBlur = new EventEmitter();
  @Output() public onChange = new EventEmitter();
  @Output() inputModelChange = new EventEmitter<string>();

  constructor(
    @Optional()
    @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.markMandatory = this.isMandatory;
  }

  writeValue(obj: any) { }
  registerOnChange(fn: any) { };
  registerOnTouched(fn: any) { };
  setDisableState?(isDisabled: boolean) { }

  onLeaveControl() {
    if (this.ngControl != null) {
      this.markMandatory = (this.isMandatory && (this.ngControl.control.value == null || this.ngControl.control.value == ""));
    }
    else {
      this.markMandatory = (this.isMandatory && (this.inputModel == null || this.inputModel == ""));
    }
    this.modelChange();
    this.onBlur.emit(true);
  }

  onTextChange() {
    this.onChange.emit(true);
  }

  modelChange() {
    if (this.ngControl != null) {
      if (this.keyFilter.toString().toLowerCase() == 'pint' || this.keyFilter.toString().toLowerCase() == 'pnum') {
        if (isNaN((this.ngControl.control.value || "").toString()))
          this.ngControl.control.setValue("");
        else if (this.allowNegativeValues == false && parseFloat(this.ngControl.control.value) < 0)
          this.ngControl.control.setValue("");
        else if (parseFloat(this.ngControl.control.value) == 0)
          this.ngControl.control.setValue("0");
        else if ((this.ngControl.control.value || "") != "" && parseFloat((this.ngControl.control.value || "").charAt(0)) == 0)
          this.ngControl.control.setValue(parseFloat(this.ngControl.control.value));
      }
    }
    else {
      if (this.keyFilter.toString().toLowerCase() == 'pint' || this.keyFilter.toString().toLowerCase() == 'pnum') {
        if (isNaN((this.inputModel || "").toString()))
          this.inputModel = "";
        else if (this.allowNegativeValues == false && parseFloat(this.inputModel) < 0)
          this.inputModel = "";
        else if (parseFloat(this.inputModel) == 0)
          this.inputModel = "0";
        else if ((this.inputModel || "") != "" && parseFloat((this.inputModel || "").charAt(0)) == 0)
          this.inputModel = parseFloat(this.inputModel);
      }
    }
    this.inputModelChange.emit(this.inputModel);
  }
}