import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MastersService } from '../../services/masters.service';
import * as _ from 'lodash';
import { Validators, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

@Component({
  selector: 'app-studhome',
  templateUrl: './studhome.component.html',
  styleUrls: ['./studhome.component.css']
})
export class StudhomeComponent implements OnInit {
  public UserInfo: any = {};
  public IsUserLoggedIn = false;
  public UserMenu: any = [];
  public isMenuShow = [];
  public userName = "";
  public menuList = [];
  public loginLocation = "";
  public pwdExpDays = 0;
  public pwdClosable = true;
  public showChatIcon = true;
  public chatApplicable = false;
  public showIdleTime = false;
  public loginExpTime = "";
  public isChceckNewPwd = false;
  public clientName = "EduKares";
  public clientLogo = "default_logo.png";

  public windowHeight;
  public windowWidth;
  public chatIconPosition;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  }

  @HostListener('document:keyup', ['$event'])
  async onkeyup(event: KeyboardEvent) {
    if (event.keyCode == 44) {
      var aux = await document.createElement("input");
      await aux.setAttribute('value', '.');
      await document.body.appendChild(aux);
      await aux.select();
      await document.execCommand('copy');
      await document.body.removeChild(aux);
    }
  }

  @HostListener('window:focus', ['$event'])
  onfocus(event: any) {
    let element: HTMLElement = document.getElementsByTagName('div')[0] as HTMLElement;
    element.click();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    window.localStorage.setItem("wc", "Y");
  }

  public inBounds = true;
  public edge = { top: true, bottom: true, left: true, right: true };

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _loginService: LoginService,
    private _masterService: MastersService,
    private messageService: MessageService,
    private _fb: FormBuilder,
    private idleTime: Idle
  ) {
    this._router.events.subscribe((event) => {
      this.getScreenSize();
      if (event instanceof NavigationEnd) {
        this.chatIconPosition = { x: this.windowWidth - 55, y: this.windowHeight - 55 };
      }
    });
  }

  idleTimeReset() {
    this.showIdleTime = false;
    this.idleTime.watch();
  }

  async ngOnInit() {
    this.showChatIcon = true;
    await this._loginService.getConfigData();
    await this._loginService.setMasterApiUrl("loginApi");

    let refreshtme = await this._masterService.SystemNotificationRefreshTime() *1000 ;
    this.getSysNotifications();
    setInterval(() => {
      this.getSysNotifications();
    }, (refreshtme) || 300000);

    this.loginLocation = this._masterService.getUserInfo("location_name");
    this.userName = this._masterService.getUserInfo("display_name").toString();
    this.pwdExpDays = parseFloat(this._masterService.getUserInfo("pwd_exprd_in_days") || 0);
    this.changePwd = (this.pwdExpDays <= 0 ? true : false);
    if (this.pwdExpDays <= 0) await this.onChangepwd();
    this.pwdClosable = (this.pwdExpDays <= 0 ? false : true);
    let params = {
      parUserId: this._masterService.getUserInfo("user_id"),
      parRoleId: this._masterService.getUserInfo("role_id")
    };


    this.userName = this._masterService.getUserInfo("display_name").toString();
    let userPermissions = await this._loginService.getUserPermissions("userPermissionsUrl", params)
    this.menuList = this._masterService.getDecryptData(userPermissions.info);
    _.forEach(this.menuList, (menu, index) => {
      menu.documentName = _.trim(menu.documentName.split('(')[0]);
      menu.metaData = { moduleId: menu.moduleId, subModuleId: menu.subModuleId, docId: menu.docId };
      menu.displayOrder = 99999;
      switch (menu.pageUrl) {
        // Student / Parent
        case "studentprofile": menu.displayOrder = 1; break;
        case "studentcourse": menu.displayOrder = 2; break;
        case "studenttimetable": menu.displayOrder = 3; break;
        case "studentfeestructure": menu.displayOrder = 4; break;
        case "studentfeepayments": menu.displayOrder = 5; break;
        case "studentfeereceipts": menu.displayOrder = 6; break;
        case "studentledger": menu.displayOrder = 7; break;
        case "studentattendance": menu.displayOrder = 8; break;
        case "studentexamreg": menu.displayOrder = 101; break;
        case "studentexamregv2": menu.displayOrder = 102; break;
        case "studentexamschedule": menu.displayOrder = 103; break;
        case "studentexamresults": menu.displayOrder = 104; break;
        case "chatmessenger": this.chatApplicable = true; break;
        // Faculty
        case "facultyprofile": menu.displayOrder = 1; break;
        case "facultytimetable": menu.displayOrder = 2; break;
        case "facultymarkattendance": menu.displayOrder = 3; break;
        case "elearningdocuments": menu.displayOrder = 4; break;
        case "facultymarksentry": menu.displayOrder = 101; break;
        case "facultymarksverify": menu.displayOrder = 102; break;
        case "facultymarkspublish": menu.displayOrder = 103; break;
      }
    });
    this.menuList = _.sortBy(this.menuList, ['displayOrder', 'documentName']);

    window.localStorage.setItem("modDocAccess", this._masterService.getEncryptData(JSON.stringify(this.menuList)));

    this.IsUserLoggedIn = (window.localStorage.getItem("IsLoggedIn") == null || window.localStorage.getItem("IsLoggedIn") == undefined ? false : true);

    if (window.localStorage.getItem("GoHome") == "Y") {
      let thisUseTypeId = this._masterService.getUserInfo('user_type_id');
      let navigateTo = '';
      switch (thisUseTypeId) {
        case 2: navigateTo = 'studentdashboard'; break;
        case 3: navigateTo = 'parentdashboard'; break;
        case 6: navigateTo = 'facultydashboard'; break;
        default: navigateTo = 'parentdashboard'; break;
      }
      this._router.navigate([navigateTo], { relativeTo: this._activatedRoute });
      window.localStorage.removeItem("GoHome");
    }

    let thisUseTypeId = this._masterService.getUserInfo('user_type_id');
    switch (thisUseTypeId) {
      case 2:
        _.forEach(this.menuList, async (menuItmVl, menuIn) => {
          menuItmVl.show = true;
          switch (menuItmVl.pageUrl) {
            case 'studentfeedback':
              menuItmVl.show = false;
              break;
            case 'chatmessenger':
              menuItmVl.show = false;
              break;
            case 'studentdashboard':
              menuItmVl.show = false
          }
        });
        break;
    }

    setInterval(() => {
      let thisRoute = this._router.url;
      this.showChatIcon = (thisRoute.toLowerCase().indexOf('chatmessenger') >= 0 ? false : true);
      _.forEach(this.menuList, (menuItmVl, menuIn) => {
        menuItmVl.show = true;
        switch (menuItmVl.pageUrl) {
          case 'studentfeedback':
            menuItmVl.show = ((window.localStorage.getItem('FeedBackRspnse') || "") == "" ? false : true);
            break;
        }
      });
    }, 1000);

    setInterval(
      () => {
        window.localStorage.removeItem("wc");
      }, 100
    );

    //////  APPLICATION IDLE TIME CHECK START
    let idleTime = this._masterService.getIdleTime();
    if (idleTime.required == "Y") {
      this.idleTime.setIdle(parseFloat(idleTime.idleTime)); // Idle Time should be in seconds
      this.idleTime.setTimeout(parseFloat(idleTime.warningTime)); // Timeout should be in seconds
      this.idleTime.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      this.idleTime.onInterrupt.subscribe(() => {
        this.showIdleTime = false;
      });

      this.idleTime.onTimeout.subscribe(async () => {
        await this.onLogoutClick();
      });

      this.idleTime.onTimeoutWarning.subscribe(seconds => {
        this.showIdleTime = true;
        this.loginExpTime = `${(Math.floor(seconds / 60)).toString().padStart(2, '0')} Min. ${(Math.floor(seconds % 60)).toString().padStart(2, '0')} Sec.`;
      });

      this.idleTimeReset();
    }
    //////  APPLICATION IDLE TIME CHECK END
  }

  async navigateToMenu(pageUrl, metaData, clickSource) {
    if (pageUrl != "") {
      let param = btoa(JSON.stringify(metaData));
      switch (pageUrl) {
        case "studentonlinetest":
          let thiStudInfo = window.localStorage.getItem("stdInfo");
          thiStudInfo = this._masterService.getDecryptData(thiStudInfo)[0];
          let onLineTestCheck = await this._masterService.getLookupData("portalsApi", "studentOnlineChckurl", {
            parAdmnId: thiStudInfo['admnId'],
            parFlag: 'dashboard',
          });
          if (onLineTestCheck[0]['Status'] == 204) {
            this._router.navigateByUrl('shome/studonlinetesthistory');
          } else {
            this._router.navigate([pageUrl, { docParams: param }], { relativeTo: this._activatedRoute });
          }
          break;
        default:
          this._router.navigate([pageUrl, { docParams: param }], { relativeTo: this._activatedRoute });
          break;
      }
    }
    let element: HTMLElement = document.getElementsByTagName('div')[0] as HTMLElement;
    element.click();
    if (clickSource != "TOP") {
      let element2: HTMLElement = document.getElementById('azIconbarShow') as HTMLElement;
      element2.click();
    }
  }

  onModuleViewClick() {
    let thisUseTypeId = this._masterService.getUserInfo('user_type_id');
    let navigateTo = '';
    switch (thisUseTypeId) {
      case 2: navigateTo = 'studentdashboard'; break;
      case 3: navigateTo = 'parentdashboard'; break;
      case 6: navigateTo = 'facultydashboard'; break;
      default: navigateTo = 'parentdashboard'; break;
    }
    this._router.navigate([navigateTo], { relativeTo: this._activatedRoute });
  }

  async onLogoutClick() {
    let params = {
      parRelogin: "N"
    };
    let newLocLoginInfo = await this._masterService.getLookupData("loginApi", "userSignoutUrl", params);
    this._router.navigate(['/login']);
    window.localStorage.setItem("UserInfo", null);
    window.localStorage.removeItem("UserInfo");
    window.localStorage.setItem("modDocAccess", null);
    window.localStorage.removeItem("modDocAccess");
    window.localStorage.setItem("UserLoc", null);
    window.localStorage.removeItem("UserLoc");
    window.localStorage.setItem("stdInfo", null);
    window.localStorage.removeItem("stdInfo");
  }

  public blockUI = false;
  public formSubmitted = false;
  public loginchangeErrMsg = {};
  public pwdStngPlcs: any;
  public title = "Change Password"
  public toastMessage = "";
  public showReq = false;
  public saveCode;

  public minLength;
  public spclChrs;
  public uppCs;
  public lwrCs;
  public minNm;
  public minLengthFlag = false;
  public spclChrsFlag = false;
  public uppCsFlag = false;
  public lwrCsFlag = false;
  public minNmFlag = false;
  public validNewPwd = false;
  public changePwd: boolean;
  public isShowSbmit = false;
  public errorMessages: any = {
    "changepassword": "",
    "changeNewPassword": "",
    "changeConformPassword": ""
  };

  public changePwdForm = this._fb.group({
    parUserId: [],
    parOldPassword: ['', Validators.required],
    parNewPassword: ['', Validators.required],
    parNewConfPassword: ['', Validators.required]
  });
  get thisForm() {
    return this.changePwdForm.controls;
  }

  getErrormessage(ctrl, showToast) {
    switch (ctrl) {
      case "parOldPassword":
        this.errorMessages.changepassword = this.validateThisctrl(ctrl, "changepasswordreq", showToast);
        break;
      case "parNewPassword":
        this.errorMessages.changeNewPassword = this.validateThisctrl(ctrl, "changeNewPasswordreq", showToast);
        break;
      case "parNewConfPassword":
        this.errorMessages.changeConformPassword = this.validateThisctrl(ctrl, "changeConformPasswordreq", showToast);
        break;
    }
  }

  validateThisctrl(ctrl: string, errorUserAttr, showToast: boolean) {
    let errorMessage = ((this.formSubmitted || this.thisForm[ctrl].touched) && this.thisForm[ctrl].errors ? this.loginchangeErrMsg[errorUserAttr] : "");

    if (ctrl == "parNewPassword" && errorMessage == "") {
      errorMessage = (this.validNewPwd ? "" : "Password is not as per the policy settings");
      errorMessage = (this.thisForm[ctrl].value != this.thisForm["parOldPassword"].value) ? '' : "Old Password & New Password should be Different "
    }
    if (ctrl == "parNewConfPassword" && errorMessage == "") {
      if (this.thisForm[ctrl].value != '') {
        if (this.thisForm[ctrl].value == this.thisForm["parNewPassword"].value) {
          errorMessage = "";
          this.isShowSbmit = true;
        } else {
          errorMessage = "Confirm Password not matching with New Password";
          this.isShowSbmit = false;
        }
      } else {
        errorMessage = this.loginchangeErrMsg["changeConformPasswordreq"];
        this.isShowSbmit = true;
      }
    }
    if (showToast && errorMessage != '') {
      this.messageService.add({ severity: 'warn', summary: 'Login', detail: errorMessage });
    }
    return errorMessage
  }

  async onChangepwd() {
    this.loginchangeErrMsg = this._masterService.getErrorMessages("changePasswordErrMsg");

    this.pwdStngPlcs = await this._masterService.getLookupData("passwordPolicySettingsApi", "getAllPasswordUrL", { "parFlag": "G" });
    this.minLength = this.pwdStngPlcs[0]["passwPolicyMinLen"];
    this.spclChrs = this.pwdStngPlcs[0]["passwPolicySpecialCharLen"];
    this.uppCs = this.pwdStngPlcs[0]["passwPolicyMinCapitalLettersLen"];
    this.lwrCs = this.pwdStngPlcs[0]["passwPolicyMinSmallLettersLen"];
    this.minNm = this.pwdStngPlcs[0]["passwPolicyDigitsMinLen"];

    this.minLengthFlag = false;
    this.spclChrsFlag = false;
    this.uppCsFlag = false;
    this.lwrCsFlag = false;
    this.minNmFlag = false;
    this.validNewPwd = false;

    this.changePwdForm.reset();
    this.errorMessages = {
      "changepassword": "",
      "changeNewPassword": "",
      "changeConformPassword": ""
    };

    this.changePwd = true;
    this.isChceckNewPwd = false;
  }

  async onChangePwdClick() {
    this.formSubmitted = true
    let pssdFields = Object.keys(this.changePwdForm.controls);
    _.forEach(pssdFields, (pwdVal, pwdIn) => {
      this.getErrormessage(pwdVal, false);
    });
    let isErrorExists = this._masterService.showErrorSummary(this.errorMessages);
    if (isErrorExists) { return; }

    let thisFormData = JSON.parse(JSON.stringify(this.changePwdForm.getRawValue()));
    thisFormData.parUserId = this._masterService.getUserInfo("user_id");
    delete thisFormData.parNewConfPassword;
    let parSessionId = this._masterService.getUserInfo("last_sessionid")

    this.blockUI = true;
    await this._masterService.setMasterApiUrl("loginApi");
    var saveResult = await this._masterService.insertUpdateMaster("userCahngePwdUrl", thisFormData);
    this.showReq = true;
    this.saveCode = saveResult[0].errorCd;

    if (saveResult.length > 0) {
      if (saveResult[0].errorCd == "0") {
        this.toastMessage = "Password changed successfully";
        setTimeout(() => {
          this.onLogoutClick()
        }, 1500);
      }
      if (saveResult[0].errorCd == "0000") {
        this.toastMessage = "Old password not matching, please check again";
        this.isShowSbmit = false;
      }
    }
    else {
      this.toastMessage = "Unable to update password";
    }
    await this.onChangepwd();

    this.blockUI = false;
    this.changePwdForm.reset();
  }

  validateSettings(event) {
    let newPwd = this.changePwdForm.getRawValue()["parNewPassword"];

    let numUCase = (newPwd.match(/[A-Z]/g) || []).length;
    let numLCase = (newPwd.match(/[a-z]/g) || []).length;
    let numNumbers = (newPwd.match(/[0-9]/g) || []).length;
    let numSpclChar = (newPwd.match(/[@#$%^&*()_{}\[\]><.?]/g) || []).length;

    this.minLengthFlag = (newPwd.length >= this.minLength);
    this.uppCsFlag = (numUCase >= this.uppCs);
    this.lwrCsFlag = (numLCase >= this.lwrCs);
    this.minNmFlag = (numNumbers >= this.minNm);
    this.spclChrsFlag = (numSpclChar >= this.spclChrs);
    this.validNewPwd = (this.minLengthFlag && this.uppCsFlag && this.lwrCsFlag && this.minNmFlag && this.spclChrsFlag);
    this.isChceckNewPwd = (this.validNewPwd ? false : true);
  }

  navigateToChat() {
    this._router.navigate(["chatmessenger"], { relativeTo: this._activatedRoute });
  };

  public oldIsInputText = 'password';
  public newIsInputText = 'password';
  public confirmIsInputText = 'password';
  public oldShowEye = 'fa fa-eye new-user-icon ';
  public newShowEye = 'fa fa-eye new-user-icon ';
  public confirmShowEye = 'fa fa-eye new-user-icon ';
  public ctrlPass = 'on';
  onClickSeePassword(ont, ctrl, toggles) {
    switch (ctrl) {
      case "old":
        if (this.ctrlPass == 'on') {
          this.oldShowEye = 'fa fa-eye-slash new-user-icon ';
          this.oldIsInputText = 'text';
          this.ctrlPass = 'off';
        } else if (this.ctrlPass == 'off') {
          this.oldShowEye = 'fa fa-eye new-user-icon ';
          this.oldIsInputText = 'password';
          this.ctrlPass = 'on';
        }
        break;
      case "new":
        if (this.ctrlPass == 'on') {
          this.newShowEye = 'fa fa-eye-slash new-user-icon ';
          this.newIsInputText = 'text';
          this.ctrlPass = 'off';
        } else if (this.ctrlPass == 'off') {
          this.newShowEye = 'fa fa-eye new-user-icon ';
          this.newIsInputText = 'password';
          this.ctrlPass = 'on';
        }
        break;
      case "cnfrm":
        if (this.ctrlPass == 'on') {
          this.confirmShowEye = 'fa fa-eye-slash new-user-icon ';
          this.confirmIsInputText = 'text';
          this.ctrlPass = 'off';
        } else if (this.ctrlPass == 'off') {
          this.confirmShowEye = 'fa fa-eye new-user-icon ';
          this.confirmIsInputText = 'password';
          this.ctrlPass = 'on';
        }
        break;
    };
  };

  checkEdge(event) {
    this.edge = event;
  }

  public reminders: any = {
    parDate: new Date(),
    "isReminderEventSlctd": "upcoming"
  };
  public isNotifications = false;
  async getNotifications() {}
  public notif_dt: any = new Date();
  async getSysNotifications() {
    this.sysNotifiactions = [];
    
      let labelList: any = await this._masterService.getLookupData('systemNotification', 'getComMsgMessage',
        {
          "parMsgMethodOfCommunicationId": "3",
          "parFlag": 'sys_notification',
          "parMsgReferenceId": this._masterService.getUserId()
        });


        _.forEach(labelList, (eValue, eIndex) => {
          eValue['sentDate'] = this.getTimeFormat(eValue['createDt']);
          this.sysNotifiactions.push(eValue);
        })
        if (labelList[0]['Status'] == '204' || labelList[0]['Status'] == '999') {
          this.sysNotifiactions = [];
        }
      // this.isBlockUI = false;
  };
  public newDate = new Date();
  public sysNotifiactions = [];
  public notificationMsgContent: any = "";

  async onClickNotifications() {
    this.reminders = {
      parDate: new Date(),
      "isReminderEventSlctd": "upcoming"
    };
    this.isNotifications = true;
    await this.getNotifications()
    this.notif_dt = new Date();
    await this.getSysNotifications();
  }

  async onUpdate(ind){
    await this._masterService.setMasterApiUrl("systemNotification");
    let postData = {
      "parComMsgMessage" : [{
        "message_id" : this.sysNotifiactions[ind]['messageId'],
        "msg_status" : "Y"
      }]
    };
    var saveResult = await this._masterService.insertUpdateMaster("updateComMsgMessage", postData);
    await this.getSysNotifications();
  }
  async onUpdateall(ind){
    await this._masterService.setMasterApiUrl("systemNotification");
    
    let postData = {}
    _.filter(this.sysNotifiactions,(msgr,msgri)=>{
      let pData = {
        "message_id" :  msgr['messageId'],
        "msg_status" : "Y"
      }
      postData['parComMsgMessage'].push(pData)
    })


    var saveResult = await this._masterService.insertUpdateMaster("updateComMsgMessage", postData);
    await this.getSysNotifications();
  }

  getTimeFormat(gdate){
    let givenDate = new Date(gdate);
    let currentDate = new Date();
 
    const diffInMilliseconds = currentDate.getTime() - givenDate.getTime();
    const diffInSec = Math.floor(diffInMilliseconds / (1000));
    if(diffInSec<60){
      return `${diffInSec} sec ago`
    }
    const diffInMinutes = Math.floor(diffInSec/60)
    if(diffInMinutes<60){
     return `${diffInMinutes} min ago`
   }
   const diffInHours = Math.floor(diffInMinutes/60)
   if(givenDate.toDateString() === currentDate.toDateString()){
     return `${diffInHours} hr ago`
   }
 
   const formattedDate = `${givenDate.getDate().toString().padStart(2,'0')}/${(givenDate.getMonth()+1).toString().padStart(2,'0')}/${givenDate.getFullYear()} 
   ${givenDate.getHours()%12||12}:${givenDate.getMinutes().toString().padStart(2,'0')} ${givenDate.getHours()>=12 ? 'PM' : 'AM'}`
   return formattedDate
 }
 


}