<div class="page-content-wrapper">
  <div [ngSwitch]="clientShortName">
    <div class="pavara-login" *ngSwitchCase="'PIMS (DU)'">
      <div class="row mx-0 pavara-bg-img">
        <div class="login-form">
          <div class="login-form-content">
            <div class="text-center mb-3">
              <div class="login-logo ">
                <img src="../../assets/img/{{clientLogo}}" alt="" class="img-fluid">
              </div>
              <h4 class="">Edu<span>K</span>ares</h4>
            </div>
            <form [formGroup]="userForm" class="">
              <div class="form-group mb-3">
                <label for="userName" class="login-font">User Name</label>
                <div class="input-group" *ngIf=false>
                  <div class="input-group-prepend">
                    <div class="input-group-text srch-br-l px-2 py-0">
                      <span class="fa fa-user" style="color:#73a0cf"></span>
                    </div>
                  </div>
                </div>
                <div class="input-control">
                  <span class="fa fa-user login-icon" style="color:#73a0cf"></span>
                  <s-inputtext controlId="userName" [formControlName]="'parUserName'"
                    [cssClass]="'form-Control p-1 pl-30'" (onBlur)="getErrormessage('parUserName',true)"
                    errorMessage="{{errorMessages.userNameError}}" [isLoginPage]="true">
                  </s-inputtext>
                </div>
              </div>
              <div class="form-group mb-3">
                <label for="userPassword" class="login-font">Password</label>
                <div class="input-group" *ngIf=false>
                  <div class="input-group-prepend">
                    <div class="input-group-text srch-br-l px-2 py-0">
                      <span class="fa fa-lock" style="color:#73a0cf"></span>
                    </div>
                  </div>
                </div>
                <div class="input-control">
                  <span class="fa fa-lock login-icon" style="color:#73a0cf"></span>
                  <s-inputtext controlId="userPassword" [formControlName]="'parPassword'"
                    [cssClass]="'form-Control p-1 pl-30'" (onBlur)="getErrormessage('parPassword',true)"
                    errorMessage="{{errorMessages.userPasswordError}}" [inputType]="newIsInputText"
                    [isLoginPage]="true">
                  </s-inputtext>
                  <span class="{{newShowEye}}" *ngIf="thisForm.parPassword.valid"
                    (click)="onClickSeePassword('password', ctrlPass)" style="color:#73a0cf"></span>
                </div>
              </div>
              <div class="mb-1">
                <a href="javascript:return false" class="login-font pwd-forget forgot-pwd" (click)="showMobileApp()"
                  *ngIf="enableMobileApp">Download Mobile App</a>
                <a href="javascript:return false" class="float-right login-font pwd-forget mb-3 forgot-pwd"
                  (click)="onForgetpwd()" *ngIf="showForgotPwd">Forgot Password</a>
              </div>
              <button type="submit" class="btn btn-primary w-100 d-block login-btn"
                (click)='onLoginClick()'>Login</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="palenttimes-login" *ngSwitchDefault>
      <header class="login-header">
        <div class="header-content-info">
          <div class="login-header-logo">
            <div class="login-client-logo login-clientLogo">
              <img src="../../assets/img/{{clientLogo}}" class="img-fluid" alt="">
              <div class="login-clientName">{{clientName}}</div>
            </div>
          </div>
          <div class="login-server-address">
            <div class="d-flex">
              <div class="login-ip" *ngIf="false">
                <label class="mb-0">Your IP Address</label>
                <div>{{clientIP}}</div>
              </div>
              <div class="login-ip-server">
                <h3 class="mb-0">{{appInstance}}</h3>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="row mx-0 login-bg">
        <div class="col-md-6  col-lg-6  col-xl-4 mx-auto  ">
          <div class="login-form">
            <div class="login-form-content">
              <div class="text-center mb-3">
                <div class="login-logo ">
                  <img src="../../assets/img/EduKares_logo.jpeg" alt="" class="img-fluid">
                </div>
              </div>
              <form [formGroup]="userForm" class="">
                <div class="form-group mb-3">
                  <label for="userName" class="login-font">User Name</label>
                  <div class="input-group" *ngIf=false>
                    <div class="input-group-prepend">
                      <div class="input-group-text srch-br-l px-2 py-0">
                        <span class="fa fa-user" style="color:#73a0cf"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-control">
                    <span class="fa fa-user login-icon" style="color:#73a0cf"></span>
                    <s-inputtext controlId="userName" [formControlName]="'parUserName'"
                      [cssClass]="'form-Control p-1 pl-30'" (onBlur)="getErrormessage('parUserName',true)"
                      errorMessage="{{errorMessages.userNameError}}" [isLoginPage]="true">
                    </s-inputtext>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <label for="userPassword" class="login-font">Password</label>
                  <div class="input-group" *ngIf=false>
                    <div class="input-group-prepend">
                      <div class="input-group-text srch-br-l px-2 py-0">
                        <span class="fa fa-lock" style="color:#73a0cf"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-control">
                    <span class="fa fa-lock login-icon" style="color:#73a0cf"></span>
                    <s-inputtext controlId="userPassword" [formControlName]="'parPassword'" [inputType]="newIsInputText"
                      [cssClass]="'form-Control p-1 pl-30'" (onBlur)="getErrormessage('parPassword',true)"
                      errorMessage="{{errorMessages.userPasswordError}}" [isLoginPage]="true">
                    </s-inputtext>
                    <span class="{{newShowEye}}" *ngIf="thisForm.parPassword.valid"
                      (click)="onClickSeePassword('password', ctrlPass)" style="color:#73a0cf"></span>
                  </div>
                </div>
                <div class="mb-1">
                  <a href="javascript:return false" class="login-font pwd-forget forgot-pwd" (click)="showMobileApp()"
                    *ngIf="enableMobileApp">Download Mobile App</a>
                  <a href="javascript:return false" class="float-right login-font pwd-forget mb-3 forgot-pwd"
                    (click)="onForgetpwd()" *ngIf="showForgotPwd">Forgot Password</a>
                </div>
                <button type="submit" class="btn btn-primary w-100 d-block login-btn"
                  (click)='onLoginClick()'>Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="login-footer" *ngIf="showFooter">
    <div class="footer-content-info">
      <div class="copyright">
        <div class="copyright-text">Copyright <span class="company-name">Talenttimes (P) Ltd</span>. All Rights
          Reserved.</div>
      </div>
      <div class="login-footer-logo">
        <div class="login-suv-logo text-center">
          <span class="" style="display: none">Powered by</span>
          <img src="../../assets/img/talenttimes-logo.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>
  </footer>
  <p-toast [style]="{marginTop:'50px'}" position="top-right">
  </p-toast>

  <p-dialog header="Select Location" [(visible)]="displayModal" [modal]="true" [style]="{width:'35vw'}"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <div class="">
      <p-dropdown [(ngModel)]="selectedLocation" [filter]="false" [options]="locList" [style]="{width:'30vw'}">
      </p-dropdown>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="loginToLocation()" label="OK"></button>
      <button type="button" pButton icon="pi pi-times" (click)="displayModal=false" label="Cancel"></button>
    </p-footer>
  </p-dialog>

  <!-- Forgot Password -->
  <p-dialog header="Reset Password" [(visible)]="displayResetPwd" [modal]="true" [style]="{width:'25rem'}"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onShow)="resetCaptcha()">
    <div>
      <form class="">
        <div class="form-group mb-2">
          <div class="">
            <label for="userName" class="login-font w-auto text-nowrap mr-3">User Name</label>
            <div class="input-control w-100">
              <span class="fa fa-user login-icon" style="color:#73a0cf"></span>
              <s-inputtext controlId="userName" [(inputModel)]="resetForm.parUserName"
                [cssClass]="'form-Control p-1 pl-30'" (onBlur)="getErrormessage('reSetparUserName',true)"
                (onChange)="getErrormessage('reSetparUserName',true)" [isLoginPage]="true">
              </s-inputtext>
              <small class="text-danger font-weight-bold"> {{errorMessages.resetuserNameError}}</small>
            </div>
          </div>
          <div class="mt-3">
            <label for="userName" class="login-font w-auto text-nowrap mr-3">Enter Captcha</label>
            <div class="">
              <div class="captchaBox bg-white mb-1">
                <s-captcha (onRefresh)="onCapthaRefresh()"></s-captcha>
              </div>
              <s-inputtext [(inputModel)]="resetForm.parCaptcha" [controlId]="'captcha'" [maxLength]="6"
                [cssClass]="'form-control p-1'" [isMandatory]="true" (onBlur)="getErrormessage('parCaptcha', true)">
              </s-inputtext>
              <small class="text-danger font-weight-bold"> {{errorMessages.captchaError}}</small>
            </div>
          </div>
          <div class="text-center mt-3">
            <button type="submit" class="btn btn-primary login-btn" (click)='onResetPwdClick()'>Submit</button>
          </div>
          <small class="font-weight-bold" [ngClass]="resetPwdInfo.code == '0' ?'text-success':'text-danger'">
            {{resetPwdInfo.msg}}</small>
        </div>

      </form>
    </div>
  </p-dialog>
</div>
<p-dialog header="Change Password" [(visible)]="changePwd" [modal]="true" [style]="{width:'20vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" styleClass="forgot_dialog">
  <form class="m-2" [formGroup]="changePwdForm">
    <div class="form-group mb-1">
      <label for="changepassword" class="login-font">Old Password</label>
      <div class="input-control">
        <span class="fa fa-lock login-icon"></span>
        <s-inputtext [inputType]="oldIsInputText" controlId="changepassword" [cssClass]="'form-Control p-1 pl-30'"
          [isLoginPage]="true" [formControlName]="'parOldPassword'"
          (onBlur)="getPwdChangeErrormessage('parOldPassword',true)">
        </s-inputtext>
        <span class="" *ngIf="thisChangePwdForm.parOldPassword.valid"
          (click)="onClickSeePassword(thisChangePwdForm.parOldPassword.valid, 'old')"></span>
        <small class="text-danger font-weight-bold"> {{errorMessages.changepassword}}</small>
      </div>
    </div>
    <div class="form-group mb-1">
      <label for="changeNewPassword" class="login-font">New Password</label>
      <div class="input-control">
        <span class="fa fa-lock login-icon"></span>
        <s-inputtext [inputType]="newIsInputText" controlId="changeNewPassword" [cssClass]="'form-Control p-1 pl-30'"
          aria-autocomplete="off" [isLoginPage]="true" [formControlName]="'parNewPassword'"
          (onBlur)="getPwdChangeErrormessage('parNewPassword',true)" (onChange)="validateSettings($event)">
        </s-inputtext>

        <!-- <span class="{{newShowEye}}" (click)="onClickSeePassword(thisChangePwdForm.parNewPassword.valid,'new',ctrlPass)"
          *ngIf="thisChangePwdForm.parNewPassword.valid"></span> -->
        <small class="text-danger font-weight-bold">{{errorMessages.changeNewPassword}}</small>
      </div>
    </div>
    <div class="form-group mb-3">
      <label for="changeConformPassword" class="login-font">Confirm New Password</label>
      <div class="input-control">
        <span class="fa fa-lock login-icon"></span>
        <s-inputtext [inputType]="confirmIsInputText" controlId="changeConformPassword"
          [cssClass]="'form-Control p-1 pl-30'" [isLoginPage]="true" [formControlName]="'parNewConfPassword'"
          (onBlur)="getPwdChangeErrormessage('parNewConfPassword',true)">
        </s-inputtext>
        <!-- <span class="{{confirmShowEye}}"
          (click)="onClickSeePassword(thisChangePwdForm.parNewConfPassword.valid,'cnfrm',ctrlPass)"
          *ngIf="thisChangePwdForm.parNewConfPassword.valid"></span> -->
        <small class="text-danger font-weight-bold">{{errorMessages.changeConformPassword}}</small>
      </div>
    </div>

    <button type="submit" class="btn btn-primary w-100 d-block login-btn" (click)='onChangePwdClick()'
      [disabled]="!(isShowSbmit && validNewPwd)">Submit</button>

    <div [ngClass]="saveCode == '0000' ?'pwd-warning':'pwd-success'" *ngIf="showReq">
      <label> {{toastMessage}}</label>
    </div>

    <ul style="margin-top: 15px" *ngIf="isChceckNewPwd">
      <li>
        <a href="javascript:return false"><i class="fa fa-times-circle char-failure" *ngIf="!minLengthFlag"></i><i
            class="fa fa-check-circle char-success" *ngIf="minLengthFlag"></i></a>
        <span>Minimum Length of {{minLength}} Characters Required</span></li>
      <li>
        <a href="javascript:return false"><i class="fa fa-times-circle char-failure" *ngIf="!uppCsFlag"></i><i
            class="fa fa-check-circle  char-success" *ngIf="uppCsFlag"></i></a>
        <span>Minimum {{uppCs}} Upper Case Letter Required</span>
      </li>
      <li>
        <a href="javascript:return false"><i class="fa fa-times-circle char-failure" *ngIf="!lwrCsFlag"></i><i
            class="fa fa-check-circle  char-success" *ngIf="lwrCsFlag"></i></a>
        <span>Minimum {{lwrCs}} Lower Case Letter Required</span>
      </li>
      <li>
        <a href="javascript:return false"><i class="fa fa-times-circle char-failure" *ngIf="!minNmFlag"></i><i
            class="fa fa-check-circle  char-success" *ngIf="minNmFlag"></i></a>
        <span>Minimum {{minNm}} Numbers Required</span>
      </li>
      <li>
        <a href="javascript:return false"><i class="fa fa-times-circle char-failure" *ngIf="!spclChrsFlag"></i><i
            class="fa fa-check-circle  char-success" *ngIf="spclChrsFlag"></i></a>
        <span>Minimum {{spclChrs}} Special Character Required</span>
      </li>
    </ul>
  </form>
</p-dialog>

<p-dialog header="Mobile App" [(visible)]="displayMobileApp" [modal]="true" [style]="{width:'25vw'}"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div class="row mt-2 mb-2">
    <div class='col-6 divLeft'>
      <div class="appDiv">
        <a href='{{androidURL}}' target="blank"><img src="../../assets/img/PlayStore.png"></a>
        <img src="../../assets/img/EduKares_Android.png" class="img-fluid" alt="EduKares Play Store"><br>
        <span>Scan to Download</span>
      </div>
    </div>
    <div class='col-6'>
      <div class="appDiv">
        <a href='{{iosURL}}' target="blank"><img src="../../assets/img/AppStore.png"></a>
        <img src="../../assets/img/EduKares_IOS.png" class="img-fluid" alt="EduKares App Store"><br>
        <span>Scan to Download</span>
      </div>
    </div>
  </div>
</p-dialog>

<style>
  .eyes-icon {
    position: absolute;
    z-index: 1111;
    right: 10px;
    top: 7px;
    font-size: 14px;
    color: #b0c4de;
  }

  .forgot-pwd {
    color: #3275bc;
    font-size: 1.2em;
    text-decoration: underline dashed;
  }

  .forgot-pwd:hover {
    color: red;
  }

  .appDiv {
    text-align: center;
    border: 1px dotted gray;
    padding: 10px;
    border-radius: 5px;
  }

  .appDiv a>img {
    width: 160px;
    height: 50px;
  }

  .appDiv img {
    width: 160px;
    height: 160px;
  }

  .appDiv span {
    color: #0d4683
  }

  .divLeft {
    border-right: 2px dotted gray;
  }
</style>