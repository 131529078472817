<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Privacy Policy</title>
    <style>
        html, body {
            height: 100%;
            margin: 0;
        }
        body {
            font-family: 'Arial', sans-serif;
            background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.8)),url("../../assets/img/bg-img.jpg") no-repeat center center fixed;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px !important;
        }
        .container {
            max-width: 800px;
            margin: 50px auto;
            padding: 20px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            overflow-y: auto;
            max-height: 90vh;
        }
        .header {
            text-align: center;
            padding: 10px;
            background-color: #333;
            color: #fff;
            border-radius: 8px 8px 0 0;
            position: sticky;
            top: 0;
            z-index: 1000;
            
        }
        .header img {
            max-width: 100px;
            height: auto;
            margin-bottom: 5px;
        }
        .header h1 {
            margin: 0;
            font-size: 1.5em;
        }
        p {
            margin: 25px 0;
            line-height: 1.5
        }
        .section-title {
            font-size: 1.5em;
            margin-bottom: 10px;
            color: #555;
        }
        ul {
            margin: 20px 0;
            padding-left: 20px;
        }
        li {
            margin-bottom: 10px;
        }
        .footers {
            text-align: center;
            margin-top: 30px;
            font-size: 0.9em;
            color: #666;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="header">
            <img src="../../assets/img/EduKares_logo.jpeg" alt="Company Logo">
            <h1>Privacy Policy</h1>
        </div>
        <p>Welcome to our Privacy Policy page. Here we explain how we handle your personal information when you use our services. This Privacy Policy applies to the Edukares app, developed by Softhealth India Pvt. Ltd.</p>
        
        <div class="section">
            <h2 class="section-title">Information We Collect</h2>
            <p>We collect various types of information to provide and improve our services:</p>
            <ul>
                <li><strong>Personal Information:</strong> Information such as your name, email address, and phone number.</li>
                <li><strong>Usage Data:</strong> Information on how you use our website, including IP addresses and browser types.</li>
            </ul>
        </div>
        
        <div class="section">
            <h2 class="section-title">How We Use Information</h2>
            <p>Your information is used to:</p>
            <ul>
                <li>Provide, operate and maintain our services.</li>
                <li>Improve, personalize and expand our services.</li>
                <li>Communicate with you, including customer service and updates.</li>
            </ul>
        </div>
        
        <div class="section">
            <h2 class="section-title">Information Sharing</h2>
            <p>We do not share your personal information with third parties except in the following cases:</p>
            <ul>
                <li>With your consent.</li>
                <li>To comply with legal obligations.</li>
                <li>To protect and defend our rights and property.</li>
            </ul>
        </div>

<div class="section">
            <h2 class="section-title">Data Retention</h2>
            <p>We retain your data only for as long as necessary for the app's intented purpose.</p>
        </div>
        
        <div class="section">
            <h2 class="section-title">Security of Information</h2>
            <p>We take the security of your information seriously and implement reasonable measures to protect it.</p>
        </div>
        
        <div class="section">
            <h2 class="section-title">Your Rights</h2>
            <p>You have the right to access, update, and delete your personal information. Contact us to exercise these rights.</p>
        </div>

         <div class="section">
            <h2 class="section-title">Contact Us</h2>
            <p> If you have any questions, please contact us:</p>
<p>Email:  support@talenttimes.in</p>
<p>Phone: +91 83677788879</p>
        </div>
        
        <div class="section">
            <h2 class="section-title">Changes to This Policy</h2>
            <p>We may update this Privacy Policy from time to time. We encourage you to review this policy periodically for any changes.</p>
        </div>
        
        <p class="footers">If you have any questions about our Privacy Policy, please contact us at support@talenttimes.in.</p>
    </div>
</body>
</html>
