<input [autoFocus]="autoFocus" [autocomplete]="autocomplete" type="{{inputType}}" (keyup)="modelChange()"
    [formControl]="ngControl.control || ''"
    [ngClass]="(isLoginPage?'form-control p-1 pl-30' : isMandatory && markMandatory?'form-control p-1 mandatory-input':'form-control p-1') + (isLargeInput?' large-input' : '')"
    id="{{controlId}}" [attr.maxlength]="maxLength" [pKeyFilter]="keyFilter" pInputText (blur)="onLeaveControl()"
    (change)="onTextChange()" *ngIf="this.ngControl!=null" placeholder="{{placeholder}}"
    [ngStyle]="{'text-align': keyFilter.toString()=='num' || keyFilter.toString()=='int' || keyFilter.toString()=='pnum' || keyFilter.toString()=='pint'?'right':'left'}"
    pTooltip="{{tooltipText}}" tooltipEvent="focus" [escape]=false autocomplete="off"
    tooltipPosition="{{tooltipPosition}}">

<input [autoFocus]="autoFocus" [autocomplete]="autocomplete" [(ngModel)]="inputModel" (keyup)="modelChange()"
    type="{{inputType}}"
    [ngClass]="(isLoginPage?'form-control p-1 pl-30' : isMandatory && markMandatory?'form-control p-1 mandatory-input':'form-control p-1') + (isLargeInput?' large-input' : '')"
    id="{{controlId}}" [attr.maxlength]="maxLength" [pKeyFilter]="keyFilter" pInputText (blur)="onLeaveControl()"
    (change)="onTextChange()" *ngIf="this.ngControl==null" [disabled]="disabledMe" placeholder="{{placeholder}}"
    [ngStyle]="{'text-align': keyFilter.toString()=='num' || keyFilter.toString()=='int' || keyFilter.toString()=='pnum' || keyFilter.toString()=='pint'?'right':'left'}"
    pTooltip="{{tooltipText}}" tooltipEvent="focus" [escape]=false autocomplete="off"
    tooltipPosition="{{tooltipPosition}}">

<i class="pi pi-exclamation-circle {{iconClass}}" tooltipStyleClass="tooltip-error" pTooltip={{errorMessage}}
    *ngIf="errorMessage!=''">